import { Form, FormCropper, useForm } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { copyAndCropMediasImage, cropMediasImage, uncropMediasImage } from "@/store/medias/actions"
import { MediasFile } from "@/store/medias/localizers"
import { DialogClose } from "@radix-ui/react-dialog"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * CropImageDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: MediasFile | false
}
export const CropImageDialog: React.FC<Props> = ({ item, open, onOpenChange }) => {
  const { _ } = useDictionary(dictionary("crop-image-dialog"))
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
      animate={false}
    >
      {item !== false && <DialogForm close={() => onOpenChange(false)} file={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<{ file: MediasFile; close: () => void }> = ({ file, close }) => {
  const { _ } = useDictionary(dictionary("crop-image-dialog"))

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      file: { url: file.originalUrl, isChange: false, transform: { ...initialTransform, ...file.transform } },
    },
    translate: _ as (ctx: string) => string,
    onSubmit: async ({ values }, event) => {
      event?.stopPropagation()
      if (values.file.isChange === false) return close()
      close()
      const { error } = await cropMediasImage(file.id, { transform: values.file.transform })
      if (error) toast.error(_("error"))
      else toast.success(_("success"))
    },
  })
  const unCrop = async () => {
    close()
    const { error } = await uncropMediasImage(file.id)
    if (error) toast.error(_("error"))
    else toast.success(_("success"))
  }
  const copyAndCrop = async () => {
    close()
    const { error } = await copyAndCropMediasImage(file.id, { transform: form.values.file.transform })
    if (error) toast.error(_("error"))
    else toast.success(_("success"))
  }

  return (
    <Form form={form} className='grid gap-6'>
      <FormCropper label={_("cropper-label")} name='file' />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        {D.isNotEmpty(file.transform) && (
          <>
            <Button variant='destructive' onClick={unCrop}>
              {_("undo")}
            </Button>
          </>
        )}
        <Button onClick={() => form.submit()}>{_("submit")}</Button>
        <Button onClick={copyAndCrop}>{_("copy-and-crop")}</Button>
      </DialogFooter>
    </Form>
  )
}

/**
 * initialTransform
 */
const initialTransform = {
  width: 100,
  height: 100,
  x: 0,
  y: 0,
  rotate: 0,
  cropper: {
    crop: {
      x: 0,
      y: 0,
    },
    zoom: 1,
    aspect: {
      w: 0,
      h: 9,
    },
  },
}
