import useUuid from "@/app/hooks/useUuid"
import { inputIconVariants, inputVariants } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { useDictionary } from "@/dictionaries/hooks"
import { Search, X } from "lucide-react"
import { useToolbarContext } from "./Toolbar"
import { SrOnly } from "@/components/radix/ui/sr-only"

/**
 * ToolbarSearch
 */
type Props = {
  search: string
  setSearch: (search: string) => void
}
export const ToolbarSearch: React.FC<Props> = ({ search, setSearch }) => {
  const { _ } = useDictionary("components.layout.toolbar")
  const { size } = useToolbarContext()
  const searchId = useUuid()
  const searchRef = React.useRef<HTMLInputElement>(null)
  const searchClear = () => {
    searchRef.current?.focus()
    setSearch("")
  }
  return (
    <div
      className={cx(
        "relative grow w-full ",
        size === "default" && "@xl/toolbar:w-auto",
        size === "sm" && "@xl/toolbar:w-auto",
        size === "xs" && "@xs/toolbar:w-auto"
      )}
    >
      <label htmlFor={searchId} className={inputIconVariants({ size, side: "left" })}>
        <Search className="w-4 h-4 text-muted-foreground" aria-hidden />
        <SrOnly>{_("search-label")}</SrOnly>
      </label>
      <input
        id={searchId}
        ref={searchRef}
        value={search}
        onChange={({ target }) => setSearch(target.value)}
        className={inputVariants({ size, icon: "both" })}
        placeholder={_("search-placeholder")}
      />
      <Button variant="ghost" icon onClick={searchClear} className={inputIconVariants({ size, side: "right" })}>
        <X className="text-muted-foreground" aria-hidden />
        <SrOnly>{_("search-clear")}</SrOnly>
      </Button>
    </div>
  )
}
