import { useTheme } from "./ThemeProvider"

/**
 * CardSpotlight
 */
type CardSpotlightProps = {
  opacity: number
  position: {
    x: number
    y: number
  }
}
export const CardSpotlight: React.FC<CardSpotlightProps> = ({ opacity, position }) => {
  const { theme } = useTheme()
  return (
    <div
      className="pointer-events-none absolute -inset-px opacity-0 transition-all duration-300"
      aria-hidden
      style={{
        opacity,
        background:
          theme === "dark"
            ? `radial-gradient(600px circle at ${position.x}px ${position.y}px, rgba(255,255,255,.06), transparent 40%)`
            : `radial-gradient(600px circle at ${position.x}px ${position.y}px, rgba(58, 37, 141, 0.04), transparent 40%)`,
      }}
    />
  )
}

/**
 * useCardSpotlight
 */
export const useCardSpotlight = () => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [isFocused] = React.useState(false)
  const [position, setPosition] = React.useState({ x: 0, y: 0 })
  const [opacity, setOpacity] = React.useState(0)

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!ref.current || isFocused) return
    const rect = ref.current.getBoundingClientRect()
    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top })
  }

  const onFocus = () => {
    //
  }

  const onBlur = () => {
    //
  }

  const onMouseEnter = () => {
    setOpacity(1)
  }

  const onMouseLeave = () => {
    setOpacity(0)
  }
  return {
    cardProps: { onMouseLeave, onMouseEnter, onBlur, onFocus, onMouseMove, ref },
    cardSpotlightProps: { position, opacity },
  }
}
