import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { Folders, GalleryVerticalEnd, Globe, Layers2, LayoutGrid, Newspaper, Settings2, Users2 } from "lucide-react"
import { useLocation } from "wouter"
import { ThemeToggle } from "../ThemeToggle"
import { UserDropdown } from "../UserDropdown"
import { Accordion } from "./accordion"
import { SidebarDivider as Divider, SidebarMenu as Menu, SidebarWrapper as Wrapper } from "./components"
import { SidebarContext } from "./context"

/**
 * dictionary src/dictionaries/en/components/layout.json
 */
const dictionary = createContextMapper("components", "layout", "sidebar")
const dashboard = createContextMapper("pages", "dashboard")

/**
 * Sidebar
 */
export const Sidebar: React.FC = () => {
  const { _ } = useDictionary()
  const { isDesktop, isMobile, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  const { isActivePath, isActiveLocation } = useActiveLocation()
  const beforeClick = (): true => {
    if (isMobile) sidebar.close()
    return true
  }
  return (
    <Wrapper>
      <div className="flex flex-col w-full overflow-x-hidden">
        <div className="flex items-center w-full h-16">
          <span className="flex justify-center w-16 shrink-0 text-primary">
            <LogoSquare aria-hidden className="w-8 h-8" />
          </span>
          <span
            className={cx(
              "flex text-2xl font-light uppercase whitespace-nowrap transition-opacity duration-300",
              expanded ? "opacity-1" : "opacity-0"
            )}
          >
            {_(dictionary("sitename"))}
          </span>
        </div>
        <Divider />
      </div>
      <Menu>
        <Accordion value="section-content-manager">
          <Accordion.Trigger icon={<GalleryVerticalEnd aria-hidden />}>
            {_(dictionary("menu-content-manager"))}
          </Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Item
              icon={<Folders aria-hidden />}
              onClick={() => navigate("/dashboard/medias")}
              active={isActivePath("/dashboard/medias")}
            >
              {_(dashboard("medias.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Layers2 aria-hidden />}
              onClick={() => navigate("/dashboard/pages")}
              active={isActivePath("/dashboard/pages")}
            >
              {_(dashboard("pages.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Newspaper aria-hidden />}
              onClick={() => navigate("/dashboard/articles")}
              active={isActivePath("/dashboard/articles", ["/dashboard/articles/categories"])}
            >
              {_(dashboard("articles.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<LayoutGrid aria-hidden />}
              onClick={() => navigate("/dashboard/articles/categories")}
              active={isActiveLocation("/dashboard/articles/categories")}
            >
              {_(dashboard("articles.categories.breadcrumbs"))}
            </Accordion.Item>
          </Accordion.Content>
        </Accordion>

        <Accordion value="section-application">
          <Accordion.Trigger icon={<Settings2 aria-hidden />}>{_(dictionary("menu-application"))}</Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Item
              icon={<Users2 aria-hidden />}
              onClick={() => navigate("/dashboard/users")}
              active={isActivePath("/dashboard/users")}
            >
              {_(dashboard("users.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Globe aria-hidden />}
              onClick={() => navigate("/dashboard/languages")}
              active={isActivePath("/dashboard/languages")}
            >
              {_(dashboard("languages.breadcrumbs"))}
            </Accordion.Item>
          </Accordion.Content>
        </Accordion>

        {/* <Button icon={<LayoutTemplate />}>Button 3</Button> */}
      </Menu>
      <div className="flex flex-col w-full overflow-hidden">
        <Divider />
        <div className={cx("flex justify-center items-center py-4 gap-4", expanded ? "px-4" : "flex-col")}>
          <ThemeToggle beforeClick={beforeClick} />
          {/* <UserDropdown expanded={expanded} /> */}
        </div>
      </div>
    </Wrapper>
  )
}

const useActiveLocation = () => {
  const [location] = useLocation()
  const isActiveLocation = (path: string) => {
    return location === path
  }
  const isActivePath = (path: string, expect: string[] = []) => {
    if (A.isNotEmpty(expect) && expect.includes(location)) return false
    return location.startsWith(path)
  }
  return { isActiveLocation, isActivePath }
}
const LogoSquare = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 200 200" {...props}>
    <path
      d="M146 64.3c.9 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5h-20.8v-36c0-.9-.6-1.5-1.5-1.5s-1.5.6-1.5 1.5v17.2H102V25.3c0-.9-.6-1.5-1.5-1.5s-1.5.6-1.5 1.5v36H78.7v-36c0-.9-.6-1.5-1.5-1.5s-1.5.6-1.5 1.5v17.2H55.5V25.3c0-.9-.6-1.5-1.5-1.5s-1.5.6-1.5 1.5v36h-20c-.9 0-1.5.6-1.5 1.5s.6 1.5 1.5 1.5h20v34.6h-20c-.9 0-1.5.6-1.5 1.5s.6 1.5 1.5 1.5h20v37.3c0 .9.6 1.5 1.5 1.5s1.5-.6 1.5-1.5v-18.1h20.2v18.1c0 .9.6 1.5 1.5 1.5s1.5-.6 1.5-1.5v-37.3h20.2v37.3c0 .9.6 1.5 1.5 1.5s1.5-.6 1.5-1.5v-18.1h20.2v18.1c0 .9.6 1.5 1.5 1.5s1.5-.6 1.5-1.5v-37.3H146c.9 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5h-20.8V64.3H146zm-70.3 53.9H55.5V83.1h20.2v35.1zm0-38.1H55.5V45.5h20.2v34.6zm23.2 18.8H78.7V64.3h20.2v34.6zm23.2 19.3h-20.2V83.1h20.2v35.1zm0-38.1h-20.2V45.5h20.2v34.6z"
      fill="currentColor"
      transform="matrix(1.30208 0 0 1.30208 -16.146 -7.146)"
    />
  </svg>
)
