import useUuid from "@/app/hooks/useUuid"
import { Breadcrumb, Breadcrumbs } from "@/components/layout/dashboard/Breadcrumbs"
import { Grid, Menu } from "@/components/layout/dashboard/Collection"
import Toolbar from "@/components/layout/dashboard/Toolbar"
import { ScrollArea } from "@/components/radix/ui/scroll-area"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useFilteredMedias, useFolderPath, useFreshFolder } from "@/store/medias/hooks"
import { MediasFolder } from "@/store/medias/localizers"
import { Folder, Plus, Search } from "lucide-react"
import { ContextProvider, useMediaContext } from "../Context"
import { FolderContextMenu, ItemCardFolder, ItemFolder, ItemRowFolder } from "../components/folders"
import { useSelectItem } from "../useSelect"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * SelectFolder
 */
type SelectFolderProps = {
  current: string | null
  disabledIds?: string[]
  hiddenIds?: string[]
  selected: string | null
  setSelected: (id: string | null) => void
  onSubmit: (id: string) => void
}
export const SelectFolder: React.FC<SelectFolderProps> = ({ ...props }) => {
  const contextKey = useUuid()
  return (
    <ContextProvider contextKey={contextKey} selectFolder {...props}>
      <SelectFolderInner {...props} />
    </ContextProvider>
  )
}

/**
 * SelectFolderInner
 * dictionary src/dictionaries/en/components/medias.json
 */
export const SelectFolderInner: React.FC<SelectFolderProps> = ({ hiddenIds, disabledIds, onSubmit, ...props }) => {
  const { _ } = useDictionary(dictionary())

  const { currentFolder, createFolder, setCurrentFolder, view, setView, selected, resetSelection } = useMediaContext()
  useFreshFolder(currentFolder)

  const { matchable, folders, filteredFolders } = useFilteredMedias(currentFolder, hiddenIds)

  // breadcrumbs
  const root: Breadcrumb = [_("breadcrumbs"), () => setCurrentFolder(null)]
  const breadcrumbs = pipe(
    useFolderPath(currentFolder),
    A.reverse,
    A.map<MediasFolder, Breadcrumb>(({ name, id }) => [name, () => setCurrentFolder(id)])
  )

  React.useEffect(() => {
    props.setSelected(A.head(selected.folders) ?? currentFolder)
  }, [selected])

  return (
    <div className="flex flex-col py-4">
      <Breadcrumbs {...{ breadcrumbs, root }} size="xs" className="pb-2" />
      <Toolbar size="xs">
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={() => createFolder(currentFolder)}>
          <Plus aria-hidden />
          {_("create-folder")}
        </Toolbar.Button>
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>

      {A.isEmpty(filteredFolders) || A.isEmpty(folders) ? (
        <div className="flex flex-col justify-center items-center aspect-video gap-4 p-2 text-center">
          {A.isEmpty(folders) ? (
            <Folder aria-hidden className="text-muted-foreground" size={48} strokeWidth={1} />
          ) : (
            <Search aria-hidden className="text-muted-foreground" size={48} strokeWidth={1} />
          )}
          <h2 className="text-lg font-semibold">{_(A.isEmpty(folders) ? "empty-folder" : "empty-result")}</h2>
        </div>
      ) : (
        <div className="overflow-hidden aspect-video border shadow-inner rounded-md" onClick={resetSelection}>
          <div className="h-full overflow-y-auto scrollbar scrollbar-w-1 scrollbar-thumb-muted scrollbar-track-background scrollbar-thumb-rounded-full">
            <Grid
              view={view}
              className={cx(view === "grid" ? "@md/collection:grid-cols-2 gap-2 p-2" : "divide-y-1 divide")}
            >
              {A.map(filteredFolders, (folder) => (
                <ItemFolder
                  key={folder.id}
                  folder={folder}
                  disabled={(disabledIds ?? []).includes(folder.id)}
                  select={(disabledIds ?? []).includes(folder.id) ? undefined : onSubmit}
                  size="xs"
                />
              ))}
            </Grid>
          </div>
        </div>
      )}
    </div>
  )
}
