import { byId } from "@/app/fns/byId"
import * as Contents from "@/services/contents/service"
import * as Pages from "@/services/pages/service"
import * as Seos from "@/services/seos/service"
import { pagesStore } from "."
import { localizeContent, localizeContentItem } from "../contents/localizers"
import { mediasStore } from "../medias"
import { localizeMediaFile } from "../medias/localizers"
import { localizeSeo } from "../seos/localizers"
import { extractFilesFromPage, extractFilesFromPages } from "./helpers"
import { localizePage } from "./localizers"
import { extractFilesFromSeo } from "../seos/helpers"
import { extractFilesFromContent, extractFilesFromContentItem } from "../contents/helpers"

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * pages
 */

/**
 * getPages
 */
export const getPages = async () => {
  const { data, error, code } = await Pages.service.index()
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromPages(data.pages), localizeMediaFile)) })
    pagesStore.evolve({ pages: byId(data.pages, localizePage) })
  }
  return { error, code } as const
}

/**
 * createPage
 */
export const createPage = async (payload: Pages.Payload["create"]) => {
  const { data, error, code } = await Pages.service.create(payload)
  if (!error) pagesStore.evolve({ pages: D.set(data.page.id, localizePage(data.page)) })
  return { error, code, id: data?.page.id } as const
}

/**
 * getPage
 */
export const getPage = async (id: string) => {
  const { data, error, code } = await Pages.service.read(id)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromPage(data.page), localizeMediaFile)) })
    pagesStore.evolve({ pages: D.set(data.page.id, localizePage(data.page)) })
  }
  return { error, code } as const
}

/**
 * updatePage
 */
export const updatePage = async (id: string, payload: Pages.Payload["create"]) => {
  const { data, error, code } = await Pages.service.update(id, payload)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromPage(data.page), localizeMediaFile)) })
    pagesStore.evolve({ pages: D.set(data.page.id, localizePage(data.page)) })
  }
  return { error, code } as const
}

/**
 * deletePage
 */
export const deletePage = async (id: string) => {
  const { error, code } = await Pages.service.delete(id)
  if (!error) pagesStore.evolve({ pages: D.deleteKey(id) })
  return { error, code } as const
}

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * seos
 */

/**
 * updatePageSeo
 */
export const updatePageSeo = async (id: string, payload: Seos.Payload["update"]) => {
  const { data, error, code } = await Seos.service.update("pages", id, payload)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromSeo(data.seo), localizeMediaFile)) })
    pagesStore.evolve({
      pages: {
        [id]: {
          seo: localizeSeo(data.seo),
        },
      },
    })
  }
  return { error, code } as const
}

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * contents
 */

/**
 * updatePageContent
 */
export const updatePageContent = async (id: string, payload: Contents.Payload["update"]) => {
  const { data, error, code } = await Contents.service.update("pages", id, payload)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromContent(data.content), localizeMediaFile)) })
    pagesStore.evolve({
      pages: {
        [id]: {
          content: localizeContent(data.content),
        },
      },
    })
  }
  return { error, code } as const
}

/**
 * createPageContentItem
 */
export const createPageContentItem = async (id: string, payload: Contents.Payload["items"]["create"]) => {
  const { data, error, code } = await Contents.service.items.create("pages", id, payload)
  if (G.isNullable(D.get(pagesStore.current.pages, id))) return { error: true, code: "STORE_OUTDATED" } as const
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromContentItem(data.item), localizeMediaFile)) })
    pagesStore.evolve({
      pages: {
        [id]: {
          content: {
            items: flow(
              D.set(data.item.id, localizeContentItem(data.item)),
              D.map((item) => D.set(item, "order", A.getIndexBy(data.sortedIds, (id) => id === item.id) ?? 0))
            ),
          },
        },
      },
    })
  }
  return { error, code } as const
}

/**
 * updatePageContentItem
 */
export const updatePageContentItem = async (
  id: string,
  itemId: string,
  payload: Contents.Payload["items"]["update"]
) => {
  const { data, error, code } = await Contents.service.items.update("pages", id, itemId, payload)
  if (G.isNullable(D.get(pagesStore.current.pages, id))) return { error: true, code: "STORE_OUTDATED" } as const
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromContentItem(data.item), localizeMediaFile)) })
    pagesStore.evolve({
      pages: {
        [id]: {
          content: {
            items: D.set(data.item.id, localizeContentItem(data.item)),
          },
        },
      },
    })
  }
  return { error, code } as const
}

/**
 * deletePageContentItem
 */
export const deletePageContentItem = async (id: string, itemId: string) => {
  const { error, code } = await Contents.service.items.delete("pages", id, itemId)
  if (!error)
    pagesStore.evolve({
      pages: {
        [id]: {
          content: {
            items: D.deleteKey(itemId),
          },
        },
      },
    })
  return { error, code } as const
}

/**
 * reorderPageContentItems
 */
export const reorderPageContentItems = async (id: string, payload: Contents.Payload["items"]["reorder"]) => {
  const page = D.get(pagesStore.current.pages, id)
  if (G.isNullable(page)) return { error: true, code: "STORE_OUTDATED" } as const
  const originalSortedIds = pipe(page.content.items, D.values, A.sortBy(D.prop("order")), A.map(D.prop("id")))
  pagesStore.evolve({
    pages: {
      [id]: {
        content: {
          items: flow(D.map((item) => D.set(item, "order", A.getIndexBy(payload.items, (id) => id === item.id) ?? 0))),
        },
      },
    },
  })
  const { data, error, code } = await Contents.service.items.reorder("pages", id, payload)
  if (!error) {
    pagesStore.evolve({
      pages: {
        [id]: {
          content: {
            items: flow(
              D.map((item) => D.set(item, "order", A.getIndexBy(data.sortedIds, (id) => id === item.id) ?? 0))
            ),
          },
        },
      },
    })
  } else {
    pagesStore.evolve({
      pages: {
        [id]: {
          content: {
            items: flow(
              D.map((item) => D.set(item, "order", A.getIndexBy(originalSortedIds, (id) => id === item.id) ?? 0))
            ),
          },
        },
      },
    })
  }
  return { error, code } as const
}

/**
 * cmsActions
 */
/**
 * cmsActions
 */
export const cmsActions = {
  updateSeo: updatePageSeo,
  updateContent: updatePageContent,
  createContentItem: createPageContentItem,
  updateContentItem: updatePageContentItem,
  deleteContentItem: deletePageContentItem,
  reorderContentItems: reorderPageContentItems,
}
