import { FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "@/components/form"
import { Menu } from "@/components/layout/dashboard/Collection"
import { SelectFiles } from "@/components/medias/components/selectFiles"
import { FileInfoDialog } from "@/components/medias/dialogs/FileInfoDialog"
import { Button, buttonFormField } from "@/components/radix/ui/button"
import { SrOnly } from "@/components/radix/ui/sr-only"
import { Video } from "@/components/radix/ui/video"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import delay from "delay"
import { FileDown, FileMinus, Info, MoreVertical, PlaySquare, Plus } from "lucide-react"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * FormMediasVideo
 */
type FormMediasVideoProps = { ratio?: string; contextKey?: string }
export const FormMediasVideo: React.FC<FormMediasVideoProps & FormFieldWrapperProps> = ({
  ratio = "aspect-video",
  contextKey,
  ...wrapperProps
}) => (
  <FormFieldWrapper {...wrapperProps}>
    <FormMediasVideoInput {...{ ratio, contextKey }} />
  </FormFieldWrapper>
)

/**
 * FormMediasVideoInput
 * dictionary src/dictionaries/en/components/medias.json
 */
const FormMediasVideoInput: React.FC<FormMediasVideoProps> = ({ ratio = "aspect-video", contextKey }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const { setFieldValue, value, id } = useFieldContext<string | null>()
  const file = useMediasFile(value)
  const onRemove = () => {
    setFieldValue(null)
    delay(10).then(() => focusRef.current?.focus())
  }
  const [open, onOpenChange] = React.useState(false)

  const onSelect = (files: string[]) => {
    const file = A.head(files)
    if (G.isNullable(file)) return
    setFieldValue(file)
  }

  const [openInfo, setOpenInfo] = React.useState(false)

  const focusRef = React.useRef<HTMLButtonElement>(null)
  return (
    <div className={cx("relative")}>
      {G.isNotNullable(file) ? (
        <>
          <FileInfoDialog {...{ file, openInfo, setOpenInfo }} onCloseAutoFocus={() => focusRef.current?.focus()} />
          <Menu
            menu={
              <>
                <Menu.Item onClick={() => setOpenInfo(true)}>
                  <Info aria-hidden />
                  {_("file-info")}
                </Menu.Item>
                <Menu.Item onClick={onRemove}>
                  <FileMinus aria-hidden />
                  {_("remove-file")}
                </Menu.Item>
                <Menu.Item onClick={() => onOpenChange(true)}>
                  <FileDown aria-hidden />
                  {_("change-file")}
                </Menu.Item>
              </>
            }
            type="dropdown-menu"
            align="start"
            side="left"
          >
            <Button
              variant="transparent"
              ref={focusRef}
              id={id}
              size="xxs"
              icon
              className="absolute z-10 top-2 right-2"
            >
              <MoreVertical aria-hidden />
              <SrOnly>{_("more")}</SrOnly>
            </Button>
          </Menu>
          <Video
            src={file.url}
            aria-label={t(file).alt}
            ratio={ratio}
            wrapperCx="w-full h-full rounded-md border border-input bg-muted"
          />
        </>
      ) : (
        <>
          <button
            ref={focusRef}
            className={cx(buttonFormField, ratio)}
            id={id}
            type="button"
            onClick={() => onOpenChange(true)}
          >
            <span className="relative" aria-hidden>
              <PlaySquare size={64} strokeWidth={0.5} />
              <Plus size={24} strokeWidth={1.4} className="absolute top-0 right-0 bg-muted rounded-full" />
            </span>
            <SrOnly>{_("add-video")}</SrOnly>
          </button>
        </>
      )}
      <SelectFiles
        open={open}
        type="video"
        onCloseAutoFocus={() => focusRef.current?.focus()}
        current={file?.parentId ?? undefined}
        onOpenChange={onOpenChange}
        onSelect={onSelect}
        contextKey={contextKey}
      />
    </div>
  )
}
