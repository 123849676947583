import { usePromise } from "@/app/hooks/usePromise"
import { CmsContextProvider } from "@/components/cms/Context"
import { ContentItems } from "@/components/cms/components/content"
import SeoItem from "@/components/cms/components/seo"
import { ArticleUpdateDialog } from "@/components/dialogs/ArticleUpdateDialog"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Menu } from "@/components/layout/dashboard/Collection"
import { Field, Fields } from "@/components/layout/dashboard/Collection/Fields"
import { Loader } from "@/components/layout/dashboard/Collection/Loader"
import { Button } from "@/components/radix/ui/button"
import { StatsDialog } from "@/components/trackings/dialogs/StatsDialog"
import { Confirm, confirmSafeDictionary, useConfirm } from "@/components/ui/Confirm"
import { useDialog } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { deleteArticle, getArticle, updateArticle } from "@/store/articles/actions"
import { useArticle, useArticleCategory } from "@/store/articles/hooks"
import { Article } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { getUser } from "@/store/users/actions"
import { useUser } from "@/store/users/hooks"
import { BarChart3, CalendarCheck, CalendarDays, Eye, EyeOff, FilePen, LayoutGrid, Shapes, Trash } from "lucide-react"
import { Redirect } from "wouter"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles")

/**
 * Page: ArticlesArticle
 */
const ArticlesArticle: React.FC<{ article: Article }> = ({ article }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()

  // confirm
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<void>({
    onAsyncConfirm: () => deleteArticle(article.id),
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })
  //dialogs
  const { setItem: update, ...updateProps } = useDialog<Article>()
  const { setItem: openStats, ...statsProps } = useDialog<string>()

  // action
  const toggleState = () => updateArticle(article.id, { state: article.state === "published" ? "draft" : "published" })

  const format = useDateFnsLocaleFormat()
  const category = useArticleCategory(article.category)
  const author = useUser(article.author)
  const isDraft = article.state === "draft"

  return (
    <CmsContextProvider ressource={{ type: "articles", ...D.selectKeys(article, ["id", "seo", "content"]) }}>
      <PageWrapper>
        <PageHeader
          breadcrumbs={[
            [_("breadcrumbs"), "/dashboard/articles"],
            [
              S.isEmpty(S.trim(t(article.seo).title ?? "")) ? _("no-title") : t(article.seo).title,
              `/dashboard/articles/${article.id}`,
            ],
          ]}
        />
        <SeoItem
          menu={<ContextMenu {...{ article, confirmDelete, toggleState, update }} />}
          action={
            <>
              <Button icon size='xxs' onClick={() => openStats(article.trackingId)}>
                <BarChart3 />
              </Button>
              <Button variant={isDraft ? "secondary" : "default"} icon size='xxs' onClick={toggleState}>
                {isDraft ? <EyeOff aria-label={_(`state-draft`)} /> : <Eye aria-label={_(`state-published`)} />}
              </Button>
            </>
          }
        >
          <h3 className='pt-4'>{_("meta-title")}</h3>
          <Fields>
            <Field name={_("type")} icon={<Shapes aria-hidden />} stretch>
              {_(`type-${article.type}`)}
            </Field>
            {G.isNotNullable(author) && (
              <Field name={_("author")} icon={<LayoutGrid aria-hidden />} stretch>
                {textPlaceholder(`${author.profile.firstname} ${author.profile.lastname}`, _("no-author-profile"))}
              </Field>
            )}
            {G.isNotNullable(category) && (
              <Field name={_("category")} icon={<LayoutGrid aria-hidden />} stretch>
                {t(category).name}
              </Field>
            )}
            <Field name={_("published-at")} icon={<CalendarDays aria-hidden />} stretch>
              {format(article.publishedAt, "PPP")}
            </Field>
            {(G.isNotNullable(article.publishedFrom) || G.isNotNullable(article.publishedTo)) && (
              <Field name={_("published")} icon={<CalendarCheck aria-hidden />} stretch>
                {G.isNotNullable(article.publishedFrom) && (
                  <span>
                    <span className='font-medium'>{_("published-from")}:</span> {format(article.publishedFrom, "PPPp")}
                  </span>
                )}
                {G.isNotNullable(article.publishedTo) && (
                  <span>
                    <span className='font-medium'>{_("published-to")}:</span> {format(article.publishedTo, "PPPp")}
                  </span>
                )}
              </Field>
            )}
          </Fields>
        </SeoItem>
        <ContentItems />
        <StatsDialog {...statsProps} />
        <Confirm {...deleteProps} />
        <ArticleUpdateDialog {...updateProps} />
      </PageWrapper>
    </CmsContextProvider>
  )
}

const Page: React.FC<{ id: string }> = ({ id }) => {
  const { _ } = useDictionary(dictionary())
  const article = useArticle(id)
  const [result, inProgress] = usePromise(() => getArticle(id))
  if (inProgress) return <Loader breadcrumbs={[[_("breadcrumbs"), "/dashboard/articles"]]} />
  if (!result || result.error || G.isNullable(article)) return <Redirect to='/dashboard/articles' />
  return <ArticlesArticle {...{ article }} />
}
export default Page

/**
 * ContextMenu
 */
type ContextMenuProps = {
  article: Article
  confirmDelete: () => void
  toggleState: () => void
  update: (article: Article) => void
}
export const ContextMenu: React.FC<ContextMenuProps> = ({ article, confirmDelete, toggleState, update }) => {
  const { _ } = useDictionary(dictionary("menu"))
  return (
    <>
      <Menu.Item onClick={() => update(article)}>
        <FilePen aria-hidden />
        {_("update")}
      </Menu.Item>
      <Menu.Item onClick={toggleState}>
        {article.state === "draft" && <Eye aria-hidden />}
        {article.state === "published" && <EyeOff aria-hidden />}
        {_(article.state === "published" ? "unpublish" : "publish")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete()}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
    </>
  )
}

/**
 * helpers
 */
const textPlaceholder = (value: string, placeholder: string) => (S.isEmpty(S.trim(value ?? "")) ? placeholder : value)
