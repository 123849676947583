import { PageContent } from "@/components/layout/dashboard"
import { Grid } from "@/components/layout/dashboard/Collection"
import {
  Toolbar,
  ToolbarFilters,
  ToolbarFiltersButton,
  ToolbarSearch,
  ToolbarSort,
  ToolbarView,
} from "@/components/layout/dashboard/Toolbar"
import { Button } from "@/components/radix/ui/button"
import { useDictionary } from "@/dictionaries/hooks"
import { useFilteredUsers } from "@/store/users/hooks"
import { UserCheck, UserX } from "lucide-react"
import { usePageContext } from "./Context"
import { Item } from "./Item"
import { createContextMapper } from "@/dictionaries/helpers"
import { Selection } from "@/components/layout/dashboard/Collection/Selection"
import { useIsAdmin } from "@/store/auth/hooks"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { sortable, matchable, filtered, filterable } = useFilteredUsers()
  const { view, setView, create, selected, setSelected, confirmDeleteSelection } = usePageContext()
  const isAdmin = useIsAdmin()
  return (
    <PageContent>
      {isAdmin && (
        <Selection selected={selected} unselect={() => setSelected([])} deleteSelection={confirmDeleteSelection} />
      )}
      <Toolbar>
        <ToolbarSearch {...matchable} />
        <Button onClick={create}>{_("create")}</Button>
        <Filters {...filterable} />
        <ToolbarSort {...sortable} dictionary={dictionary("sort")} />
        <ToolbarView {...{ view, setView }} />
      </Toolbar>
      <Grid {...{ view }}>
        {A.map([...filtered], (user) => (
          <Item key={user.id} user={user} />
        ))}
      </Grid>
    </PageContent>
  )
}

/**
 * Filters
 */
type Props = ReturnType<typeof useFilteredUsers>["filterable"]
const Filters: React.FC<Props> = ({ toggle, isActive }) => {
  const { _ } = useDictionary(dictionary("filters"))
  return (
    <ToolbarFilters>
      <ToolbarFiltersButton onClick={() => toggle("soft-deleted")}>
        {isActive("soft-deleted") ? (
          <>
            <UserX aria-hidden />
            {_("soft-deleted-true")}
          </>
        ) : (
          <>
            <UserCheck aria-hidden />
            {_("soft-deleted-false")}
          </>
        )}
      </ToolbarFiltersButton>
    </ToolbarFilters>
  )
}
