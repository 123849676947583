import { Button } from "@/components/radix/ui/button"
import DropdownMenu from "@/components/radix/ui/dropdown-menu"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { DisplayByName } from "./types"
import Tooltip from "@/components/radix/ui/tooltip"

/**
 * dictionary src/dictionaries/en/components/trackings.json
 */
const dictionary = createContextMapper("components", "trackings", "display-by")

/**
 * DisplayBySelector
 */
export const DisplayBySelector: React.FC<{
  displayBy: DisplayByName
  setDisplayBy: (displayBy: DisplayByName) => void
}> = ({ displayBy, setDisplayBy }) => {
  const { _ } = useDictionary(dictionary())
  const list: DisplayByName[] = ["days", "weeks", "months", "years"]
  return (
    <div className='flex gap-4 items-center'>
      <DropdownMenu>
        <Tooltip content={_("tooltip")} side='bottom'>
          <DropdownMenu.Trigger asChild>
            <Button variant='outline' size='xs'>
              {_(displayBy)}
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
        <DropdownMenu.Content align='end' side='bottom' className='w-56'>
          {A.map(list, (item) => (
            <DropdownMenu.Item onClick={() => setDisplayBy(item)} active={displayBy === item} key={item}>
              {_(item)}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  )
}
