import { Toolbar } from "./Toolbar"
import ToolbarButton from "./ToolbarButton"
import { ToolbarFilters, ToolbarFiltersButton } from "./ToolbarFilters"
import { ToolbarSearch } from "./ToolbarSearch"
import { ToolbarSort } from "./ToolbarSort"
import { ToolbarView } from "./ToolbarView"
export { Toolbar, ToolbarFilters, ToolbarFiltersButton, ToolbarSearch, ToolbarSort, ToolbarView }
export default Object.assign(Toolbar, {
  Search: ToolbarSearch,
  Button: ToolbarButton,
  Sort: ToolbarSort,
  Filters: Object.assign(ToolbarFilters, {
    Button: ToolbarFiltersButton,
  }),
  View: ToolbarView,
})
