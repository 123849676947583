import { usePromise } from "@/app/hooks/usePromise"
import { CmsContextProvider } from "@/components/cms/Context"
import { ContentItems } from "@/components/cms/components/content"
import SeoItem from "@/components/cms/components/seo"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Menu } from "@/components/layout/dashboard/Collection"
import { Loader } from "@/components/layout/dashboard/Collection/Loader"
import { Button } from "@/components/radix/ui/button"
import { StatsDialog } from "@/components/trackings/dialogs/StatsDialog"
import { Confirm, confirmSafeDictionary, useConfirm } from "@/components/ui/Confirm"
import { useDialog } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { deletePage, getPage, updatePage } from "@/store/pages/actions"
import { usePage } from "@/store/pages/hooks"
import { Page } from "@/store/pages/localizers"
import { BarChart3, Eye, EyeOff, Trash } from "lucide-react"
import { Redirect } from "wouter"

/**
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "pages")

/**
 * Page: PagesPage
 */
type PageProps = { id: string }
const PagesPage: React.FC<PageProps> = ({ id }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()

  const page = usePage(id)

  const { confirm: confirmDelete, ...deleteProps } = useConfirm<void>({
    onAsyncConfirm: () => deletePage(page.id),
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })
  const { setItem: openStats, ...statsProps } = useDialog<string>()
  const toggleState = () => updatePage(page.id, { state: page.state === "published" ? "draft" : "published" })

  const [result, inProgress] = usePromise(() => getPage(id))
  if (inProgress) return <Loader breadcrumbs={[[_("breadcrumbs"), "/dashboard/pages"]]} />
  if (!result || result.error || G.isNullable(page)) return <Redirect to='/dashboard/pages' />

  const isDraft = page.state === "draft"
  return (
    <CmsContextProvider ressource={{ type: "pages", ...D.selectKeys(page, ["id", "seo", "content"]) }}>
      <PageWrapper>
        <PageHeader
          breadcrumbs={[
            [_("breadcrumbs"), "/dashboard/pages"],
            [S.isEmpty(S.trim(t(page.seo).title ?? "")) ? _("no-title") : t(page.seo).title, `/dashboard/pages/${id}`],
          ]}
        />
        <SeoItem
          menu={<ContextMenu {...{ page, confirmDelete, toggleState }} />}
          action={
            <>
              <Button icon size='xxs' onClick={() => openStats(page.trackingId)}>
                <BarChart3 />
              </Button>
              <Button variant={isDraft ? "secondary" : "default"} icon size='xxs' onClick={toggleState}>
                {isDraft ? <EyeOff aria-label={_(`state-draft`)} /> : <Eye aria-label={_(`state-published`)} />}
              </Button>
            </>
          }
        />
        <ContentItems />
        <StatsDialog {...statsProps} />
        <Confirm {...deleteProps} />
      </PageWrapper>
    </CmsContextProvider>
  )
}

export default PagesPage

/**
 * ContextMenu
 */
type ContextMenuProps = { page: Page; confirmDelete: () => void; toggleState: () => void }
export const ContextMenu: React.FC<ContextMenuProps> = ({ page, confirmDelete, toggleState }) => {
  const { _ } = useDictionary(dictionary("menu"))
  return (
    <>
      <Menu.Item onClick={toggleState}>
        {page.state === "draft" && <Eye aria-hidden />}
        {page.state === "published" && <EyeOff aria-hidden />}
        {_(page.state === "published" ? "unpublish" : "publish")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete()}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
    </>
  )
}
