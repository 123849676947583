import { byId } from "@/app/fns/byId"
import * as Articles from "@/services/articles/service"
import * as Contents from "@/services/contents/service"
import * as Seos from "@/services/seos/service"
import { articlesStore } from "."
import { extractFilesFromContent, extractFilesFromContentItem } from "../contents/helpers"
import { localizeContent, localizeContentItem } from "../contents/localizers"
import { mediasStore } from "../medias"
import { localizeMediaFile } from "../medias/localizers"
import { extractFilesFromSeo } from "../seos/helpers"
import { localizeSeo } from "../seos/localizers"
import { usersStore } from "../users"
import { localizeUser } from "../users/localizers"
import {
  extractCategoriesFromArticles,
  extractFilesFromArticle,
  extractFilesFromArticles,
  extractUsersFromArticles,
} from "./helpers"
import { localizeArticle, localizeArticleCategory } from "./localizers"

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * articles
 */

/**
 * getArticles
 */
export const getArticles = async () => {
  const { data, error, code } = await Articles.service.index()
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromArticles(data.articles), localizeMediaFile)) })
    usersStore.evolve({ users: D.merge(byId(extractUsersFromArticles(data.articles), localizeUser)) })
    articlesStore.evolve({
      articles: byId(data.articles, localizeArticle),
      categories: D.merge(byId(extractCategoriesFromArticles(data.articles), localizeArticleCategory)),
    })
  }
  return { error, code } as const
}

/**
 * createArticle
 */
export const createArticle = async (payload: Articles.Payload["create"]) => {
  const { data, error, code } = await Articles.service.create(payload)
  if (!error) {
    usersStore.evolve({ users: D.merge(byId(extractUsersFromArticles([data.article]), localizeUser)) })
    articlesStore.evolve({
      articles: D.set(data.article.id, localizeArticle(data.article)),
      categories: D.merge(byId(extractCategoriesFromArticles([data.article]), localizeArticleCategory)),
    })
  }
  return { error, code, id: data?.article.id ?? "" } as const
}

/**
 * getArticle
 */
export const getArticle = async (id: string) => {
  const { data, error, code } = await Articles.service.read(id)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromArticle(data.article), localizeMediaFile)) })
    usersStore.evolve({ users: D.merge(byId(extractUsersFromArticles([data.article]), localizeUser)) })
    articlesStore.evolve({
      articles: D.set(data.article.id, localizeArticle(data.article)),
      categories: D.merge(byId(extractCategoriesFromArticles([data.article]), localizeArticleCategory)),
    })
  }
  return { error, code } as const
}

/**
 * updateArticle
 */
export const updateArticle = async (id: string, payload: Articles.Payload["update"]) => {
  const { data, error, code } = await Articles.service.update(id, payload)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromArticle(data.article), localizeMediaFile)) })
    usersStore.evolve({ users: D.merge(byId(extractUsersFromArticles([data.article]), localizeUser)) })
    articlesStore.evolve({
      articles: D.set(data.article.id, localizeArticle(data.article)),
      categories: D.merge(byId(extractCategoriesFromArticles([data.article]), localizeArticleCategory)),
    })
  }
  return { error, code } as const
}

/**
 * deleteArticle
 */
export const deleteArticle = async (id: string) => {
  const { error, code } = await Articles.service.delete(id)
  if (!error) articlesStore.evolve({ articles: D.deleteKey(id) })
  return { error, code } as const
}

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * seos
 */

/**
 * updateArticleSeo
 */
export const updateArticleSeo = async (id: string, payload: Seos.Payload["update"]) => {
  const { data, error, code } = await Seos.service.update("articles", id, payload)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromSeo(data.seo), localizeMediaFile)) })
    articlesStore.evolve({
      articles: {
        [id]: {
          seo: localizeSeo(data.seo),
        },
      },
    })
  }
  return { error, code } as const
}

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * contents
 */

/**
 * updateArticleContent
 */
export const updateArticleContent = async (id: string, payload: Contents.Payload["update"]) => {
  const { data, error, code } = await Contents.service.update("articles", id, payload)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromContent(data.content), localizeMediaFile)) })
    articlesStore.evolve({
      articles: {
        [id]: {
          content: localizeContent(data.content),
        },
      },
    })
  }
  return { error, code } as const
}

/**
 * createArticleContentItem
 */
export const createArticleContentItem = async (id: string, payload: Contents.Payload["items"]["create"]) => {
  const { data, error, code } = await Contents.service.items.create("articles", id, payload)
  if (G.isNullable(D.get(articlesStore.current.articles, id))) return { error: true, code: "STORE_OUTDATED" } as const
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromContentItem(data.item), localizeMediaFile)) })
    articlesStore.evolve({
      articles: {
        [id]: {
          content: {
            items: flow(
              D.set(data.item.id, localizeContentItem(data.item)),
              D.map((item) => D.set(item, "order", A.getIndexBy(data.sortedIds, (id) => id === item.id) ?? 0))
            ),
          },
        },
      },
    })
  }
  return { error, code } as const
}

/**
 * updateArticlesContentItem
 */
export const updateArticleContentItem = async (
  id: string,
  itemId: string,
  payload: Contents.Payload["items"]["update"]
) => {
  const { data, error, code } = await Contents.service.items.update("articles", id, itemId, payload)
  if (!error) {
    mediasStore.evolve({ files: D.merge(byId(extractFilesFromContentItem(data.item), localizeMediaFile)) })
    articlesStore.evolve({
      articles: {
        [id]: {
          content: {
            items: D.set(data.item.id, localizeContentItem(data.item)),
          },
        },
      },
    })
  }
  return { error, code } as const
}

/**
 * deleteArticleContentItem
 */
export const deleteArticleContentItem = async (id: string, itemId: string) => {
  const { error, code } = await Contents.service.items.delete("articles", id, itemId)
  if (!error)
    articlesStore.evolve({
      articles: {
        [id]: {
          content: {
            items: D.deleteKey(itemId),
          },
        },
      },
    })
  return { error, code } as const
}

/**
 * reorderArticleContentItems
 */
export const reorderArticleContentItems = async (id: string, payload: Contents.Payload["items"]["reorder"]) => {
  const article = D.get(articlesStore.current.articles, id)
  if (G.isNullable(article)) return { error: true, code: "STORE_OUTDATED" } as const
  const originalSortedIds = pipe(article.content.items, D.values, A.sortBy(D.prop("order")), A.map(D.prop("id")))
  articlesStore.evolve({
    articles: {
      [id]: {
        content: {
          items: flow(D.map((item) => D.set(item, "order", A.getIndexBy(payload.items, (id) => id === item.id) ?? 0))),
        },
      },
    },
  })
  const { data, error, code } = await Contents.service.items.reorder("articles", id, payload)
  if (!error) {
    articlesStore.evolve({
      articles: {
        [id]: {
          content: {
            items: flow(
              D.map((item) => D.set(item, "order", A.getIndexBy(data.sortedIds, (id) => id === item.id) ?? 0))
            ),
          },
        },
      },
    })
  } else {
    articlesStore.evolve({
      articles: {
        [id]: {
          content: {
            items: flow(
              D.map((item) => D.set(item, "order", A.getIndexBy(originalSortedIds, (id) => id === item.id) ?? 0))
            ),
          },
        },
      },
    })
  }
  return { error, code } as const
}

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * categories
 */

/**
 * getArticleCategories
 */
export const getArticleCategories = async () => {
  const { data, error, code } = await Articles.service.categories.index()
  if (!error) articlesStore.evolve({ categories: byId(data.categories, localizeArticleCategory) })
  return { error, code } as const
}

/**
 * createArticleCategory
 */
export const createArticleCategory = async (payload: Articles.Payload["categories"]["create"]) => {
  const { data, error, code } = await Articles.service.categories.create(payload)
  if (!error) articlesStore.evolve({ categories: D.set(data.category.id, localizeArticleCategory(data.category)) })
  return { error, code } as const
}

/**
 * getArticleCategory
 */
export const getArticleCategory = async (id: string) => {
  const { data, error, code } = await Articles.service.categories.read(id)
  if (!error)
    articlesStore.evolve({
      categories: D.set(data.category.id, localizeArticleCategory(data.category)),
      articles: D.merge(byId(data.articles, localizeArticle)),
    })
  return { error, code } as const
}

/**
 * updateArticleCategory
 */
export const updateArticleCategory = async (id: string, payload: Articles.Payload["categories"]["update"]) => {
  const { data, error, code } = await Articles.service.categories.update(id, payload)
  if (!error) articlesStore.evolve({ categories: D.set(data.category.id, localizeArticleCategory(data.category)) })
  return { error, code } as const
}

/**
 * deleteArticleCategory
 */
export const deleteArticleCategory = async (id: string) => {
  const { error, code } = await Articles.service.categories.delete(id)
  if (!error) articlesStore.evolve({ categories: D.deleteKey(id) })
  return { error, code } as const
}

/**
 * cmsActions
 */
export const cmsActions = {
  updateSeo: updateArticleSeo,
  updateContent: updateArticleContent,
  createContentItem: createArticleContentItem,
  updateContentItem: updateArticleContentItem,
  deleteContentItem: deleteArticleContentItem,
  reorderContentItems: reorderArticleContentItems,
}
