import { create } from "zustand"
import { decorateStore } from "@/app/fns/decorateStore"
import { Page } from "./localizers"

/**
 * store
 */
export type PagesStoreState = {
  pages: ById<Page>
}

/**
 * initialState
 */
const initialState: PagesStoreState = {
  pages: {},
}

/**
 * store
 */
export const pagesStore = decorateStore(initialState, create, {})
export const usePagesStore = pagesStore.use
