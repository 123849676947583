import { Language } from "@/store/languages/localizers"
import { FormFieldGroup } from "./FormFieldGroup"

export const FormTranslationContext: React.FC<{ language: Language; children: React.ReactNode }> = ({
  language,
  children,
}) => {
  return (
    <FormFieldGroup name='translations'>
      <FormFieldGroup name={language.id}>{children}</FormFieldGroup>
    </FormFieldGroup>
  )
}
