import { decorateStore } from "@/app/fns/decorateStore"
import { create } from "zustand"
import { Language } from "./localizers"

/**
 * types
 */
export type LanguagesStoreState = {
  initDone: boolean
  languages: ById<Language>
}

/**
 * initialState
 */
const initialState: LanguagesStoreState = {
  initDone: false,
  languages: {},
}

/**
 * store
 */
export const languagesStore = decorateStore(initialState, create, {})
export const useLanguagesStore = languagesStore.use
