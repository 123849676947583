import { byId } from "@/app/fns/byId"
import * as Users from "@/services/users/service"
import { usersStore } from "."
import { localizeUser } from "./localizers"

/**
 * getUsers
 */
export const getUsers = async () => {
  const { data, error, code } = await Users.service.index()
  if (!error) {
    usersStore.evolve({ users: byId(data.users, localizeUser) })
  }
  return { error, code } as const
}

/**
 * getUser
 */
export const getUser = async (id: string) => {
  const { data, error, code } = await Users.service.read(id)
  if (!error) usersStore.evolve({ users: D.set(data.user.id, localizeUser(data.user)) })
  return { error, code } as const
}

/**
 * createUser
 */
export const createUser = async (payload: Users.Payload["create"]) => {
  const { data, error, code } = await Users.service.create(payload)
  if (!error) usersStore.evolve({ users: D.set(data.user.id, localizeUser(data.user)) })
  return { error, code, id: data?.user.id } as const
}

/**
 * updateUser
 */
export const updateUser = async (id: string, payload: Users.Payload["update"]) => {
  const { data, error, code } = await Users.service.update(id, payload)
  if (!error) usersStore.evolve({ users: D.set(data.user.id, localizeUser(data.user)) })
  return { error, code } as const
}

/**
 * deleteUser
 */
export const deleteUser = async (id: string) => {
  const { error, code } = await Users.service.delete(id)
  if (!error) usersStore.evolve({ users: D.deleteKey(id) })
  return { error, code } as const
}

/**
 * updateProfile
 */
export const updateProfile = async (id: string, payload: Users.Payload["profile"]["update"]) => {
  const { data, error, code } = await Users.service.profile.update(id, payload)
  if (!error) usersStore.evolve({ users: D.set(data.user.id, localizeUser(data.user)) })
  return { error, code } as const
}
