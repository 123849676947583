import { Dialog } from "@/components/ui/Dialog"
import { useDictionary } from "@/dictionaries/hooks"
import { useLanguages } from "@/store/languages/hooks"
import { useCmsContext } from "../Context"
import Items from "../items"
import { Button, buttonFocus } from "@/components/radix/ui/button"
import { createContextMapper } from "@/dictionaries/helpers"
import { SrOnly } from "@/components/radix/ui/sr-only"
import Popover from "@/components/radix/ui/popover"
import { InfoIcon } from "lucide-react"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * UpdateSeoDialogs
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: number | false
}
export const CreateItemDialog: React.FC<Props> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("create-item-dialog"))
  return (
    <Dialog
      open={props.open}
      onOpenChange={props.onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className="sm:max-w-xl"
    >
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}
const DialogForm: React.FC<Props & { item: number }> = ({ onOpenChange, item: order }) => {
  const {
    actions: { createContentItem },
  } = useCmsContext()
  const { _ } = useDictionary(dictionary())
  const languages = useLanguages()
  return (
    <div className="grid grid-cols-4 gap-4">
      {A.map(D.keys(Items), (item) => {
        const Thumbnail = Items[item].ItemThumbnail
        const create = Items[item].create
        return (
          <div
            key={item}
            className={cx(
              "relative flex justify-center items-center aspect-square border border-input shadow-sm rounded-md"
            )}
          >
            <button
              className={cx("absolute inset-0 w-full h-full rounded-md", buttonFocus)}
              type="button"
              onClick={() => {
                onOpenChange(false)
                createContentItem(create(order + 1, languages))
              }}
            >
              <SrOnly>{_("create-item-dialog.create-item")}</SrOnly>
            </button>
            <Popover>
              <Popover.Trigger asChild>
                <Button variant="ghost" icon size="xs" className="absolute top-1 right-1">
                  <SrOnly>{_("create-item-dialog.info")}</SrOnly>
                  <InfoIcon size={16} aria-hidden />
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <h3 className="text-lg font-medium">{_(`content.items.${item}.title`)}</h3>
                <p className="text-sm">{_(`content.items.${item}.description`)}</p>
              </Popover.Content>
            </Popover>
            <div className="flex flex-col justify-center items-center w-full h-full p-2 gap-4 pointer-events-none">
              <Thumbnail />
            </div>
          </div>
        )
      })}
    </div>
  )
}
