import { ExtraField } from "@/store/types"

/**
 * Fields
 */
type FieldsProps = React.ComponentPropsWithoutRef<"ul"> & {
  divider?: boolean
}
export const Fields = React.forwardRef<HTMLUListElement, FieldsProps>(
  ({ className, divider = false, ...props }, ref) => (
    <ul
      className={cx("@container/fields group/fields flex flex-col text-sm", divider && "divide-y", className)}
      ref={ref}
      {...props}
    />
  )
)

/**
 * Field
 */
type FieldProps = Extend<
  React.ComponentPropsWithoutRef<"li">,
  {
    name: React.ReactNode
    icon?: React.ReactNode
    value?: React.ReactNode
    stretch?: boolean
  }
>
export const Field = React.forwardRef<HTMLLIElement, FieldProps>(
  ({ name, icon, value, stretch = false, className, children, ...props }, ref) => (
    <li
      className={cx(
        "flex flex-col @sm/fields:grid items-start grid-cols-3 gap-x-2",
        stretch ? "py-2" : "py-4",
        className
      )}
      ref={ref}
      {...props}
    >
      <span className='flex items-center gap-2 font-medium [&>svg]:w-3 [&>svg]:h-3'>
        {icon}
        {name}
      </span>
      <span className='flex flex-col col-span-2'>{value ?? children}</span>
    </li>
  )
)

/**
 * ExtraFields
 */
export const ExtraFields: React.FC<{
  fields: ExtraField[]
  icon?: React.ReactNode
  wrapper?: (value: string) => React.ReactNode
}> = ({ fields, icon = null, wrapper = (value) => value }) =>
  A.isNotEmpty(fields) ? (
    <>
      {A.mapWithIndex(fields, (index, { name, value }) => (
        <Field name={name} value={wrapper(value)} icon={icon} key={index} />
      ))}
    </>
  ) : null
