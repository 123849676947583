import { Form, FormAssertive, FormHeader, FormInput, FormSelect, useForm } from "@/components/form"
import { FilesType } from "@/components/medias/components/selectFiles"
import {
  FormMediasFiles,
  FormMediasImage,
  FormMediasImages,
  FormMediasPdf,
  FormMediasPdfs,
  FormMediasVideo,
} from "@/components/medias/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog } from "@/components/ui/Dialog"
import { Bug } from "lucide-react"

export const MediaFormTest: React.FC = () => {
  const contextKey = "medias"
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      video: null as null | string,
      pdf: null as null | string,
      pdfs: [] as string[],
      image: null as null | string,
      images: [] as string[],
      fileType: "*" as FilesType,
      fileMax: 10,
      file: [] as string[],
      files: [] as string[],
    },
    onSubmit: async () => {},
  })

  const [open, setOpen] = React.useState(false)
  const fileTypeOptions = [
    { value: "*", label: "*" },
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
    { value: "pdf", label: "Pdf" },
    { value: "audio", label: "Audio" },
  ]

  return (
    <>
      <Button variant="ghost" icon onClick={() => setOpen(true)}>
        <Bug aria-hidden />
      </Button>
      <Dialog
        open={open}
        onOpenChange={setOpen}
        title="Media form playground"
        description="You can test each form field of file manager"
      >
        <Form form={form} className="grid gap-6">
          <FormAssertive />
          <FormHeader>
            <FormHeader.Title>Specifics medias fields</FormHeader.Title>
            <FormHeader.Description>Try different way to select some specifics medias</FormHeader.Description>
          </FormHeader>
          <FormMediasVideo label={"Video"} name="video" contextKey={contextKey} />

          <FormMediasPdf label={"Pdf"} name="pdf" contextKey={contextKey} />
          <FormMediasPdfs label={"Pdfs"} name="pdfs" contextKey={contextKey} />

          <FormMediasImage label={"Image"} name="image" contextKey={contextKey} />
          <FormMediasImages label={"Images"} name="images" contextKey={contextKey} />
          <FormHeader>
            <FormHeader.Title>Default media files fields</FormHeader.Title>
            <FormHeader.Description>Try different way to select a file</FormHeader.Description>
          </FormHeader>
          <FormMediasFiles label={"Single file"} name="file" max={1} contextKey={contextKey} />
          <div className="grid grid-cols-2 gap-6">
            <FormSelect label={"File type"} name="fileType" options={fileTypeOptions} />
            <FormInput label={"Number of file"} name="fileMax" type="number" />
          </div>
          <FormMediasFiles
            label={"Files"}
            name="files"
            max={+form.values.fileMax}
            type={form.values.fileType}
            contextKey={contextKey}
          />
        </Form>
      </Dialog>
    </>
  )
}
