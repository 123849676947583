import { Button } from "@/components/radix/ui/button"
import { useDictionary } from "@/dictionaries/hooks"
import { MoreVertical } from "lucide-react"
import Menu from "./Menu"
import { SrOnly } from "@/components/radix/ui/sr-only"

/**
 * Section
 */
export const Section: React.FC<React.ComponentPropsWithoutRef<"section">> = ({ className, ...props }) => (
  <section
    className={cx(
      "flex flex-col max-w-screen-2xl mx-auto mt-4 px-8 py-8 gap-4 bg-card rounded-md shadow-sm",
      className
    )}
    {...props}
  />
)

/**
 * SectionHeader
 */
export const SectionHeader = React.forwardRef<React.ElementRef<"div">, React.ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx("relative flex flex-col space-y-1.5 pt-4 text-center sm:text-left pr-12", className)}
      {...props}
    />
  )
)

/**
 * SectionHeaderTitle
 */
export const SectionHeaderTitle = React.forwardRef<React.ElementRef<"h3">, React.ComponentPropsWithoutRef<"h3">>(
  ({ className, ...props }, ref) => (
    <h3 ref={ref} className={cx("text-base font-normal leading-none tracking-tight", className)} {...props} />
  )
)

/**
 * SectionHeaderDescription
 */
export const SectionHeaderDescription = React.forwardRef<React.ElementRef<"p">, React.ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => (
    <p ref={ref} className={cx("text-sm text-muted-foreground font-light", className)} {...props} />
  )
)

/**
 * SectionMenu
 */
export const SectionMenu: React.FC<React.ComponentPropsWithoutRef<"button"> & { menu: React.ReactNode }> = ({
  className,
  menu,
  ...props
}) => {
  const { _ } = useDictionary("components.layout.menu")
  return (
    <Menu menu={menu} type="dropdown-menu" align="start" side="left">
      <Button
        variant="ghost"
        size="xs"
        icon
        className={cx("absolute top-2 right-2 rounded-full", className)}
        {...props}
      >
        <MoreVertical aria-hidden />
        <SrOnly>{_("button")}</SrOnly>
      </Button>
    </Menu>
  )
}
SectionMenu.displayName = "SectionMenu"

/**
 * default export
 */
export default Object.assign(Section, {
  Header: SectionHeader,
  HeaderTitle: SectionHeaderTitle,
  HeaderDescription: SectionHeaderDescription,
  Menu: SectionMenu,
})
