import useIsFirstRender from "@/app/hooks/useIsFirstRender"
import { useDictionary } from "@/dictionaries/hooks"
import { ExtraField } from "@/store/types"
import { Plus, X } from "lucide-react"
import {
  FormGroup,
  FormGroupError,
  FormGroupInfo,
  FormInputType,
  FormLabel,
  FormTypes,
  inputIconVariants,
  inputVariants,
  useFieldContext,
} from "."
import { Button } from "../radix/ui/button"
import { SrOnly } from "../radix/ui/sr-only"

/**
 * FormExtraFields
 */
type Props = {
  label?: string
  name?: string
  info?: string | false
} & ExtraFieldsProps
export const FormExtraFields: React.FC<Props> = ({ label, name, info, ...props }) => {
  return (
    <FormGroup name={name}>
      <FormLabel>{label}</FormLabel>
      <ExtraFields {...props} />
      <FormGroupError />
      {info !== false && <FormGroupInfo>{info}</FormGroupInfo>}
    </FormGroup>
  )
}

/**
 * ExtraFields
 */
type ExtraFieldsProps = {
  className?: ClassName
  translate?: (key: string) => string
  type?: FormInputType
  auto?: {
    name?: FormTypes.Auto | string
    value?: FormTypes.Auto | string
  }
}
export const ExtraFields: React.FC<ExtraFieldsProps> = ({ auto, className, translate, type = "text" }) => {
  const { _: defaultTranslate } = useDictionary("components.form.form-extra-fields")
  const _ = translate ?? defaultTranslate

  const { value, setFieldValue, disabled, name } = useFieldContext<ExtraField[]>()
  const id = name

  const autoFocus = !useIsFirstRender()
  const addButtonRef = React.useRef<HTMLButtonElement>(null)

  const onChange = (newValue: string, index: number, type: keyof ExtraField) =>
    setFieldValue(
      A.replaceAt(value, index, {
        ...A.getUnsafe(value, index),
        [type]: newValue,
      } as ExtraField)
    )

  const autoComplete = React.useMemo(
    () =>
      D.map(D.merge({ name: false, value: true }, D.filter(auto ?? {}, G.isNotNullable)), (v) =>
        G.isString(v) ? v : v ? "on" : "off"
      ),
    [auto]
  )
  return (
    <div className={cx("@container/input flex flex-col w-full gap-4", className)}>
      {A.isNotEmpty(value) && (
        <ul className="flex flex-col w-full gap-4">
          {A.mapWithIndex(value, (index, field) => (
            <li className="grid @xs/input:grid-cols-3 gap-4" key={index}>
              <div>
                <SrOnly htmlFor={`${id}-name-${index}`} as="label">
                  {_("name-placeholder")}
                </SrOnly>
                <input
                  className={inputVariants()}
                  id={`${id}-name-${index}`}
                  name={`${id}-name-${index}`}
                  autoComplete={autoComplete.name}
                  type="text"
                  disabled={disabled}
                  placeholder={_("name-placeholder")}
                  value={field.name}
                  onChange={({ target }) => onChange(target.value, index, "name")}
                />
              </div>
              <div className="relative @xs/input:col-span-2">
                <SrOnly htmlFor={`${id}-value-${index}`} as="label">
                  {_("value-placeholder")}
                </SrOnly>
                <input
                  className={inputVariants({ icon: "right" })}
                  id={`${id}-value-${index}`}
                  name={`${id}-value-${index}`}
                  autoComplete={autoComplete.value}
                  type={type}
                  disabled={disabled}
                  placeholder={_("value-placeholder")}
                  autoFocus={autoFocus}
                  value={field.value}
                  onChange={({ target }) => onChange(target.value, index, "value")}
                />
                <Button
                  disabled={disabled}
                  variant="ghost"
                  className={inputIconVariants({ size: "default", side: "right" })}
                  icon
                  onClick={() => {
                    addButtonRef.current?.focus()
                    setFieldValue(A.removeAt(value, index))
                  }}
                >
                  <X aria-hidden />
                  <SrOnly>{_("button-delete")}</SrOnly>
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="flex">
        <Button
          ref={addButtonRef}
          disabled={disabled}
          variant="outline"
          onClick={() => setFieldValue(A.append(value, { name: _("name-default"), value: _("value-default") }))}
        >
          <Plus size={16} aria-hidden />
          {_("button-add")}
        </Button>
      </div>
    </div>
  )
}
