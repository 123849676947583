import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  toSelectOptions,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { useDictionary } from "@/dictionaries/hooks"
import { updateSession } from "@/store/auth/actions"
import { useMe } from "@/store/auth/hooks"
import { updateUser } from "@/store/users/actions"
import { User } from "@/store/users/localizers"
import { confirmAlert } from "../ui/Confirm"

/**
 * UserAccountDialog
 * dictionary src/dictionaries/en/components/dialogs/user-account-dialog.json
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: User | false
}
export const UserAccountDialog: React.FC<Props> = ({ item, open, onOpenChange }) => {
  const { _ } = useDictionary("components.dialogs.user-account-dialog")
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='max-w-xl'
    >
      {item !== false && <DialogForm close={() => onOpenChange(false)} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 * dictionary src/dictionaries/en/components/dialogs/user-account-dialog.json
 */
const DialogForm: React.FC<{ item: User; close: () => void }> = ({ item: user, close }) => {
  const { _ } = useDictionary("components.dialogs.user-account-dialog")
  const me = useMe()
  const isMe = me.id === user.id
  const isSuperadmin = me.role === "superadmin"
  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      email: user?.email ?? "",
      role: user.role,
      status: user.status,
      password: "",
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      email: [min(1, "email-required"), isEmail("email-format")],
      password: [(field) => S.isEmpty(S.trim(field)) || N.gte(S.length(S.trim(field)), 8) || "password-length"],
    }),
    onSubmit: async ({ values: { email, role, status, password: maybePassword } }) => {
      if (!form.isValid) return "VALIDATION_FAILURE"
      const data = {
        email,
        role: role,
        status: status,
        password: S.isEmpty(maybePassword) ? undefined : S.trim(maybePassword),
      }
      // alert if user is trying to change his own role
      if (isMe && data.role !== me.role) {
        const confirmation = await confirmAlert({
          dictionary: "components.dialogs.user-account-dialog.confirm-change-role",
        })
        if (!confirmation) return ""
      }
      // alert if user is trying to change his own status
      if (isMe && data.status !== me.status) {
        const confirmation = await confirmAlert({
          dictionary: "components.dialogs.user-account-dialog.confirm-change-status",
        })
        if (!confirmation) return ""
      }
      const response = await updateUser(user.id, data)
      if (!response.error) {
        toast.success(_("success"))
        if (isMe) await updateSession()
        close()
      } else return response.code
    },
  })

  const disabled = user.role === "superadmin" && !isSuperadmin
  const roleOption = [
    {
      label: _("role-member"),
      value: "member",
    },
    {
      label: _("role-admin"),
      value: "admin",
    },
    {
      label: _("role-superadmin"),
      value: "superadmin",
      disabled: !isSuperadmin,
    },
  ]

  return (
    <Form form={form} className='grid gap-6'>
      {disabled && <div className='text-sm text-foreground/75 font-light'>{_("superadmin-message")}</div>}
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-connection-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-connection-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormInput
          label={_("email-label")}
          name='email'
          type='email'
          placeholder={_("email-placeholder")}
          disabled={disabled}
        />
        <FormInput
          label={_("password-label")}
          name='password'
          type='password'
          placeholder={_("password-placeholder")}
          disabled={disabled}
        />
      </FormSection>
      <FormHeader>
        <FormHeader.Title>{_("section-settings-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-settings-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormSelect
          label={_("role-label")}
          name='role'
          placeholder={_("role-placeholder")}
          disabled={disabled}
          options={roleOption}
        />
        <FormSelect
          label={_("status-label")}
          name='status'
          placeholder={_("status-placeholder")}
          disabled={disabled}
          options={toSelectOptions(
            ["pending", "active", "deleted", "suspended"],
            _ as (ctx: string) => string,
            "status"
          )}
        />
      </FormSection>
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
