import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { updateMediasFile, updateMediasFolder } from "@/store/medias/actions"
import { getChildrenFolderIds } from "@/store/medias/helpers"
import { useMediasFilesById, useMediasFoldersById } from "@/store/medias/hooks"
import { confirmSelection, useMediaContext } from "../Context"
import { SelectFolder } from "../components/selectFolder"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias", "move-selection-dialog")

/**
 * MoveSelectionDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: void | false
}
export const MoveSelectionDialog: React.FC<Props> = ({ item, open, onOpenChange }) => {
  const { selected, currentFolder } = useMediaContext()
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      {item !== false && (
        <DialogForm
          close={() => onOpenChange(false)}
          selection={{
            folders: [...selected.folders],
            files: [...selected.files],
          }}
          currentFolder={currentFolder}
        />
      )}
    </Dialog>
  )
}

/**
 * DialogForm
 */
type DialogFormProps = {
  selection: {
    folders: string[]
    files: string[]
  }
  currentFolder: string | null
  close: () => void
}
const DialogForm: React.FC<DialogFormProps> = ({ selection, currentFolder, close }) => {
  const { _ } = useDictionary(dictionary())

  const [selected, setSelected] = React.useState<string | null>(currentFolder)
  const { resetSelection } = useMediaContext()

  const translate = useTranslation()
  const filesById = useMediasFilesById()
  const foldersById = useMediasFoldersById()
  const displayFileName = (id: string) => {
    const file = D.get(filesById, id)
    return G.isNotNullable(file) ? translate(file).name : id
  }
  const displayFolderName = (id: string) => foldersById[id]?.name ?? id

  const onSubmit = async (nextId?: string) => {
    const moveId = nextId ?? selected
    close()
    await confirmSelection(
      selection,
      (id: string) => updateMediasFolder(id, { parentId: moveId }),
      displayFolderName,
      (id: string) => updateMediasFile(id, { folderId: moveId }),
      displayFileName,
      `move-selection-confirm`,
      _,
      resetSelection
    )()
  }

  const disabledIds = React.useMemo(
    () => [
      ...A.reduce(selection.folders, [], (ids: string[], currentId) => [
        ...ids,
        ...getChildrenFolderIds(currentId, D.values(foldersById)),
        currentId,
      ]),
    ],
    [selection.folders, foldersById, currentFolder]
  )
  const submitDisabled = selected === currentFolder || disabledIds.includes(selected ?? "")

  return (
    <>
      <SelectFolder current={currentFolder} {...{ selected, setSelected, onSubmit, disabledIds }} />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <Button
          onClick={(e) => {
            e.stopPropagation()
            onSubmit()
          }}
          disabled={submitDisabled}
        >
          {_("submit")}
        </Button>
      </DialogFooter>
    </>
  )
}
