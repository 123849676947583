import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { createMediasFolder } from "@/store/medias/actions"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * CreateFolderDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: string | false | null
}
export const CreateFolderDialog: React.FC<Props> = ({ item, open, onOpenChange }) => {
  const { _ } = useDictionary(dictionary("create-folder-dialog"))
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      {item !== false && <DialogForm close={() => onOpenChange(false)} parentId={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<{ parentId: string | null; close: () => void }> = ({ parentId, close }) => {
  const { _ } = useDictionary(dictionary("create-folder-dialog"))
  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      name: "",
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      name: [min(1, "name-required")],
    }),
    onSubmit: async ({ values: { name } }, event) => {
      event?.stopPropagation()
      if (!form.isValid) return "VALIDATION_FAILURE"
      const response = await createMediasFolder({ name, parentId })
      if (!response.error) {
        close()
        toast.success(_("success"))
      } else return response.code
    },
  })

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormInput label={_("name-label")} name='name' placeholder={_("name-placeholder")} />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
