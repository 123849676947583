import { PageContent } from "@/components/layout/dashboard"
import { Grid } from "@/components/layout/dashboard/Collection"
import Toolbar from "@/components/layout/dashboard/Toolbar"
import { createContextMapper } from "@/dictionaries/helpers"
import { dictionarySafe, useDictionary } from "@/dictionaries/hooks"
import { useFilteredArticles } from "@/store/articles/hooks"
import { usePageContext } from "./Context"
import { Item } from "./Item"
import { Selection } from "@/components/layout/dashboard/Collection/Selection"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { sortable, matchable, filtered } = useFilteredArticles()
  const {
    view,
    setView,
    create,
    selected,
    setSelected,
    confirmDeleteSelection,
    confirmPublishSelection,
    confirmUnpublishSelection,
  } = usePageContext()
  return (
    <PageContent>
      <Selection
        selected={selected}
        unselect={() => setSelected([])}
        deleteSelection={confirmDeleteSelection}
        publishSelection={confirmPublishSelection}
        unpublishSelection={confirmUnpublishSelection}
      />
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={create}>{_("create")}</Toolbar.Button>
        <Toolbar.Sort {...sortable} dictionary={dictionarySafe(dictionary())} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Grid {...{ view }}>
        {A.map([...filtered], (article) => (
          <Item key={article.id} article={article} />
        ))}
      </Grid>
    </PageContent>
  )
}
export default Collection
