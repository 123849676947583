import { decorateStore } from "@/app/fns/decorateStore"
import { create } from "zustand"
import { User } from "./localizers"

/**
 * types
 */
export type UsersStoreState = {
  users: ById<User>
}

/**
 * initialState
 */
const initialState: UsersStoreState = {
  users: {},
}

/**
 * Store
 */
export const usersStore = decorateStore(initialState, create, {})
export const useUsersStore = usersStore.use
