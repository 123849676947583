import { useTranslation } from "@/store/languages/hooks"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"
import { useMediasFile } from "@/store/medias/hooks"
import { Image } from "@/components/radix/ui/image"
import { ArrowRight, ImageIcon, X } from "lucide-react"
import { Skeleton } from "@/components/radix/ui/skeleton"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { proseStyle, relativeProseStyle } from "../../frontend/proseStyle"
import { createContextMapper } from "@/dictionaries/helpers"
import { Hn } from "@/components/ui/Hn"
import { useDictionary } from "@/dictionaries/hooks"
import { SrOnly } from "@/components/radix/ui/sr-only"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { tabs } = item.props

  return <article className='p-4 @lg/card:p-8 bg-white font-museo'></article>
}

/**
 * types
 */
type TabType = FormPayload<ItemType>["translations"][number]["props"]["tabs"][string]
