import { Button } from "./radix/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./radix/ui/card"
import { useDictionary } from "@/dictionaries/hooks"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/layout.json
 */
const dictionary = createContextMapper("components", "layout", "boundary-error")

/**
 * PageError
 */
type PageErrorProps = {
  title?: string
  secondary?: string
  message?: string
  displayRedirection?: boolean
}
export const PageError: React.FC<PageErrorProps> = ({ title, secondary, message, displayRedirection = true }) => {
  const { _ } = useDictionary(dictionary())
  const refresh = () => location.reload()
  return (
    <div className='flex justify-center items-center w-full min-h-screen p-8 bg-secondary' role='status'>
      <Card className={cx("w-full max-w-sm", G.isNullable(title) && G.isNullable(secondary) && "pt-6")}>
        {(G.isNotNullable(title) || G.isNotNullable(secondary)) && (
          <CardHeader>
            {G.isNotNullable(title) && <CardTitle>{title}</CardTitle>}
            {G.isNotNullable(secondary) && <CardDescription>{secondary}</CardDescription>}
          </CardHeader>
        )}
        {G.isNotNullable(message) && (
          <CardContent className='flex flex-col gap-6'>
            <p className='text-sm font-light'>{message}</p>
            {displayRedirection && (
              <div className='flex gap-2'>
                <Button onClick={refresh}>{_("button-refresh")}</Button>
                <Button onClick={() => navigate("/")} variant={"secondary"}>
                  {_("button-home")}
                </Button>
              </div>
            )}
          </CardContent>
        )}
      </Card>
    </div>
  )
}
