import { Image, ImageProps, ImageRef } from "@/components/radix/ui/image"
import { useDictionary } from "@/dictionaries/hooks"
import Menu from "./Menu"
import { Button } from "@/components/radix/ui/button"
import { MoreHorizontal, MoreVertical } from "lucide-react"
import Tooltip, {
  Tooltip as TooltipRoot,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@/components/radix/ui/tooltip"
import { SrOnly } from "@/components/radix/ui/sr-only"

/**
 * RowRoot
 */
type RowRootProps = {
  size?: "default" | "sm" | "xs"
  selected?: boolean
  disabled?: boolean
} & React.ComponentProps<"div">
const RowRoot: React.FC<RowRootProps> = ({
  size = "default",
  selected = false,
  disabled = false,
  className,
  children,
  ...props
}) => {
  //
  return (
    <div
      className={cx(
        "relative group/item flex items-center justify-stretch flex-wrap @lg/collection:flex-nowrap gap-4 border",
        isTouchDevice() && "select-none touch-callout-none",
        "transition-all duration-300 ease-in-out",
        size === "default"
          ? "py-4 px-16  @lg/collection:px-4 min-h-[4rem]"
          : "py-4 px-16  @lg/collection:px-4  @lg/collection:py-2 min-h-[2rem]",
        disabled && "opacity-50",
        selected ? "bg-primary/5 border-primary" : "bg-card border-card",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
RowRoot.displayName = "RowRoot"

/**
 * RowImage
 */
const RowImage = React.forwardRef<ImageRef, ImageProps>(({ className, ...props }, ref) => {
  const imageCx = cx("w-8 h-8 rounded-full object-cover", className)
  const wrapperCx = "absolute top-4 left-4 @lg/collection:static"
  return <Image ref={ref} className={imageCx} wrapperCx={wrapperCx} {...props} />
})
RowImage.displayName = "RowImage"

/**
 * RowIcon
 */
type RowIconProps = {
  tooltip?: string
  side?: "top" | "right" | "bottom" | "left"
} & React.ComponentPropsWithoutRef<"span">
const RowIcon = React.forwardRef<HTMLSpanElement, RowIconProps>(
  ({ className, tooltip, side = "left", ...props }, ref) => {
    const icon = (
      <span
        className={cx(
          "flex justify-center items-center w-6 h-6 bg-muted rounded-md [&>svg]:w-3 [&>svg]:h-3",
          className
        )}
        ref={ref}
        {...props}
      />
    )
    if (G.isNotNullable(tooltip))
      return (
        <Tooltip side={side} content={tooltip}>
          {icon}
        </Tooltip>
      )
    return icon
  }
)
RowIcon.displayName = "RowIcon"

/**
 * RowHeader
 */
const RowHeader: React.FC<React.ComponentPropsWithoutRef<"div">> = ({ className, ...props }) => {
  return <div className={cx("flex flex-col grow space-y-1 w-full @lg/collection:w-auto", className)} {...props} />
}

/**
 * RowTitle
 */
const RowTitle: React.FC<React.ComponentPropsWithoutRef<"h3">> = ({ className, ...props }) => {
  return <h3 className={cx("flex text-base font-medium leading-none tracking-tight", className)} {...props} />
}
const RowTrucateTitle: React.FC<React.ComponentPropsWithoutRef<"h3"> & { children: string }> = ({
  className,
  children,
  ...props
}) => {
  const ref = React.useRef<HTMLHeadingElement>(null)
  const virtualRef = React.useRef<HTMLSpanElement>(null)
  const [isTruncated, setIsTruncated] = React.useState(false)
  React.useEffect(() => {
    const checkTruncation = () => {
      const element = ref.current
      const virtual = virtualRef.current
      if (element && virtual) {
        setIsTruncated(element.offsetWidth < virtual.offsetWidth)
      }
    }
    checkTruncation()
    window.addEventListener("resize", checkTruncation)
    return () => window.removeEventListener("resize", checkTruncation)
  }, [children])
  return (
    <h3 className={cx("grid text-base font-medium leading-none tracking-tight", className)} ref={ref} {...props}>
      <span className='flex gap-2 overflow-hidden'>
        <span className='truncate text-clip'>{children}</span>
        {isTruncated && (
          <Tooltip content={children} side='right' align='center' className='max-w-xs'>
            <Button variant='ghost' icon size='xxs'>
              <MoreHorizontal size={12} className='mt-1.5 inline-block' />
            </Button>
          </Tooltip>
        )}
      </span>
      <div className='relative w-0 h-0 overflow-hidden' aria-hidden>
        <div className='text-base font-medium leading-none tracking-tight text-nowrap'>
          <span ref={virtualRef}>{children}</span>
        </div>
      </div>
    </h3>
  )
}

/**
 * RowDescription
 */
const RowDescription: React.FC<React.ComponentPropsWithoutRef<"p">> = ({ className, ...props }) => {
  return <p className={cx("text-xs font-light text-muted-foreground [&_svg]:w-3 [&_svg]:h-3", className)} {...props} />
}

/**
 * RowDescription
 */
const RowContent: React.FC<React.ComponentPropsWithoutRef<"p">> = ({ className, ...props }) => {
  return <div className={cx("flex items-center text-sm font-light", className)} {...props} />
}

/**
 * RowMenu
 */
type RowMenuProps = React.ComponentPropsWithoutRef<"button"> & {
  menu: React.ReactNode
}
const RowMenu = React.forwardRef<HTMLButtonElement, RowMenuProps>(({ className, menu, ...props }, ref) => {
  const { _ } = useDictionary("components.layout.menu")
  return (
    <Menu menu={menu} type='dropdown-menu' align='start' side='left'>
      <Button
        variant='ghost'
        size='xs'
        icon
        className={cx("absolute top-4 right-4 @lg/collection:static rounded-full", className)}
        ref={ref}
        {...props}
      >
        <MoreVertical aria-hidden />
        <SrOnly>{_("button")}</SrOnly>
      </Button>
    </Menu>
  )
})
RowMenu.displayName = "RowMenu"

/**
 * Row
 */
export default Object.assign(RowRoot, {
  Image: RowImage,
  Icon: RowIcon,
  Header: RowHeader,
  Title: RowTitle,
  TrucateTitle: RowTrucateTitle,
  Description: RowDescription,
  Content: RowContent,
  Menu: RowMenu,
})

/**
 * helpers
 */
const isTouchDevice = (): boolean => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0
}
