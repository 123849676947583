import { Menu } from "@/components/layout/dashboard/Collection"
import { Badge } from "@/components/radix/ui/badge"
import { Button } from "@/components/radix/ui/button"
import { Image } from "@/components/radix/ui/image"
import { SrOnly } from "@/components/radix/ui/sr-only"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { ImageIcon, MoreHorizontal, Target } from "lucide-react"
import { SeoContextMenu, useCmsContext } from "../Context"
import Card from "./card"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * SeoItem
 */
type SeoItemProps = {
  action?: React.ReactNode
  menu?: React.ReactNode
  children?: React.ReactNode
}
const SeoItem: React.FC<SeoItemProps> = ({ action, menu, children }) => {
  const { _ } = useDictionary(dictionary("seo"))
  const { seo } = useCmsContext()

  const t = useTranslation()
  const { title, description, keywords } = t(seo)
  const image = useMediasFile(t(seo).image)

  return (
    <Card>
      <Menu menu={<SeoContextMenu menu={menu} />} type="context-menu" align="start" side="left">
        <Card.Header>
          <Card.Header.Title className="flex items-center gap-2 text-base font-normal leading-none tracking-tight">
            <Target size={16} aria-hidden />
            {_("title")}
          </Card.Header.Title>
          <Card.Header.Aside>
            {action}
            <Menu menu={<SeoContextMenu menu={menu} />} type="dropdown-menu" align="start" side="left">
              <Button variant="ghost" size="xxs" icon>
                <MoreHorizontal aria-hidden />
                <SrOnly>{_("more")}</SrOnly>
              </Button>
            </Menu>
          </Card.Header.Aside>
        </Card.Header>
        <Card.Content className="flex flex-col-reverse @3xl/card:grid grid-cols-2 gap-8 px-4 @3xl/card:px-8 pb-4 @3xl/card:pb-8">
          <div className="flex flex-col gap-2">
            <h3 className="pt-2 text-lg">{S.isEmpty(S.trim(title ?? "")) ? _("no-title") : title}</h3>
            <p className="text-sm">{S.isEmpty(S.trim(description ?? "")) ? _("no-description") : description}</p>
            <ul className="flex flex-wrap pt-2 gap-3">
              {A.map(keywords, (keyword) => (
                <li key={keyword}>
                  <Badge>{keyword}</Badge>
                </li>
              ))}
            </ul>
            {children}
          </div>
          <Image
            src={image?.previewUrl}
            alt={image ? t(image).alt : undefined}
            wrapperCx="flex justify-center items-center w-full aspect-video rounded-md overflow-hidden text-muted-foreground/10 border border-input"
            className="w-full h-full object-cover"
          >
            <ImageIcon size={64} strokeWidth={1} />
          </Image>
        </Card.Content>
      </Menu>
    </Card>
  )
}
export default SeoItem
