import usePersistedState from "@/app/hooks/usePersistedState"
import useResponsive from "@/app/hooks/useResponsive"

/**
 * SidebarContext
 */
export const SidebarContext = React.createContext<SidebarContextType>({} as SidebarContextType)

/**
 * SidebarProvider
 */
type SidebarProviderProps = {
  children: React.ReactNode
}
export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const media = useResponsive()
  const [isMobile, isDesktop] = React.useMemo(() => {
    const isMobile = media.max("md")
    return [isMobile, !isMobile]
  }, [media.max("md")])
  const [sidebar, setSidebar] = usePersistedState(false, "sidebar", sessionStorage)
  const [mobileSidebar, setMobileSidebar] = React.useState(false)
  const contextMobile = {
    open: () => setMobileSidebar(true),
    close: () => setMobileSidebar(false),
    toggle: () => setMobileSidebar(!mobileSidebar),
    state: mobileSidebar,
  }
  const contextDesktop = {
    open: () => setSidebar(true),
    close: () => setSidebar(false),
    toggle: () => setSidebar(!sidebar),
    state: sidebar,
  }

  React.useEffect(() => {
    media.max("md") && setMobileSidebar(false)
  }, [media.max("md"), setMobileSidebar])
  return (
    <SidebarContext.Provider
      value={{
        isMobile,
        isDesktop,
        ...(isMobile ? contextMobile : contextDesktop),
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

/**
 * types
 */
export type SidebarContextType = {
  isMobile: boolean
  state: boolean
  isDesktop: boolean
  open: () => void
  close: () => void
  toggle: () => void
}
