import { useDictionary } from "@/dictionaries/hooks"
import { type ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"
import { createContextMapper } from "@/dictionaries/helpers"
import { Skeleton } from "@/components/radix/ui/skeleton"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemThumbnail
 */
export const ItemThumbnail: ItemMappingExport<ItemType>["ItemThumbnail"] = () => {
  const { _ } = useDictionary(dictionary())
  return (
    <>
      <p className='text-xs font-bold'>{_("title")}</p>
    </>
  )
}
