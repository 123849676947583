import { SelectProvider, useSelect } from "@/app/hooks/useSelect"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { useDictionary } from "@/dictionaries/hooks"
import { useRefreshArticles } from "@/store/articles/hooks"
import Collection from "./Collection"
import { Context, ContextProvider } from "./Context"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles")

/**
 * Page: ArticlesIndex
 */
const PagesIndex: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  useRefreshArticles()

  return (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[[_("breadcrumbs"), "/dashboard/articles"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <PagesIndex />
  </ContextProvider>
)
