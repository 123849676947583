import { useTranslation } from "@/store/languages/hooks"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"
import { useMediasFile } from "@/store/medias/hooks"
import { Image } from "@/components/radix/ui/image"
import { ArrowRight, ImageIcon, X } from "lucide-react"
import { Skeleton } from "@/components/radix/ui/skeleton"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { proseStyle, relativeProseStyle } from "../../frontend/proseStyle"
import { createContextMapper } from "@/dictionaries/helpers"
import { Hn } from "@/components/ui/Hn"
import { useDictionary } from "@/dictionaries/hooks"
import { SrOnly } from "@/components/radix/ui/sr-only"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary, description, cards } = t(item).props

  return (
    <article className='p-4 @lg/card:p-8 bg-be-cultured font-museo'>
      <div className='w-full max-w-[1000px] mx-auto'>
        <p className='pb-2.5 @xl/card:pb-5 text-xs @xl/card:text-base leading-normal font-semibold text-be-manatee uppercase'>
          {secondary}
          <Skeleton className='max-w-[300px]' value={secondary} />
        </p>
        <Hn
          className='w-full max-w-sm uppercase text-[25px] @xl/card:text-[40px] leading-tight font-bold text-be-midnight'
          level={titleLevel}
        >
          {title}
          <Skeleton value={title} />
        </Hn>
        {S.isNotEmpty(S.trim(description)) && (
          <div
            className={cx(relativeProseStyle, "w-full max-w-lg text-sm @xl/card:text-base text-be-midgray")}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
      <AccordionPrimitive.Root type='multiple' asChild>
        <ul className='flex flex-col  w-full max-w-[1280px] mx-auto pt-[20px] gap-[20px]'>
          {A.map(item.props.cards, (card) => (
            <Card card={D.get(cards, card) ?? emptyCard} id={card} titleLevel={titleLevel} key={card} />
          ))}
          {A.isEmpty(item.props.cards) &&
            A.map(A.range(0, 3), (index) => <Card card={emptyCard} id='1' titleLevel={titleLevel} key={index} />)}
        </ul>
      </AccordionPrimitive.Root>
    </article>
  )
}

/**
 * Card
 */
const Card: React.FC<{ card: CardT; id: string; titleLevel: number }> = ({ card, id, titleLevel }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const image = useMediasFile(card.image)
  const { title, secondary, content } = card
  return (
    <AccordionPrimitive.Item value={id} asChild>
      <li className='flex flex-col rounded-[5px] shadow-be-card text-white overflow-hidden'>
        <AccordionPrimitive.AccordionHeader className='relative flex items-center px-6 @lg/card:px-[70px] py-6 @lg/card:py-[50px] gap-5 @lg/card:gap-[30px]'>
          {G.isNotNullable(image) && (
            <Image
              src={image.url}
              alt={t(image).alt}
              className='w-full h-full object-cover'
              wrapperCx='absolute inset-0 w-full h-full'
            >
              <ImageIcon size={16} />
            </Image>
          )}
          <div className='absolute inset-0 w-full h-full bg-be-midnight/50' aria-hidden></div>
          <div className='relative grow flex flex-col items-start gap-[20px] @lg/card:gap-[30px]'>
            <Hn
              className='text-white text-[25px] @lg/card:text-[35px] font-semibold leading-tight'
              level={titleLevel + 1}
            >
              {title}
              <Skeleton className='max-w-sm' value={title} />
            </Hn>
            {S.isNotEmpty(S.trim(secondary)) && (
              <p className='inline-flex items-center px-5 py-2.5 rounded-full bg-white/20 backdrop-blur-md text-sm @lg/card:text-base leading-tight'>
                {secondary}
              </p>
            )}
          </div>
          <div className='absolute top-2 right-2 @lg/card:static'>
            <AccordionPrimitive.Trigger
              className={cx(
                "inline-flex justify-center items-center size-8 @lg/card:size-[50px]",
                "rounded-full bg-white/20 backdrop-blur-md",
                "[&[data-state=closed]_svg:last-child]:opacity-0 [&[data-state=closed]_svg:last-child]:rotate-45",
                "[&[data-state=open]_svg:first-child]:opacity-0 [&[data-state=open]_svg:first-child]:rotate-45"
              )}
            >
              <SrOnly>{_("toggle")}</SrOnly>
              <span
                className='relative size-3 @lg/card:size-4 [&>svg]:absolute [&>svg]:inset-0 [&>svg]:size-full [&>svg]:transition-all [&>svg]:duration-300'
                aria-hidden
              >
                <ArrowRight className='opacity-100 rotate-0' />
                <X className='opacity-100 rotate-90' />
              </span>
            </AccordionPrimitive.Trigger>
          </div>
        </AccordionPrimitive.AccordionHeader>
        <AccordionPrimitive.Content className='relative px-4 @lg/card:px-[70px] py-4 @lg/card:py-[30px] transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down'>
          <div
            className={cx(relativeProseStyle, "max-w-lg text-sm @lg/card:text-base")}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </AccordionPrimitive.Content>
      </li>
    </AccordionPrimitive.Item>
  )
}

const emptyCard = {
  image: "",
  title: "",
  secondary: "",
  content: "",
}

/**
 * types
 */
type CardT = FormPayload<ItemType>["translations"][number]["props"]["cards"][string]
