import { normString } from "@/app/fns/search"
import { useLimitable } from "@/app/hooks/useLimitable"
import { usePromise } from "@/app/hooks/usePromise"
import { useMatchable } from "@/app/hooks/useSearchable"
import { useSortable } from "@/app/hooks/useSortable"
import { useDateFnsLocale } from "@/dictionaries/hooks"
import { format } from "date-fns"
import { useArticlesStore } from "."
import { useTranslation } from "../languages/hooks"
import { getArticleCategories, getArticles } from "./actions"
import { Article, ArticleCategory } from "./localizers"

export const useRefreshArticleCategories = () => {
  return usePromise(getArticleCategories)
}
export const useRefreshArticles = () => {
  return usePromise(getArticles)
}
export const useArticlesById = () => {
  const byIds = useArticlesStore(D.prop("articles"))
  return byIds
}
export const useArticleCategoriesById = () => {
  const byIds = useArticlesStore(D.prop("categories"))
  return byIds
}
export const useArticles = () => {
  const byIds = useArticlesById()
  const articles = React.useMemo(() => D.values(byIds), [byIds])
  return articles
}
export const useArticleCategories = () => {
  const byIds = useArticleCategoriesById()
  const categories = React.useMemo(() => D.values(byIds), [byIds])
  return categories
}
export const useArticle = (id: Option<string>) => {
  const article = useArticlesStore(flow(D.prop("articles"), D.get(id ?? "")))
  return article
}
export const useArticleCategory = (id: Option<string>) => {
  const page = useArticlesStore(flow(D.prop("categories"), D.get(id ?? "")))
  return page
}
export const useFilteredArticles = (initialLimit = 24, initialByMore = 12) => {
  const articles = useArticles()
  const categoriesById = useArticleCategoriesById()
  const locale = useDateFnsLocale()
  const t = useTranslation()

  const [matchable, matchIn] = useMatchable<Article>([
    ({ seo }) => t(seo).title,
    ({ seo }) => t(seo).description,
    ({ category }) =>
      G.isNotNullable(D.get(categoriesById, category ?? "")) ? t(D.getUnsafe(categoriesById, category ?? "")).name : "",
    ({ createdAt }) => normString(format(createdAt, "Pp", { locale })),
    ({ createdAt }) => normString(format(createdAt, "PPPP", { locale })),
  ])

  const [sortable, sortBy] = useSortable<Article>(
    "articles-sort",
    {
      title: ({ seo }) => t(seo).title,
      category: ({ category }) =>
        G.isNotNullable(D.get(categoriesById, category ?? ""))
          ? t(D.getUnsafe(categoriesById, category ?? "")).name
          : "",
      createdAt: ({ createdAt }) => createdAt,
      updatedAt: ({ updatedAt }) => updatedAt,
    },
    "title"
  )
  const filtered = React.useMemo(() => pipe(articles, matchIn, sortBy), [articles, matchIn, sortBy])
  const [limitable, limit] = useLimitable<Article>(filtered.length, initialLimit, initialByMore)

  return { articles, filtered, matchable, sortable, sortBy, limitable, limit }
}
export const useFilteredArticleCategories = () => {
  const categories = useArticleCategories()
  const t = useTranslation()

  const [matchable, matchIn] = useMatchable<ArticleCategory>([(category) => t(category).name])

  const [sortable, sortBy] = useSortable<ArticleCategory>(
    "article-categories-sort",
    {
      name: (category) => t(category).name,
    },
    "name"
  )
  const filtered = React.useMemo(() => pipe(categories, matchIn, sortBy), [categories, matchIn, sortBy])

  return { categories, filtered, matchable, sortable, sortBy }
}
