import {
  Form,
  FormAssertive,
  FormExtraFields,
  FormHeader,
  FormImage,
  FormInput,
  FormSection,
  FormSubmit,
  formatDateToFormInput,
  useForm,
} from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { updateProfile } from "@/store/users/actions"
import { User } from "@/store/users/localizers"

/**
 * dictionary src/dictionaries/en/components/dialogs/user-profile-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "user-profile-dialog")

/**
 * UserProfileDialog
 *
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: User | false
}
export const UserProfileDialog: React.FC<Props> = ({ item, open, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-4xl'
    >
      {item !== false && <DialogForm close={() => onOpenChange(false)} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<{ item: User; close: () => void }> = ({ item: user, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: translate } = useDictionary<string, false>(dictionary())

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      ...D.selectKeys(user.profile, ["firstname", "lastname", "position", "company", "phones", "emails", "extras"]),
      image: {
        url: user.profile.image,
        file: null,
        delete: false,
      },
      dob: formatDateToFormInput(user.profile.dob),
      ...user.profile.address,
    },
    translate,
    onSubmit: async ({ values }) => {
      if (!form.isValid) return "VALIDATION_FAILURE"
      const dob = values.dob === "" ? null : values.dob
      const address = D.selectKeys(values, ["address", "city", "country", "state", "zip"])
      const image = D.selectKeys(values.image, ["file", "delete"])
      const data = D.selectKeys(values, ["firstname", "lastname", "position", "company", "phones", "emails", "extras"])
      const response = await updateProfile(user.id, { ...data, address, dob, image })
      if (!response.error) {
        toast.success(_("success"))
        close()
      } else return response.code
    },
  })

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-personal-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-personal-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className='@xl/dialog:grid grid-cols-3'>
        <FormSection className='col-span-2'>
          <FormInput
            label={_("firstname-label")}
            name='firstname'
            placeholder={_("firstname-placeholder")}
            auto='given-name'
          />
          <FormInput
            label={_("lastname-label")}
            name='lastname'
            placeholder={_("lastname-placeholder")}
            auto='family-name'
          />
          <FormInput label={_("dob-label")} name='dob' type='date' auto='bday-day' />
        </FormSection>
        <FormImage label={_("image-label")} name='image' ratio='aspect-square' />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-employment-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-employment-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className='@xl/dialog:grid grid-cols-2'>
        <FormInput
          label={_("company-label")}
          name='company'
          placeholder={_("company-placeholder")}
          auto='organization'
        />
        <FormInput
          label={_("position-label")}
          name='position'
          placeholder={_("position-placeholder")}
          auto='organization-title'
        />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-contact-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-contact-description")}</FormHeader.Description>
      </FormHeader>
      <FormExtraFields
        label={_("phones-label")}
        name='phones'
        translate={(t) => translate(`phones-${t}`)}
        type='tel'
        auto={{ name: "new-phone", value: "tel" }}
      />
      <FormExtraFields
        label={_("emails-label")}
        name='emails'
        translate={(t) => translate(`emails-${t}`)}
        type='email'
        auto={{ name: "new-email", value: "email" }}
      />

      <FormHeader>
        <FormHeader.Title>{_("section-address-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-address-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className='@xl/dialog:grid grid-cols-3'>
        <FormInput
          className='col-span-2'
          label={_("address-label")}
          name='address'
          auto='street-address'
          placeholder={_("address-placeholder")}
        />
        <FormInput label={_("city-label")} name='city' placeholder={_("city-placeholder")} />
        <FormInput label={_("state-label")} name='state' placeholder={_("state-placeholder")} />
        <FormInput label={_("zip-label")} name='zip' placeholder={_("zip-placeholder")} auto='postal-code' />
        <FormInput label={_("country-label")} name='country' placeholder={_("country-placeholder")} auto='country' />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-additional-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-additional-description")}</FormHeader.Description>
      </FormHeader>
      <FormExtraFields
        label={_("extras-label")}
        name='extras'
        translate={(t) => translate(`extras-${t}`)}
        auto={{ value: false }}
      />

      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
