import ContextMenu from "@/components/radix/ui//context-menu"
import DropdownMenu from "@/components/radix/ui//dropdown-menu"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"

/**
 * MenuContext
 */
type MenuContextType = {
  type: "context-menu" | "dropdown-menu"
}
const MenuContext = React.createContext<MenuContextType>({ type: "context-menu" })

/**
 * useMenuContext
 */
export const useMenuContext = () => {
  const context = React.useContext(MenuContext)
  return context
}

/**
 * MenuRoot
 */
type MenuRootProps = {
  type: MenuContextType["type"]
  menu: React.ReactNode
  disabled?: boolean
  asChild?: boolean
  children: React.ReactNode
  align?: DropdownMenuPrimitive.MenuContentProps["align"]
  side?: DropdownMenuPrimitive.MenuContentProps["side"]
} & React.ComponentPropsWithoutRef<"div">
const MenuRoot: React.FC<MenuRootProps> = ({
  type,
  menu,
  align,
  asChild = false,
  side,
  disabled = false,
  children,
  ...props
}) => {
  const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0
  if (disabled) return children
  if (type === "context-menu" && isTouchDevice) return children
  return (
    <MenuContext.Provider value={{ type }}>
      {type === "context-menu" ? (
        <ContextMenu>
          <ContextMenu.Trigger asChild={asChild}>{children}</ContextMenu.Trigger>
          <ContextMenu.Content {...props}>{menu}</ContextMenu.Content>
        </ContextMenu>
      ) : (
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
          <DropdownMenu.Content align={align} side={side} {...props}>
            {menu}
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </MenuContext.Provider>
  )
}

/**
 * MenuItem
 */
type MenuItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
export const MenuItem: React.FC<MenuItemProps> = ({ ...props }) => {
  const { type } = useMenuContext()
  return type === "context-menu" ? <ContextMenu.Item {...props} /> : <DropdownMenu.Item {...props} />
}

/**
 * MenuItem
 */
type MenuSeparatorProps = React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
export const MenuSeparator: React.FC<MenuSeparatorProps> = ({ ...props }) => {
  const { type } = useMenuContext()
  return type === "context-menu" ? <ContextMenu.Separator {...props} /> : <DropdownMenu.Separator {...props} />
}

/**
 * Menu
 */
export default Object.assign(MenuRoot, { Item: MenuItem, Separator: MenuSeparator })
