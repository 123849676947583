import { makeBreakable } from "@/app/fns/String"
import { FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "@/components/form"
import { Menu, Row } from "@/components/layout/dashboard/Collection"
import { FilesType, SelectFiles } from "@/components/medias/components/selectFiles"
import { FileInfoDialog } from "@/components/medias/dialogs/FileInfoDialog"
import { buttonFormField } from "@/components/radix/ui/button"
import { SrOnly } from "@/components/radix/ui/sr-only"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core"
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import delay from "delay"
import { FileDown, FileMinus, FilePlus, Info } from "lucide-react"
import { FileIcon } from "../components/files"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * FormMediasFiles
 */
type FormMediasFilesProps = { contextKey?: string; type?: FilesType; max?: number }
export const FormMediasFiles: React.FC<FormMediasFilesProps & FormFieldWrapperProps> = ({
  contextKey,
  type,
  max,
  ...wrapperProps
}) => (
  <FormFieldWrapper {...wrapperProps}>
    <FormMediasFilesInput {...{ contextKey, type, max }} />
  </FormFieldWrapper>
)

/**
 * FormMediasFilesInput
 * dictionary src/dictionaries/en/components/medias.json
 */
const FormMediasFilesInput: React.FC<FormMediasFilesProps> = ({ contextKey, type = "*", max = Infinity }) => {
  const { _ } = useDictionary(dictionary())
  const { setFieldValue, value, id } = useFieldContext<string[]>()

  // add files
  const [open, onOpen] = React.useState(false)
  const onSelect = (files: string[]) => {
    // check if file is already in the list and take only the max number of files
    setFieldValue(A.take([...value, ...A.difference(files, value)], max))
  }

  // remove an file
  const onRemove = (fileId: string) => {
    setFieldValue(A.reject(value, (id) => id === fileId))
    delay(10).then(() => focusRef.current?.focus())
  }

  // replace a file by another
  const [replaceFileOpen, setReplaceFileOpen] = React.useState(false)
  const [replaceId, setReplaceId] = React.useState<string | null>(null)
  const onChange = (fileId: string) => {
    setReplaceId(fileId)
    setReplaceFileOpen(true)
  }
  const replaceBy = (files: string[]) => {
    setReplaceFileOpen(false)
    const originalId = replaceId
    setReplaceId(null)
    const fileId = A.head(files)
    if (G.isNullable(fileId)) return
    setFieldValue(A.map(value, (id) => (id === originalId ? fileId : id)))
    setFocusId(fileId)
  }

  // drag and drop reordering
  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (active.id !== over?.id) {
        const oldIndex = value.indexOf(active.id as string)
        const newIndex = value.indexOf(over!.id as string)
        setFieldValue(arrayMove(value, oldIndex, newIndex))
      }
    },
    [value]
  )
  // keyboard accessibility reordering
  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => {
      const keyCode = e.key
      if (!A.includes(["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"], keyCode)) return
      e.preventDefault()
      const index = value.indexOf(id)
      switch (keyCode) {
        case "ArrowUp": {
          const newIndex = index - 1
          if (newIndex < 0) return
          setFieldValue(arrayMove(value, index, newIndex))
          break
        }
        case "ArrowDown": {
          const newIndex = index + 1
          if (newIndex >= value.length) return
          setFieldValue(arrayMove(value, index, newIndex))
          break
        }
        case "ArrowLeft": {
          const newIndex = index - 1
          if (newIndex < 0) return
          setFieldValue(arrayMove(value, index, newIndex))
          break
        }
        case "ArrowRight": {
          const newIndex = index + 1
          if (newIndex >= value.length) return
          setFieldValue(arrayMove(value, index, newIndex))
          break
        }
      }
    },
    [value]
  )

  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const sensors = useSensors(mouseSensor, touchSensor)

  const focusRef = React.useRef<HTMLButtonElement>(null)
  const [focusId, setFocusId] = React.useState<string | null>(null)

  return (
    <div>
      <ul className='grid grid-cols-1 gap-2'>
        {max === 1 ? (
          A.map(value, (id) => (
            <FormMediasFilesFile key={id} {...{ id, onChange, onRemove, onKeyDown, max, focusId, setFocusId }} />
          ))
        ) : (
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
            <SortableContext items={value} strategy={rectSortingStrategy}>
              {A.map(value, (id) => (
                <FormMediasFilesFile key={id} {...{ id, onChange, onRemove, onKeyDown, max, focusId, setFocusId }} />
              ))}
            </SortableContext>
          </DndContext>
        )}
        {N.lt(A.length(value), max) && (
          <li>
            <button
              ref={focusRef}
              className={cx(buttonFormField, "h-16")}
              id={id}
              onClick={() => onOpen(true)}
              type='button'
            >
              <FilePlus size={32} strokeWidth={0.9} aria-hidden />
              <SrOnly>{_("add-file")}</SrOnly>
            </button>
          </li>
        )}
      </ul>
      <SelectFiles
        open={open}
        type={type}
        onOpenChange={onOpen}
        onCloseAutoFocus={() => (max === value.length ? setFocusId(A.last(value) ?? null) : focusRef.current?.focus())}
        onSelect={onSelect}
        contextKey={contextKey}
        hiddenFiles={value}
        multiple={max > 1}
      />
      <SelectFiles
        open={replaceFileOpen}
        type={type}
        onOpenChange={setReplaceFileOpen}
        onCloseAutoFocus={() => setFocusId(replaceId)}
        onSelect={replaceBy}
        contextKey={contextKey}
        hiddenFiles={value}
      />
    </div>
  )
}

/**
 * FormMediasFilesFile
 * dictionary src/dictionaries/en/components/medias.json
 */
type FormMediasFilesFileProps = {
  id: string
  max: number
  focusId: string | null
  setFocusId: (fileId: string | null) => void
  onChange: (fileId: string) => void
  onRemove: (fileId: string) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => void
}
const FormMediasFilesFile: React.FC<FormMediasFilesFileProps> = ({
  id,
  focusId,
  setFocusId,
  onChange,
  onRemove,
  onKeyDown,
  max,
}) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id })
  const style = { transform: CSS.Transform.toString(transform), transition }

  const file = useMediasFile(id)
  const [openInfo, onOpenInfoChange] = React.useState(false)

  const multiple = N.gt(max, 1)

  const focusRef = React.useRef<HTMLButtonElement>(null)
  React.useEffect(() => {
    if (focusId === id) {
      focusRef.current?.focus()
      setFocusId(null)
    }
  }, [focusId === id, setFocusId])
  if (G.isNullable(file)) {
    onRemove(id)
    return null
  }

  return (
    <li
      ref={setNodeRef}
      style={multiple ? style : undefined}
      className={cx(
        "relative group/item flex items-center justify-stretch flex-wrap min-h-[4rem] py-4 px-16 @lg/collection:px-4 gap-4 border transition-opacity",
        multiple && isDragging ? "opacity-75 z-20" : "opacity-100 z-10"
      )}
    >
      {multiple && (
        <button
          className='absolute inset-0 w-full h-full rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2'
          {...listeners}
          {...attributes}
          type='button'
          onKeyDown={(e) => onKeyDown(e, id)}
        >
          <SrOnly>{_("drag-file")}</SrOnly>
        </button>
      )}
      <Row.Image src={file.thumbnailUrl} alt={t(file).alt}>
        <FileIcon file={file} />
      </Row.Image>
      <Row.Header>
        <Row.Title className='text-sm'>{makeBreakable(t(file).name, 5)}</Row.Title>
      </Row.Header>
      <Row.Menu
        ref={focusRef}
        menu={
          <>
            <Menu.Item onClick={() => onOpenInfoChange(true)}>
              <Info aria-hidden />
              {_("file-info")}
            </Menu.Item>
            <Menu.Item onClick={() => onRemove(file.id)}>
              <FileMinus aria-hidden />
              {_("remove-file")}
            </Menu.Item>
            <Menu.Item onClick={() => onChange(file.id)}>
              <FileDown aria-hidden />
              {_("change-file")}
            </Menu.Item>
          </>
        }
      />
      <FileInfoDialog
        {...{ file, openInfo, setOpenInfo: onOpenInfoChange }}
        onCloseAutoFocus={() => focusRef.current?.focus()}
      />
    </li>
  )
}
