import { Form, FormAssertive, FormFiles, FormInput, FormSubmit, FormTranslationTabs, useForm } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { ValidContextAndProps, useDictionary } from "@/dictionaries/hooks"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { updateMediasFile } from "@/store/medias/actions"
import { MediasFile } from "@/store/medias/localizers"
import { ValuesValidate } from "use-a11y-form"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias", "edit-file-dialog")
type FormDictionary = {
  "name-required": string
  VALIDATION_FAILURE: string
}
const formSafeDictionary = <T extends string>(context: ValidContextAndProps<T, FormDictionary>) => context

/**
 * EditFolderDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: MediasFile | false
}
export const EditFileDialog: React.FC<Props> = (props) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      open={props.open}
      onOpenChange={props.onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='max-w-xl'
    >
      {props.item !== false && <DialogForm {...props} item={props.item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<Props & { item: MediasFile }> = ({ item, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const languagesById = useLanguagesById()
  const initialValue = React.useMemo(
    () => ({
      files: [] as File[],
      translations: D.map(languagesById, (language) => ({
        languageId: language.id,
        name: translate(item, language)?.name ?? "",
        alt: translate(item, language)?.alt ?? "",
        caption: translate(item, language)?.caption ?? "",
      })),
    }),
    []
  )
  formSafeDictionary(dictionary())
  const form = useForm({
    allowSubmitAttempt: true,
    values: initialValue,
    translate: _ as (ctx: string) => string,
    validate: (values) => ({
      translations: D.map(values.translations, (translation) => ({
        name: !translation.name && "name-required",
      })) as ValuesValidate<typeof initialValue>["translations"],
    }),
    onSubmit: async ({ values: { files, translations } }, event) => {
      event?.stopPropagation()
      if (!form.isValid) return "VALIDATION_FAILURE"
      const data = {
        file: A.isNotEmpty(files) ? A.getUnsafe(files, 0) : undefined,
        translations: D.values(translations),
      }
      const { error, code } = await updateMediasFile(item.id, data)
      if (!error) {
        onOpenChange(false)
        toast.success(_("success"))
      } else if (code === "RESOURCE_NOT_FOUND") {
        onOpenChange(false)
        toast.error(_(code))
      } else if (code === "INVALID_API_TOKEN" || code === "INVALID_AUTH_SESSION" || code === "FETCH_ERROR")
        return window.location.reload()
      return code
    },
  })
  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormTranslationTabs>
        {(language) => (
          <div className='grid gap-6' key={language.id}>
            <FormInput label={_("name-label")} name='name' placeholder={_("name-placeholder")} />
            <FormInput label={_("alt-label")} name='alt' placeholder={_("alt-placeholder")} />
            <FormInput label={_("caption-label")} name='caption' placeholder={_("caption-placeholder")} />
          </div>
        )}
      </FormTranslationTabs>
      <FormFiles label={_("files-label")} name='files' multiple={false} />

      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>

        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
