import { Link as WouterLink, type LinkProps as WouterLinkProps } from "wouter"

/**
 * Link
 */
export type LinkProps = WouterLinkProps
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(({ className, children, ...props }, ref) => {
  return (
    <WouterLink {...props}>
      <a className={className} ref={ref}>
        {children}
      </a>
    </WouterLink>
  )
})
export const linkCx = "text-primary hover:underline transition-colors"
