import { Form, FormAssertive, FormFiles, FormSubmit, useForm, validator } from "@/components/form"
import { FormFileType, isFile } from "@/components/form/FormFiles"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { createMediasFile } from "@/store/medias/actions"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias", "upload-files-dialog")

/**
 * UploadFilesDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: string | null | false
}
export const UploadFilesDialog: React.FC<Props> = ({ item, open, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      {item !== false && <DialogForm close={() => onOpenChange(false)} folderId={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<{ folderId: string | null; close: () => void }> = ({ folderId, close }) => {
  const { _ } = useDictionary(dictionary())
  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      files: [] as FormFileType[],
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      files: [min(1, "files-required")],
    }),
    onSubmit: ({ values }, event) => {
      event?.stopPropagation()
      if (!form.isValid) return "VALIDATION_FAILURE"
      close()
      const files = values.files.filter(isFile)
      let counter = 1
      const total = files.length
      const toastId = toast.promise(
        Promise.all(
          A.map(files, async (file) => {
            const { error } = await createMediasFile({ folderId, file })
            if (error) toast.error(_("error"), { description: file.name })
            toast.loading(_("progress", { counter: counter++, total }), {
              id: toastId,
            })
          })
        ),
        {
          loading: _("progress", { counter, total }),
          success: _("success"),
        }
      )
    },
  })

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormFiles label={_("name-label")} name='files' />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
