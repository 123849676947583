import { useMediaContext } from "@/components/medias/Context"
import { Button } from "@/components/radix/ui/button"
import { useDictionary } from "@/dictionaries/hooks"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { BoxSelect, Folder, FolderTree, Search, Trash, Upload } from "lucide-react"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * CollectionEmpty
 */
type CollectionEmptyProps = {
  resultIsEmpty: boolean
  folderIsEmpty: boolean
  clearAllFilters: () => void
}
export const CollectionEmpty: React.FC<CollectionEmptyProps> = ({ resultIsEmpty, folderIsEmpty, clearAllFilters }) => {
  const { _ } = useDictionary(dictionary())
  const { createFolder, currentFolder, uploadFiles } = useMediaContext()

  return resultIsEmpty || folderIsEmpty ? (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="flex flex-col items-center justify-center gap-2">
        {folderIsEmpty ? (
          <Folder aria-hidden className="text-muted-foreground my-4" size={48} strokeWidth={1} />
        ) : (
          <Search aria-hidden className="text-muted-foreground my-4" size={48} strokeWidth={1} />
        )}
        <h2 className="text-2xl font-semibold">{_(folderIsEmpty ? "empty-folder" : "empty-result")}</h2>
        <p className="text-sm text-muted-foreground">
          {folderIsEmpty ? (
            <>
              {_("empty-folder-description-1")}
              <Button variant="link" size="link" onClick={() => uploadFiles(currentFolder)}>
                {_("empty-folder-upload-files")}
              </Button>
              {_("empty-folder-description-2")}
              <Button variant="link" size="link" onClick={() => createFolder(currentFolder)}>
                {_("empty-folder-create-folder")}
              </Button>
              {_("empty-folder-description-3")}
            </>
          ) : (
            <>
              {_("empty-result-description")}
              <Button variant="link" size="link" onClick={clearAllFilters}>
                {_("clear-all-filters")}
              </Button>
            </>
          )}
        </p>
      </div>
    </div>
  ) : null
}

/**
 * UploadFiles
 */
export const UploadFiles: React.FC = () => {
  const { _ } = useDictionary(dictionary("drop-zone"))
  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="flex flex-col items-center justify-center gap-2">
        <Upload aria-hidden className="text-muted-foreground my-4" size={48} strokeWidth={1} />
        <h2 className="text-2xl font-semibold">{_("title")}</h2>
        <p className="text-sm text-muted-foreground">{_("description")}</p>
      </div>
    </div>
  )
}
