import { FormGroup, FormGroupError, FormGroupInfo, FormLabel } from "./"

/**
 * FormMediaWrapper
 */
export type FormFieldWrapperProps = {
  label?: string
  labelAside?: React.ReactNode
  name?: string
  info?: string | false
}
export const FormFieldWrapper: React.FC<React.PropsWithChildren<FormFieldWrapperProps>> = ({
  label,
  labelAside,
  name,
  info,
  children,
}) => (
  <FormGroup name={name}>
    {G.isNullable(labelAside) && <FormLabel>{label}</FormLabel>}
    {G.isNotNullable(labelAside) && (
      <div className='flex justify-between'>
        <FormLabel>{label}</FormLabel>
        {labelAside}
      </div>
    )}
    {children}
    <FormGroupError />
    {info !== false && <FormGroupInfo>{info}</FormGroupInfo>}
  </FormGroup>
)
