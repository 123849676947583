import { X } from "lucide-react"
import { FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "."
import { Button } from "../radix/ui/button"

/**
 * BoundFormKeywords
 */
type Props = FormFieldKeywordsProps & FormFieldWrapperProps
export const FormKeywords: React.FC<Props> = ({ label, labelAside, name, info, ...props }) => (
  <FormFieldWrapper {...{ label, labelAside, name, info }}>
    <FormFieldKeywords {...props} />
  </FormFieldWrapper>
)

type FormFieldKeywordsProps = Omit<
  React.ComponentPropsWithRef<"input">,
  "name" | "id" | "onChange" | "value" | "disabled" | "children" | "type" | "onKeyDown"
>
const FormFieldKeywords: React.FC<FormFieldKeywordsProps> = ({ ...props }) => {
  const { value, setFieldValue, disabled, name, id } = useFieldContext<string[]>()
  const ref = React.useRef<HTMLInputElement>(null)
  const [state, setState] = React.useState("")

  const focusInput = () => {
    ref.current?.focus()
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value)
  }

  const onInputKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!["Enter", "Backspace"].includes(e.key)) return
      if (e.key === "Enter") {
        if (S.isNotEmpty(S.trim(state))) {
          setFieldValue(A.append(value, S.trim(state)))
          setState("")
        }
        e.preventDefault()
      }
      if (e.key === "Backspace" && S.isEmpty(state) && A.isNotEmpty(value)) {
        setFieldValue(A.removeAt(value, value.length - 1))
        e.preventDefault()
      }
    },
    [value, state]
  )

  const onButtonKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>, index: number) => {
      if (!["Enter", " "].includes(e.key)) return
      e.preventDefault()
      setFieldValue(A.removeAt(value, index))
      focusInput()
    },
    [value]
  )

  const onButtonClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
      e.stopPropagation()
      setFieldValue(A.removeAt(value, index))
      focusInput()
    },
    [value]
  )

  return (
    <div
      onClick={focusInput}
      className={cx(
        "flex flex-wrap w-full px-3 py-2 gap-x-3 gap-y-2 rounded-md",
        "box-border border border-input bg-background",
        "ring-offset-background focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"
      )}
    >
      {A.mapWithIndex(value, (index, text) => (
        <Button
          key={index}
          onClick={(e) => onButtonClick(e, index)}
          onKeyDown={(e) => onButtonKeyDown(e, index)}
          disabled={disabled}
          size="xs"
          className="h-auto py-2 leading-none"
        >
          {text}
          <X size={12} aria-hidden />
        </Button>
      ))}
      <input
        className={cx(
          "grow min-w-[33%] -mx-3 -my-2 px-3 h-[44px]",
          "bg-transparent",
          "text-sm font-normal placeholder:text-muted-foreground",
          "disabled:cursor-not-allowed disabled:opacity-50",
          "outline-none"
        )}
        ref={ref}
        name={name}
        id={id}
        type="text"
        value={state}
        disabled={disabled}
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        {...props}
      />
    </div>
  )
}
