import { Form, FormAssertive, FormInput, FormSection, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/radix/ui/card"
import { useDictionary } from "@/dictionaries/hooks"
import { signIn } from "@/store/auth/actions"
import { createContextMapper } from "@/dictionaries/helpers"
import globalConfig from "@/config/global"

/**
 * dictionary src/dictionaries/en/pages/home.json
 */
const dictionary = createContextMapper("pages", "index")

/**
 * Page: index
 */
const Index: React.FC = () => {
  const { _ } = useDictionary(dictionary())

  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    values: {
      email: globalConfig.login.email,
      password: globalConfig.login.password,
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      email: [min(1, "email-required"), isEmail("email-format")],
      password: [min(1, "password-required")],
    }),
    onSubmit: async ({ values }) => {
      const response = await signIn(values)
      if (!response.error) navigate("/dashboard")
      else return response.code
    },
  })

  return (
    <div className='flex justify-center items-center w-full min-h-screen p-8 bg-secondary'>
      <Card className='w-full max-w-xs'>
        <CardHeader>
          <CardTitle>{_("title")}</CardTitle>
          <CardDescription>{_("secondary")}</CardDescription>
        </CardHeader>
        <CardContent>
          <Form form={form} className='grid gap-4'>
            <FormAssertive />

            <FormSection>
              <FormInput label={_("email-label")} name='email' type='email' placeholder={_("email-placeholder")} />
              <FormInput
                label={_("password-label")}
                name='password'
                type='password'
                placeholder={_("password-placeholder")}
              />
            </FormSection>
            <FormSubmit variant='default'>{_("submit")}</FormSubmit>
            <Button variant='ghost' onClick={() => navigate("/forgot-password")}>
              {_("forgot-password")}
            </Button>
          </Form>
        </CardContent>
      </Card>
    </div>
  )
}
export default Index
