import { useDropZone } from "@/app/hooks/useDropZone"
import useUuid from "@/app/hooks/useUuid"
import { Breadcrumb, Breadcrumbs } from "@/components/layout/dashboard/Breadcrumbs"
import { Grid } from "@/components/layout/dashboard/Collection"
import Toolbar from "@/components/layout/dashboard/Toolbar"
import { Button } from "@/components/radix/ui/button"
import Popover from "@/components/radix/ui/popover"
import { SrOnly } from "@/components/radix/ui/sr-only"
import { Dialog, DialogFooter } from "@/components/ui/Dialog"
import globalConfig, { acceptedFileExtensions } from "@/config/global"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { createMediasFile } from "@/store/medias/actions"
import { useFilteredMedias, useFolderPath, useFreshFolder } from "@/store/medias/hooks"
import { MediasFolder } from "@/store/medias/localizers"
import { DialogClose } from "@radix-ui/react-dialog"
import { FolderPlus, HelpCircle, Upload } from "lucide-react"
import { ContextProvider, useMediaContext } from "../Context"
import { SelectProvider, useSelect } from "../useSelect"
import { CollectionEmpty, UploadFiles } from "./collection"
import { ItemFile } from "./files"
import { ItemFolder } from "./folders"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * SelectFiles
 */
type DialogProps = {
  open: boolean
  onOpenChange: (state: boolean) => void
  onCloseAutoFocus?: () => void
}
type SelectFilesProps = {
  multiple?: boolean
  hiddenFiles?: string[]
  type?: FilesType
  contextKey?: string
  current?: string | null
  onSelect: (ids: string[]) => void
}
export const SelectFiles: React.FC<DialogProps & SelectFilesProps> = ({
  open,
  onOpenChange,
  onCloseAutoFocus,
  onSelect,
  ...props
}) => {
  const contextKey = useUuid()
  const { _ } = useDictionary<string, false>(dictionary("select-files"))
  const type = props.type ?? "*"
  const multiple = props.multiple ?? false
  const titleSuffix = type === "*" ? (multiple ? "multiple" : "single") : `${type}${multiple ? "s" : ""}`

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_(`title-${titleSuffix}`)}
      className="sm:max-w-xl select-none relative"
      onCloseAutoFocus={onCloseAutoFocus}
    >
      <ContextProvider
        contextKey={props.contextKey ?? contextKey}
        accepted={typeToExtension(props.type ?? "*")}
        selectFile={multiple ? "multiple" : true}
        current={props.current}
      >
        <SelectFilesInner
          onSelect={(files) => {
            onOpenChange(false)
            onSelect(files)
          }}
          {...props}
        />
      </ContextProvider>
    </Dialog>
  )
}

/**
 * SelectFilesInner
 */
export const SelectFilesInner: React.FC<SelectFilesProps> = ({ hiddenFiles = [], onSelect, multiple }) => {
  const { _ } = useDictionary("components.medias")
  const { _: translate } = useDictionary("components.medias.upload-files-dialog")
  const select = useSelect()
  const { currentFolder, createFolder, setCurrentFolder, uploadFiles, accepted, selected, setSelected, view, setView } =
    useMediaContext()
  useFreshFolder(currentFolder)

  const { matchable, sortable, folders, files, filteredFolders, filteredFiles, clearAllFilters } = useFilteredMedias(
    currentFolder,
    hiddenFiles
  )

  // breadcrumbs
  const root: Breadcrumb = [_("breadcrumbs"), () => setCurrentFolder(null)]
  const breadcrumbs = pipe(
    useFolderPath(currentFolder),
    A.reverse,
    A.map<MediasFolder, Breadcrumb>(({ name, id }) => [name, () => setCurrentFolder(id)])
  )
  const folderIsEmpty = A.isEmpty(folders) && A.isEmpty(files)
  const resultIsEmpty = A.isEmpty(filteredFolders) && A.isEmpty(filteredFiles)
  const { dragOver, bindDropZone } = useDropZone({
    onDropFiles: (files) => {
      if (A.isEmpty(files)) return
      let counter = 1
      const total = files.length
      const toastId = toast.promise(
        Promise.all(
          A.map(files, async (file) => {
            const { error } = await createMediasFile({ folderId: currentFolder, file })
            if (error) toast.error(translate("error"), { description: file.name })
            toast.loading(translate("progress", { counter: counter++, total }), {
              id: toastId,
            })
          })
        ),
        {
          loading: translate("progress", { counter, total }),
          success: translate("success"),
        }
      )
    },
    accept: acceptedFileExtensions,
    max: globalConfig.maxUploadFile,
  })
  const clearSelection = () => setSelected({ folders: [], files: [] })
  return (
    <>
      <div className="flex flex-col pb-4">
        <div className="flex justify-between pb-2">
          <Breadcrumbs {...{ breadcrumbs, root }} size="xs" className="grow" />
          {accepted && (
            <Popover>
              <Popover.Trigger asChild>
                <Button variant="ghost" size="xs" icon>
                  <HelpCircle aria-hidden />
                  <SrOnly>{_("accept")}</SrOnly>
                </Button>
              </Popover.Trigger>
              <Popover.Content side="bottom" align="end">
                <p className="text-xs">
                  <span className="inline-block pb-1 font-bold">{_("accept-extensions")}</span>
                  <br />
                  {A.join(accepted, ", ")}
                </p>
              </Popover.Content>
            </Popover>
          )}
        </div>
        <Toolbar size="xs">
          <Toolbar.Search {...matchable} />
          <Toolbar.Button onClick={() => createFolder(currentFolder)}>
            <FolderPlus aria-hidden />
            {_("create-folder")}
          </Toolbar.Button>
          <Toolbar.Button onClick={() => uploadFiles(currentFolder)}>
            <Upload aria-hidden />
            {_("upload-files")}
          </Toolbar.Button>
          <Toolbar.Sort {...sortable} dictionary="components.medias.sort" size="xs" />
          <Toolbar.View {...{ view, setView }} />
        </Toolbar>
        <div {...bindDropZone} className="relative">
          {folderIsEmpty || resultIsEmpty ? (
            <div className="flex flex-col justify-center items-center aspect-video border shadow-inner rounded-md gap-4 p-2 text-center">
              <CollectionEmpty {...{ resultIsEmpty, folderIsEmpty, clearAllFilters }} />
            </div>
          ) : (
            <div
              className="overflow-hidden aspect-video border shadow-inner rounded-md"
              onClick={() => !multiple && clearSelection()}
            >
              <div
                className="h-full overflow-y-auto scrollbar scrollbar-w-1 scrollbar-thumb-muted scrollbar-track-background scrollbar-thumb-rounded-full"
                ref={select.ref}
              >
                {multiple && <SelectProvider {...select.props} />}
                <Grid
                  view={view}
                  className={cx(
                    view === "grid"
                      ? "@md/collection:grid-cols-2 gap-2 p-2"
                      : "divide-y-1 divide bg-blue-500 @md/collection:bg-red-500"
                  )}
                >
                  {A.map(filteredFolders, (folder) => (
                    <ItemFolder key={folder.id} folder={folder} size="xs" />
                  ))}
                  {A.filterMap(filteredFiles, (file) =>
                    accepted && !accepted.includes(file.extension) ? (
                      O.None
                    ) : (
                      <ItemFile
                        key={file.id}
                        file={file}
                        size="xs"
                        select={multiple ? undefined : (fileId) => onSelect([fileId])}
                        canToggleSelect={multiple}
                      />
                    )
                  )}
                </Grid>
              </div>
            </div>
          )}
          {dragOver && (
            <div className="absolute inset-0 flex flex-col justify-center items-center w-full h-full border border-dashed border-primary bg-primary/5 backdrop-blur-sm animate-in fade-in-0 rounded-md gap-4 p-2 text-center">
              <UploadFiles />
            </div>
          )}
        </div>
      </div>
      <DialogFooter className="sm:justify-start">
        <DialogClose asChild>
          <Button variant="secondary">{_("select-files.cancel")}</Button>
        </DialogClose>
        <Button onClick={() => onSelect(selected.files)} disabled={A.isEmpty(selected.files)}>
          {_("select-files.submit")}
        </Button>
      </DialogFooter>
    </>
  )
}

/**
 * helpers
 */
export const typeToExtension = (type: FilesType): string[] | undefined => {
  switch (type) {
    case "image":
      return ["jpg", "jpeg", "png", "gif", "webp", "svg"]
    case "video":
      return ["mp4", "webm", "ogg"]
    case "audio":
      return ["mp3", "wav", "ogg"]
    case "document":
      return ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"]
    case "pdf":
      return ["pdf"]
    case "archive":
      return ["zip", "rar", "tar", "gz", "7z"]
    default:
      return undefined
  }
}

/**
 * types
 */
export type FilesType = "image" | "video" | "audio" | "document" | "pdf" | "archive" | "*"
