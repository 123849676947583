/**
 * SrOnly
 */
export type HnProps = React.HTMLAttributes<HTMLHeadingElement> & {
  level?: number
}
export const Hn = React.forwardRef<HTMLHeadingElement, HnProps>(({ level = 1, ...props }, ref) => {
  const As = `h${A.includes([1, 2, 3, 4, 5, 6], level) ? level : 1}` as React.ElementType
  return <As {...props} ref={ref} />
})
