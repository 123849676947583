import {
  acceptToInputAccept,
  checkExtFromFile,
  formatExtList,
  getSizeFromFile,
  useDropZone,
} from "@/app/hooks/useDropZone"
import globalConfig from "@/config/global"
import { useDictionary } from "@/dictionaries/hooks"
import { X } from "lucide-react"
import selectFiles from "select-files"
import { Form, FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "."
import { Button } from "../radix/ui/button"

/**
 * default
 */
const defaultAccept = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "svg", "webp"]
const defaultRatio = "aspect-video"
const defaultFit = "object-cover"

/**
 * FormImage
 */
type Props = FormInputImageProps & FormFieldWrapperProps
export const FormImage = React.forwardRef<HTMLInputElement, Props>(
  ({ label, labelAside, name, info, ...props }, ref) => (
    <FormFieldWrapper {...{ label, labelAside, name, info }}>
      <FormInputImage {...props} ref={ref} />
    </FormFieldWrapper>
  )
)

/**
 * FormInputImage
 * dictionary src/dictionaries/en/components/form/form-image.json
 */
type FormInputImageProps = React.ComponentProps<typeof Form.Input> & {
  ratio?: string
  fit?: string
  accept?: string[]
  min?: number
  max?: number
}
export const FormInputImage = React.forwardRef<HTMLDivElement, FormInputImageProps>(
  (
    {
      ratio = defaultRatio,
      fit = defaultFit,
      accept = defaultAccept,
      min = 0,
      max = globalConfig.maxUploadFile,
      className,
      ...props
    },
    ref
  ) => {
    const { _ } = useDictionary("components.form.form-image")
    const { setFieldValue, value, name } = useFieldContext<FormFileType>()

    /**
     * onDropFiles
     */
    const onDropFiles = (files: File[]) => {
      const file = A.head(files)
      if (G.isNullable(file)) return
      setFieldValue({ ...value, file })
    }

    /**
     * onClickDropZone
     */
    const onClickDropZone = async (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      const fileList = await selectFiles({ accept: acceptToInputAccept(accept) })
      const allFiles = fileList ? Array.from(fileList) : []
      const files = A.slice(allFiles, 0, 1)
      if (!A.some(files, (file) => min <= getSizeFromFile(file) && max >= getSizeFromFile(file)))
        return onError("TOOLARGE")
      if (!A.some(files, (file) => checkExtFromFile(file, accept))) return onError("UNACCEPTED")
      onDropFiles(files)
    }

    /**
     * onClickRemove
     */
    const onClickRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      if (G.isNotNullable(value.file)) return setFieldValue({ ...value, file: null, delete: false })
      setFieldValue({ ...value, file: null, delete: true })
    }

    /**
     * onError
     */
    const onError = (code: "UNACCEPTED" | "TOOLARGE") => {
      toast.error(_(code))
    }

    /**
     * useDropZone
     */
    const { bindDropZone, dragOver } = useDropZone({
      accept,
      min,
      max,
      multiple: false,
      onDropFiles,
      onError,
    })

    /**
     * memo
     */
    const acceptedExtensions = React.useMemo(() => formatExtList(accept), [accept])
    const imagePath = React.useMemo(() => {
      if (G.isNotNullable(value.file)) return URL.createObjectURL(value.file)
      if (value.delete) return null
      return value.url
    }, [value])

    return (
      <div
        className={cx(
          "relative flex justify-center items-center w-full rounded-md",
          ratio,
          "border border-input border-dashed focus-within:border-orient transition-colors",
          dragOver && "border-primary",
          className
        )}
        ref={ref}
        {...bindDropZone}
        {...props}
      >
        {G.isNotNullable(imagePath) ? (
          <div className='flex justify-center items-center w-full h-full'>
            <div className='absolute inset-0' aria-hidden>
              <img src={imagePath} className={cx("w-full h-full rounded-md", fit)} />
              <div className='absolute inset-0 bg-gradient-to-b from-orient/20 to-orient/80' />
            </div>
            <Button variant='secondary' size='xs' icon className='absolute top-3 right-3' onClick={onClickRemove}>
              <X size={16} aria-hidden aria-label={_("button-delete")} />
            </Button>
            <Button
              className='absolute bottom-3 right-3'
              id={name}
              variant='default'
              size='xs'
              onClick={onClickDropZone}
            >
              {_("button-change")}
            </Button>
          </div>
        ) : (
          <div className='flex flex-col gap-1 justify-center items-center p-3 text-muted-foreground font-light'>
            <p className='text-sm'>
              {_("placeholder-before")}{" "}
              <button
                id={name}
                type='button'
                className='text-primary font-normal underline rounded-md outline-none ring-offset-background focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1'
                onClick={onClickDropZone}
              >
                {_("placeholder-button")}
              </button>{" "}
              {_("placeholder-after")}
            </p>
            <p className='text-xs'>{_("accept", { extensions: A.join(acceptedExtensions, ", ") })}</p>
          </div>
        )}
      </div>
    )
  }
)

/**
 * types
 */
export type FormFileType = {
  file: File | null
  url: string | null
  delete: boolean
}
