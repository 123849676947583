import { Slider } from "@/components/radix/ui/slider"
import { useDictionary } from "@/dictionaries/hooks"
import { MediasTransform } from "@/store/medias/localizers"
import { Patch } from "evolve-ts"
import { Maximize2, MoveHorizontal, MoveVertical, RefreshCw } from "lucide-react"
import Cropper, { CropperProps } from "react-easy-crop"
import {
  FormGroup,
  FormGroupError,
  FormGroupInfo,
  FormLabel,
  inputIconVariants,
  inputVariants,
  useFieldContext,
} from "."
import { Button } from "../radix/ui/button"
import { SrOnly } from "../radix/ui/sr-only"

/**
 * FormCropper
 */
type Props = {
  label?: string
  name?: string
  info?: string | false
}
export const FormCropper = React.forwardRef<HTMLDivElement, Props>(({ label, name, info, ...props }, ref) => {
  return (
    <FormGroup name={name}>
      <FormLabel>{label}</FormLabel>
      <FormInputCropper {...props} ref={ref} />
      <FormGroupError />
      {info !== false && <FormGroupInfo>{info}</FormGroupInfo>}
    </FormGroup>
  )
})

/**
 * FormInputCropper
 */
export const FormInputCropper = React.forwardRef<HTMLDivElement, React.ComponentProps<"div">>(
  ({ className, ...props }, ref) => {
    const { setFieldValue, value } = useFieldContext<FormCropperType>()
    const setPartial = React.useCallback(
      (patch: Patch<FormCropperType>) => setFieldValue(evolve(patch, value)),
      [setFieldValue, value]
    )

    const onCropComplete: CropperProps["onCropComplete"] = (croppedArea) => {
      setPartial({
        ...value,
        isChange: !compareCropper(original, value.transform.cropper),
        transform: {
          ...croppedArea,
          cropper: value.transform.cropper,
        },
      })
    }
    const [original, setOriginal] = React.useState<MediasTransform["cropper"]>(() => value.transform.cropper)
    const onMediaLoaded = (mediaSize: { width: number; height: number }) => {
      // if no aspect ratio is set, set it to the image aspect ratio
      if (value.transform.cropper.aspect.w === 0) {
        const aspect = getImageAspect(mediaSize.width, mediaSize.height)
        const cropper = {
          aspect,
          crop: { x: 0, y: 0 },
          zoom: 1,
        }
        setOriginal(cropper)
        setPartial({
          transform: {
            cropper,
          },
        })
      }
    }
    const aspect = React.useMemo(
      () => value.transform.cropper.aspect.w / value.transform.cropper.aspect.h,
      [value.transform.cropper.aspect]
    )

    return (
      <div className={cx("flex flex-col gap-4", className)} ref={ref} {...props}>
        <div className='relative aspect-square'>
          <CropperBar />
          <div>
            <Cropper
              image={value.url ?? undefined}
              classes={{
                containerClassName: "bg-card",
              }}
              aspect={aspect}
              onCropComplete={onCropComplete}
              onMediaLoaded={onMediaLoaded}
              crop={value.transform.cropper.crop}
              onCropChange={(crop) => setPartial({ transform: { cropper: { crop } } })}
              zoom={value.transform.cropper.zoom}
              onZoomChange={(zoom) => setPartial({ transform: { cropper: { zoom } } })}
              // rotation={value.transform.rotate}
              // onRotationChange={(rotation) => setPartial({ transform: { rotate: rotation } })}
              //initialCroppedAreaPercentages={D.selectKeys(value.transform, ["x", "y", "width", "height"])}
            />
          </div>
          <CropperInsetBar />
        </div>
      </div>
    )
  }
)

/**
 * CropperBar
 */
const CropperBar: React.FC = () => {
  const { _ } = useDictionary("components.form.form-cropper")
  const { id, value, setFieldValue } = useFieldContext<FormCropperType>()
  const setPartial = React.useCallback(
    (patch: Patch<FormCropperType>) => setFieldValue(evolve(patch, value)),
    [setFieldValue, value]
  )
  const setAspect = (aspect: MediasTransform["cropper"]["aspect"]) => setPartial({ transform: { cropper: { aspect } } })
  const aspect = value.transform.cropper.aspect

  // test predefined ratio
  const is169 = React.useMemo(() => {
    const a = getImageAspect(aspect.w, aspect.h)
    return (a.w === 16 && a.h === 9) || (a.w === 9 && a.h === 16)
  }, [aspect])
  const is43 = React.useMemo(() => {
    const a = getImageAspect(aspect.w, aspect.h)
    return (a.w === 4 && a.h === 3) || (a.w === 3 && a.h === 4)
  }, [aspect])
  const isSquare = React.useMemo(() => {
    const a = getImageAspect(aspect.w, aspect.h)
    return a.w === 1 && a.h === 1
  }, [aspect])

  // change aspect ratio
  const toggle43 = () => {
    if (is43) N.gt(aspect.h, aspect.w) ? setAspect({ w: 4, h: 3 }) : setAspect({ w: 3, h: 4 })
    else setAspect({ w: 4, h: 3 })
  }
  const toggle169 = () => {
    if (is169) N.gt(aspect.h, aspect.w) ? setAspect({ w: 16, h: 9 }) : setAspect({ w: 9, h: 16 })
    else setAspect({ w: 16, h: 9 })
  }
  const setSquare = () => setAspect({ w: 1, h: 1 })
  const reverse = () => setAspect({ w: aspect.h, h: aspect.w })

  return (
    <div className='absolute z-10 top-2 inset-x-2 flex justify-between p-2 gap-2 text-xs flex-wrap rounded-md bg-card/25 opacity-75 hover:opacity-100 focus-within:opacity-100 transition-opacity'>
      <div className='flex gap-2 text-xs'>
        <div className='relative'>
          <label htmlFor={`${id}-width`} className={inputIconVariants({ size: "xs", side: "left" })}>
            <MoveHorizontal aria-hidden />
            <SrOnly>{_("width")}</SrOnly>
          </label>
          <input
            id={`${id}-width`}
            className={cx(inputVariants({ size: "xs", icon: "left" }), "w-24")}
            type='number'
            value={aspect.w}
            min={1}
            onChange={({ target }) => setAspect({ ...aspect, w: +target.value })}
          />
        </div>
        <Button variant='ghost' size='xs' icon onClick={reverse}>
          <RefreshCw aria-hidden />
          <SrOnly>{_("reverse")}</SrOnly>
        </Button>
        <div className='relative'>
          <label className={inputIconVariants({ size: "xs", side: "left" })} htmlFor={`${id}-height`}>
            <MoveVertical aria-hidden />
            <SrOnly>{_("height")}</SrOnly>
          </label>
          <input
            id={`${id}-height`}
            className={cx(inputVariants({ size: "xs", icon: "left" }), "w-24")}
            type='number'
            step={1}
            max={24}
            min={1}
            value={aspect.h}
            onChange={({ target }) => setAspect({ ...aspect, h: +target.value })}
          />
        </div>
      </div>
      <div className='flex gap-2 text-xs'>
        <Button variant={is43 ? "default" : "secondary"} onClick={toggle43} size='xs'>
          <RatioIcon className={is43 && N.gt(aspect.h, aspect.w) ? "aspect-[3/4]" : "aspect-[4/3]"} />
          4:3
          <SrOnly>{_("toggle-4-3")}</SrOnly>
        </Button>
        <Button variant={is169 ? "default" : "secondary"} onClick={toggle169} size='xs'>
          <RatioIcon className={is169 && N.gt(aspect.h, aspect.w) ? "aspect-[9/16]" : "aspect-[16/9]"} />
          16:9
          <SrOnly>{_("toggle-16-9")}</SrOnly>
        </Button>
        <Button variant={isSquare ? "default" : "secondary"} onClick={setSquare} size='xs'>
          <RatioIcon className='aspect-square' />
          1:1
          <SrOnly>{_("toggle-1-1")}</SrOnly>
        </Button>
      </div>
    </div>
  )
}

/**
 * CropperInsetBar
 */
const CropperInsetBar: React.FC = () => {
  const { _ } = useDictionary("components.form.form-cropper")
  const { id, value, setFieldValue } = useFieldContext<FormCropperType>()
  const setPartial = React.useCallback(
    (patch: Patch<FormCropperType>) => setFieldValue(evolve(patch, value)),
    [setFieldValue, value]
  )
  const roundedZoom = Math.round(value.transform.cropper.zoom * 100) / 100
  return (
    <div className='absolute bottom-1 right-1 left-1 flex items-center w-full p-2 gap-2 rounded-md bg-card/25 opacity-75 hover:opacity-100 focus-within:opacity-100 transition-opacity'>
      <label htmlFor={`${id}-zoom`} className='[&>svg]:w-4 [&>svg]:h-4'>
        <Maximize2 aria-hidden />
        <SrOnly>{_("zoom")}</SrOnly>
      </label>
      <Slider
        id={`${id}-zoom`}
        value={[value.transform.cropper.zoom]}
        onValueChange={([value]: [number]) => setPartial({ transform: { cropper: { zoom: value } } })}
        min={1}
        max={3}
        step={0.01}
        className='grow'
      />
      <input
        id={`${id}-zoom`}
        className={cx(inputVariants({ size: "xs", icon: "left" }), "w-24")}
        type='number'
        value={roundedZoom}
        min={1}
        step={0.01}
        onChange={({ target }) => setPartial({ transform: { cropper: { zoom: +target.value } } })}
      />
    </div>
  )
}

/**
 * RatioIcon
 */
const RatioIcon: React.FC<{ className: ClassName }> = ({ className }) => (
  <span className={cx("inline-block border-[1.5px] border-current h-3", className)} aria-hidden />
)

/**
 * types
 */
export type FormCropperType = {
  url: string | null
  isChange: boolean
  transform: MediasTransform
}

/**
 * helpers
 */
// Fonction pour calculer le PGCD
const gcd = (a: number, b: number): number => {
  return b === 0 ? a : gcd(b, a % b)
}

// Fonction pour obtenir le ratio d'une image
const getImageAspect = (width: number, height: number) => {
  const divisor = gcd(width, height)
  return {
    w: width / divisor,
    h: height / divisor,
  }
}
const compareCropper = (a: MediasTransform["cropper"], b: MediasTransform["cropper"]) => {
  return a.aspect.w === b.aspect.w && a.aspect.h === b.aspect.h && a.crop.x === b.crop.x && a.crop.y === b.crop.y
}
