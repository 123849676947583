import { addressToGoogleMapUrl } from "@/app/fns/google-map"
import { hasNonEmptyProp, hasTruthyKey } from "@/app/fns/helpers"
import { usePromise } from "@/app/hooks/usePromise"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { ExtraFields, Field, Fields } from "@/components/layout/dashboard/Collection/Fields"
import { Loader } from "@/components/layout/dashboard/Collection/Loader"
import Menu from "@/components/layout/dashboard/Collection/Menu"
import Section from "@/components/layout/dashboard/Collection/Section"
import { Image } from "@/components/radix/ui/image"
import { linkCx } from "@/components/ui/Link"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { useIsAdmin, useMe } from "@/store/auth/hooks"
import { getUser } from "@/store/users/actions"
import { useUser } from "@/store/users/hooks"
import { User } from "@/store/users/localizers"
import {
  Calendar,
  CalendarClock,
  CalendarPlus,
  LogIn,
  Mail,
  MapPinned,
  Phone,
  Settings,
  Trash,
  UserCog,
  UserIcon,
  UserSquare,
} from "lucide-react"
import { getFullname } from "../Item"
import { ContextProvider, usePageContext } from "../Context"
import { Redirect } from "wouter"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Page: DashboardUsersUser
 */
type PageProps = { id: string }
const DashboaUsersUser: React.FC<PageProps> = ({ id }) => {
  const { _ } = useDictionary(dictionary())
  const user = useUser(id)
  const isAdmin = useIsAdmin()

  const [result, inProgress] = usePromise(() => getUser(id))
  if (inProgress) return <Loader breadcrumbs={[[_("breadcrumbs"), "/dashboard/users"]]} />
  if (!result || result.error || G.isNullable(user)) return <Redirect to='/dashboard/users' />

  const fullname = getFullname(user, _("fullname-placeholder"))
  return (
    <PageWrapper>
      <PageHeader
        breadcrumbs={[
          [_("breadcrumbs"), "/dashboard/users"],
          [fullname, `/dashboard/users/${id}`],
        ]}
      />
      <Menu type='context-menu' menu={<ContextMenu user={user} />} disabled={!isAdmin}>
        <div className='inline'>
          <SectionHeader user={user} />
          <SectionAccount user={user} />
          <SectionPersonal user={user} />
        </div>
      </Menu>
    </PageWrapper>
  )
}

export default (props: PageProps) => (
  <ContextProvider>
    <DashboaUsersUser {...props} />
  </ContextProvider>
)

/**
 * SectionHeader
 */
type Props = { user: User }
const SectionHeader: React.FC<Props> = ({ user }) => {
  const { _ } = useDictionary(dictionary())
  const me = useMe()
  const isMe = me.id === user.id
  const fullname = getFullname(user, _("fullname-placeholder"))
  const { position, company } = user.profile
  const image = user.profile.thumbnail ?? user.profile.preview ?? user.profile.image
  return (
    <Section className='mt-16'>
      <div className='relative flex justify-center h-12'>
        <div className={cx("mx-auto absolute -top-20 w-32 h-32 shadow-sm rounded-full transition-all hover:scale-110")}>
          <Image
            src={image}
            alt={_(`profile-image-alt`, { fullname })}
            className='w-full h-full rounded-full aspect-square object-cover text-muted-foreground/10 border-4 border-card'
          >
            <UserIcon size={64} strokeWidth={1} />
          </Image>
        </div>
      </div>
      <div className='flex flex-col space-x-2'>
        <h1 className='font-bold text-center text-3xl'>
          {fullname}
          {isMe && <span className='text-sm pl-2 text-foreground/75'>({_("is-me")})</span>}
        </h1>
        {hasTruthyKey(user.profile, ["position", "company"]) && (
          <p className='text-center text-foreground/50 font-medium'>
            {position} {company}
          </p>
        )}
      </div>
    </Section>
  )
}

/**
 * SectionAccount
 */
const SectionAccount: React.FC<Props> = ({ user }) => {
  const { _ } = useDictionary(dictionary())
  const isAdmin = useIsAdmin()
  const format = useDateFnsLocaleFormat()
  const { role, status, createdAt, updatedAt, lastLoginAt } = user
  return (
    <Section>
      <Section.Header>
        <Section.HeaderTitle>{_("section-account-title")}</Section.HeaderTitle>
        <Section.HeaderDescription>{_("section-account-description")}</Section.HeaderDescription>
        {isAdmin && <Section.Menu menu={<ContextMenu user={user} />} />}
      </Section.Header>
      <Fields divider>
        <Field name={_("role")} icon={<Settings aria-hidden />} value={_(`role-${role}`)} />
        <Field name={_("status")} icon={<UserCog aria-hidden />} value={_(`status-${status}`)} />
        <Field name={_("created-at")} icon={<CalendarPlus aria-hidden />} value={format(createdAt, "PPpp")} />
        <Field name={_("updated-at")} icon={<CalendarClock aria-hidden />} value={format(updatedAt, "PPpp")} />
        <Field
          name={_("last-login-at")}
          icon={<LogIn aria-hidden />}
          value={G.isNotNullable(lastLoginAt) ? format(lastLoginAt, "PPpp") : "-"}
        />
      </Fields>
    </Section>
  )
}

/**
 * SectionPersonal
 */
const SectionPersonal: React.FC<Props> = ({ user }) => {
  const { _ } = useDictionary(dictionary())
  const isAdmin = useIsAdmin()
  const format = useDateFnsLocaleFormat()
  const { address, dob, phones, emails, extras } = user.profile
  return (
    <Section>
      <Section.Header>
        <Section.HeaderTitle>{_("section-personal-title")}</Section.HeaderTitle>
        <Section.HeaderDescription>{_("section-personal-description")}</Section.HeaderDescription>
        {isAdmin && <Section.Menu menu={<ContextMenu user={user} />} />}
      </Section.Header>
      <Fields>
        {G.isNotNullable(dob) && <Field name={_("dob")} icon={<Calendar aria-hidden />} value={format(dob, "PP")} />}
        {hasNonEmptyProp(address) && (
          <Field
            name={_("address")}
            icon={<MapPinned aria-hidden />}
            value={
              <a href={addressToGoogleMapUrl(address)} target='_blank' rel='noreffer noopener' className={linkCx}>
                {address.address}
                {hasTruthyKey(address, ["address"]) && <br />}
                {S.trim(`${address.zip} ${address.city}`)}
                {hasTruthyKey(address, ["zip", "city"]) && <br />}
                {address.state}
                {hasTruthyKey(address, ["state"]) && address.state !== address.city && <br />}
                {address.country}
              </a>
            }
          />
        )}
        <ExtraFields
          fields={phones}
          icon={<Phone aria-hidden />}
          wrapper={(phone) => (
            <a href={`tel:${phone}`} className={linkCx}>
              {phone}
            </a>
          )}
        />
        <ExtraFields
          fields={emails}
          icon={<Mail aria-hidden />}
          wrapper={(email) => (
            <a href={`mailto:${email}`} className={linkCx}>
              {email}
            </a>
          )}
        />
        <ExtraFields fields={extras} />
      </Fields>
    </Section>
  )
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<Props> = ({ user }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { confirmDelete, updateAccount, updateProfile } = usePageContext()

  const { id } = user
  const isAdmin = useIsAdmin()

  return isAdmin ? (
    <>
      <Menu.Item onClick={() => updateAccount(user)}>
        <UserCog aria-hidden />
        {_("edit-account")}
      </Menu.Item>
      <Menu.Item onClick={() => updateProfile(user)}>
        <UserSquare aria-hidden />
        {_("edit-profile")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
    </>
  ) : null
}
