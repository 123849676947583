import usePersistedState from "@/app/hooks/usePersistedState"
import globalConfig from "@/config/global"

/**
 * context
 */
const ThemeProviderContext = React.createContext<ThemeProviderState>({
  color: "default",
  setColor: () => null,
  theme: "system",
  setTheme: () => null,
  isDark: false,
  isLight: true,
})

/**
 * ThemeProvider
 */
type ThemeProviderProps = {
  children: React.ReactNode
  defaultTheme?: Theme
  storageKey?: string
}
export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  defaultTheme = "system",
  storageKey = `${globalConfig.sessionKey}-ui-theme`,
  ...props
}) => {
  const [theme, setTheme] = React.useState<Theme>(() => (localStorage.getItem(storageKey) as Theme) || defaultTheme)
  const [color, setColor] = usePersistedState<Color>("default", "color", localStorage)
  React.useEffect(() => {
    const root = window.document.documentElement
    root.classList.remove(...themeColors)
    if (color !== "default") root.classList.add(color)
  }, [color])

  React.useEffect(() => {
    const root = window.document.documentElement
    root.classList.remove("light", "dark")
    if (theme === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
      root.classList.add(systemTheme)
    } else root.classList.add(theme)
  }, [theme])
  const isLight = React.useMemo(
    () => theme === "light" || (theme === "system" && window.matchMedia("(prefers-color-scheme: light)").matches),
    [theme]
  )
  const isDark = React.useMemo(
    () => theme === "dark" || (theme === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches),
    [theme]
  )
  const value = {
    isLight,
    isDark,
    color,
    setColor,
    theme,
    setTheme: (theme: Theme) => {
      localStorage.setItem(storageKey, theme)
      setTheme(theme)
    },
  }

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  )
}
/**
 * const
 */
export const themeColors = ["default", "red", "blue", "green", "sky"] as const
export const themes = ["system", "light", "dark"] as const

/**
 * hook
 */
export const useTheme = () => {
  const context = React.useContext(ThemeProviderContext)
  if (context === undefined) throw new Error("useTheme must be used within a ThemeProvider")
  return context
}

/**
 * types
 */
type Theme = (typeof themes)[number]
type Color = (typeof themeColors)[number]
type ThemeProviderState = {
  color: Color
  setColor: (color: Color) => void
  theme: Theme
  setTheme: (theme: Theme) => void
  isDark: boolean
  isLight: boolean
}
