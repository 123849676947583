import { SelectProvider, useSelect } from "@/app/hooks/useSelect"
import { Form, FormTiptap, useForm } from "@/components/form"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useRefreshPages } from "@/store/pages/hooks"
import Collection from "./Collection"
import { Context, ContextProvider } from "./Context"
import { proseStyle } from "@/components/cms/frontend/proseStyle"

/**
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "pages")

/**
 * Page: PagesIndex
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
const PagesIndex: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  useRefreshPages()

  return (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[[_("breadcrumbs"), "/dashboard/pages"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <PagesIndex />
  </ContextProvider>
)
