import { textPlaceholder } from "@/app/fns/String"
import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormFieldGroup,
  FormHeader,
  FormInput,
  FormReorderableItem,
  FormReorderableList,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
  useFieldGroupContext,
  useForm,
  useFormContext,
} from "@/components/form"
import { FormTextarea } from "@/components/form/FormTextarea"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/radix/ui/button"
import Dialog from "@/components/radix/ui/dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { RectangleHorizontal, Trash } from "lucide-react"
import { v4 as uuid } from "uuid"
import { proseStyle } from "../../frontend/proseStyle"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)
const formDictionary = createContextMapper("components", "cms", "content", "form")

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _form } = useDictionary(formDictionary())

  const {
    actions: { updateContentItem },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: React.useMemo(
      () => ({
        titleLevel: `${item.props.titleLevel}`,
        cards: item.props.cards,
        translations: D.map(languagesById, (language) => ({
          languageId: language.id,
          title: translate(item, language)?.props.title ?? "",
          secondary: translate(item, language)?.props.secondary ?? "",
          description: translate(item, language)?.props.description ?? "",
          linkText: translate(item, language)?.props.linkText ?? "",
          linkUrl: translate(item, language)?.props.linkUrl ?? "",
          cards:
            translate(item, language)?.props.cards ??
            A.reduce(item.props.cards, {} as Cards, (cards, card) => ({
              ...cards,
              [card]: emptyCard,
            })),
        })),
      }),
      []
    ),
    onSubmit: async ({ values }) => {
      const payload: FormPayload<ItemType> = {
        props: {
          titleLevel: +values.titleLevel,
          cards: values.cards,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: A.reduce(D.values(values.translations), [] as string[], (files, translation) => [
          ...files,
          ...A.filterMap(D.values(translation.cards), (card) => card.image || O.None),
        ]),
      }
      const { error, code } = await updateContentItem(item.id, payload)
      if (!error) {
        toast.success(_("success"))
        return close()
      } else if (code === "VALIDATION_FAILURE") {
        return _form(code)
      } else if (code === "RESOURCE_NOT_FOUND" || code === "FETCH_ERROR") {
        toast.error(_form(code))
        return close()
      }
      resetAllStoresAndReload()
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs context={false}>
        {(language) => (
          <div className="grid gap-6" key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput label={_form("title-label")} name="title" placeholder={_form("title-placeholder")} />
              <FormInput
                label={_form("secondary-label")}
                name="secondary"
                placeholder={_form("secondary-placeholder")}
              />
              <FormTiptap label={_form("description-label")} name="description" prose={cx(proseStyle, "bg-white")} />
              <FormInput label={_form("cta-text-label")} name="linkText" placeholder={_form("cta-text-placeholder")} />
              <FormInput
                label={_form("cta-url-label")}
                name="linkUrl"
                type="url"
                placeholder={_form("cta-url-placeholder")}
              />
              <FormCards />
            </FormTranslationContext>
            <FormHeader>
              <FormHeader.Title>{_form("more-options-title")}</FormHeader.Title>
              <FormHeader.Description>{_form("more-options-description")}</FormHeader.Description>
            </FormHeader>
            <FormSelect label={_form("title-level-label")} name="titleLevel" options={titleLevelOptions} />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * FormCards
 */
const FormCards: React.FC = () => {
  const { _ } = useDictionary(dictionary(`cards`))
  const { _: _form } = useDictionary(formDictionary())

  const { values, setValues } = useFormContext<FormCardsContext>()

  // create a new card
  const createCard = () => {
    const cardId = uuid()
    setValues({
      cards: [...values.cards, cardId],
      translations: D.map(values.translations, (translation) => ({
        ...translation,
        cards: {
          ...translation.cards,
          [cardId]: emptyCard,
        },
      })),
    })
  }

  // drag and drop reordering
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      const oldIndex = values.cards.indexOf(active.id as string)
      const newIndex = values.cards.indexOf(over!.id as string)
      setValues({
        cards: arrayMove(values.cards, oldIndex, newIndex),
      })
    }
  }

  return (
    <FormFieldGroup name="cards">
      <div className="flex flex-col items-start gap-4">
        <FormHeader>
          <FormHeader.Title>{_("title")}</FormHeader.Title>
          <FormHeader.Description>{_("description")}</FormHeader.Description>
        </FormHeader>
        <FormReorderableList
          onDragEnd={onDragEnd}
          items={values.cards}
          createButton={_form("create-card")}
          create={createCard}
        >
          {A.map(values.cards, (id) => (
            <FormCard id={id} key={id} />
          ))}
        </FormReorderableList>
      </div>
    </FormFieldGroup>
  )
}

/**
 * FormCard
 */
type FormCardProps = {
  id: string
}
const FormCard: React.FC<FormCardProps> = ({ id }) => {
  const { _ } = useDictionary(dictionary(`cards`))
  const { _: _form } = useDictionary(formDictionary())
  const { id: contextKey } = useCmsContext()

  const { values, setValues } = useFormContext<FormCardsContext>()
  const { title } = D.getUnsafe(useFieldGroupContext<FormCardsContext["translations"][string]["cards"]>().values, id)

  // delete a card
  const deleteCard = () => {
    setValues({
      cards: A.reject(values.cards, (card) => card === id),
      translations: D.map(values.translations, (translation) => ({
        ...translation,
        cards: D.deleteKey(translation.cards, id),
      })),
    })
  }

  // keyboard accessibility reordering
  const onKeyDown = (keyCode: "ArrowUp" | "ArrowDown") => {
    const oldIndex = values.cards.indexOf(id)
    switch (keyCode) {
      case "ArrowUp": {
        const newIndex = oldIndex - 1
        if (newIndex < 0) return
        setValues({
          cards: arrayMove(values.cards, oldIndex, newIndex),
        })
        break
      }
      case "ArrowDown": {
        const newIndex = oldIndex + 1
        if (newIndex >= values.cards.length) return
        setValues({
          cards: arrayMove(values.cards, oldIndex, newIndex),
        })
        break
      }
    }
  }

  const variantOptions = [
    { value: "primary", label: _("card.variant-primary") },
    { value: "secondary", label: _("card.variant-secondary") },
  ]

  return (
    <FormReorderableItem
      id={id}
      title={
        <>
          <RectangleHorizontal size={16} aria-hidden />
          {textPlaceholder(title, _form("card-title"))}
        </>
      }
      titleLevel={4}
      actions={
        <Button variant="secondary" size="xxs" icon onClick={deleteCard} aria-label={_form("delete-card")}>
          <Trash aria-hidden />
        </Button>
      }
      onKeyDown={onKeyDown}
    >
      <FormFieldGroup name={id}>
        <div className="relative flex flex-col gap-4">
          <FormMediasImage label={_("card.image-label")} name="image" ratio="aspect-video" contextKey={contextKey} />
          <FormSelect label={_("card.variant-label")} name="variant" options={variantOptions} />
          <FormInput label={_("card.title-label")} name="title" placeholder={_("card.title-placeholder")} />
          <FormTextarea
            label={_("card.description-label")}
            name="description"
            placeholder={_("card.description-placeholder")}
          />
          <FormInput label={_("card.link-text-label")} name="linkText" placeholder={_("card.link-text-placeholder")} />
          <FormInput
            label={_("card.link-url-label")}
            name="linkUrl"
            type="url"
            placeholder={_("card.link-url-placeholder")}
          />
        </div>
      </FormFieldGroup>
    </FormReorderableItem>
  )
}

const emptyCard = {
  image: "",
  title: "",
  description: "",
  variant: "primary" as const,
  linkText: "",
  linkUrl: "",
}

/**
 * types
 */
type FormCardsContext = {
  cards: string[]
  translations: Record<
    string,
    {
      languageId: string
      cards: Record<string, Cards[string]>
    }
  >
}
type Cards = FormPayload<ItemType>["translations"][number]["props"]["cards"]
