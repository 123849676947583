import { Button } from "@/components/radix/ui/button"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { BoxSelect, Trash, FolderTree, Eye, EyeOff } from "lucide-react"

/**
 * dictionary src/dictionaries/en/components/layout.json
 */
const dictionary = createContextMapper("components", "layout")

/**
 * Selection
 */
type SelectionProps = {
  selected: string[]
  moveSelection?: () => void
  deleteSelection?: () => void
  publishSelection?: () => void
  unpublishSelection?: () => void
  unselect?: () => void
  children?: React.ReactNode
}
export const Selection: React.FC<SelectionProps> = ({
  selected,
  unselect,
  moveSelection,
  deleteSelection,
  publishSelection,
  unpublishSelection,
  children,
}) => {
  const { _ } = useDictionary(dictionary("selection"))
  return (
    <DialogPrimitive.Root open={A.isNotEmpty(selected)} modal={false}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Content className="fixed z-50 bottom-4 right-4 flex items-center gap-4 px-8 py-2 border bg-card shadow-lg rounded-sm">
          <div className="text-sm">{selected.length} element(s) selected</div>
          {unselect && (
            <Button onClick={unselect} variant="ghost" size="xs">
              <BoxSelect size={16} aria-hidden />
              {_("unselect")}
            </Button>
          )}
          {publishSelection && (
            <Button onClick={publishSelection} variant="ghost" size="xs">
              <Eye size={16} aria-hidden />
              {_("publish")}
            </Button>
          )}
          {unpublishSelection && (
            <Button onClick={unpublishSelection} variant="ghost" size="xs">
              <EyeOff size={16} aria-hidden />
              {_("unpublish")}
            </Button>
          )}
          {moveSelection && (
            <Button onClick={moveSelection} variant="ghost" size="xs">
              <FolderTree size={16} aria-hidden />
              {_("move")}
            </Button>
          )}
          {deleteSelection && (
            <Button onClick={deleteSelection} variant="ghost" size="xs">
              <Trash size={16} aria-hidden />
              {_("delete")}
            </Button>
          )}
          {children}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
