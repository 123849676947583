/**
 * AdaptHeight
 */
type AdaptHeightProps = {
  children: React.ReactNode
  extensive?: boolean
  animateInitial?: boolean
  hideInitial?: boolean
  overflowVisible?: boolean
  afterInitial?: "visible" | "hidden"
  transition?: string
  deps?: React.DependencyList
}
const AdaptHeight: React.FC<AdaptHeightProps> = ({
  extensive,
  animateInitial,
  transition = "300ms",
  deps = [],
  ...props
}) => {
  const animate = React.useRef(typeof animateInitial === "boolean" ? animateInitial : false)
  const [height, setHeight] = React.useState<number>(0)
  const [overflowVisible, setOverflowVisible] = React.useState(props.overflowVisible)

  const containerRef = React.useRef<HTMLDivElement>(null)

  const updateHeight = () => {
    const rect = containerRef.current?.getBoundingClientRect()
    if (!rect) return
    setHeight(rect.height)
  }

  React.useEffect(() => {
    setTimeout(() => (animate.current = true), 1)
  }, [])

  React.useEffect(() => {
    updateHeight()

    if (!containerRef.current) return

    const resizeHandler = updateHeight

    window.addEventListener("resize", resizeHandler)

    const observer = new MutationObserver(updateHeight)

    observer.observe(containerRef.current, {
      characterData: typeof extensive === "boolean" ? extensive : true,
      attributes: true,
      childList: true,
      subtree: true,
    })

    return () => {
      observer.disconnect()
      window.removeEventListener("resize", resizeHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setOverflowVisible(!!props.overflowVisible)
    const to = setTimeout(() => props.afterInitial && setOverflowVisible(props.afterInitial === "visible"), 500)
    return () => void clearTimeout(to)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height])

  React.useEffect(() => {
    updateHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])

  return (
    <div
      className={cx(
        "w-full",
        overflowVisible ? "overflow-visible" : "overflow-hidden",
        props.hideInitial && height === 0 ? "invisible" : "visible"
      )}
      style={{
        willChange: "height",
        height: `${height}px`,
        transition: animate.current ? transition : undefined,
      }}
    >
      <div ref={containerRef} className='w-full flex flex-col'>
        {props.children}
      </div>
    </div>
  )
}

export default AdaptHeight
