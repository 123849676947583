import { FormSelectOption } from "@/components/form/FormSelect"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "form")

export const useSchemeOptions = (): FormSelectOption[] => {
  const { _, language } = useDictionary(dictionary())
  const titleLevelOption = React.useMemo(
    () => [
      {
        label: _("scheme-light"),
        value: "light",
      },
      {
        label: _("scheme-dark"),
        value: "dark",
      },
    ],
    [language]
  )
  return titleLevelOption
}
