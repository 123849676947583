import { Button, buttonVariants } from "@/components/radix/ui/button"
import { SrOnly } from "@/components/radix/ui/sr-only"
import { Link } from "@/components/ui/Link"
import { useDictionary } from "@/dictionaries/hooks"
import { ArrowLeft, ChevronRight, LucideProps } from "lucide-react"

/**
 * Breadcrumbs
 */
type BreadcrumbsProps = {
  size?: "default" | "sm" | "xs"
  root?: Breadcrumb
  breadcrumbs: Breadcrumb[]
  className?: ClassName
}
export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ root, size = "default", breadcrumbs, className }) => {
  const { _ } = useDictionary()
  const base = G.isNotNullable(root) ? root : [_("pages.dashboard.index.breadcrumbs"), "/dashboard"]
  const back = () => {
    const fnOrPath = (breadcrumbs[breadcrumbs.length - 2] ?? base)[1]
    G.isFunction(fnOrPath) ? fnOrPath() : navigate(fnOrPath)
  }
  const hiddenCx = "hidden @lg/breadcrumb:inline-flex"
  const textSize = size === "default" ? "text-sm" : "text-xs"
  const dividerSize = size === "default" ? 16 : size === "sm" ? 12 : 10
  return (
    <div className={cx("@container/breadcrumb flex", size === "default" ? "gap-4" : "gap-2", className)}>
      <Button
        side="right"
        aria-label={A.isEmpty(breadcrumbs) ? undefined : _("components.layout.sidebar.back")}
        onClick={back}
        disabled={A.isEmpty(breadcrumbs)}
        variant="secondary"
        icon
        size={size}
      >
        <ArrowLeft aria-hidden />
        <SrOnly>{_("components.layout.sidebar.back")}</SrOnly>
      </Button>
      <ul className="flex items-center gap-0">
        {A.mapWithIndex([base, ...breadcrumbs], (index, [text, link]) => (
          <li key={index}>
            {index === breadcrumbs.length ? (
              <AsSpan className={textSize}>{text}</AsSpan>
            ) : (
              <>
                {G.isFunction(link) ? (
                  <AsButton onClick={link} className={cx(hiddenCx, textSize)}>
                    {text}
                  </AsButton>
                ) : (
                  <AsLink href={link} className={cx(hiddenCx, textSize)}>
                    {text}
                  </AsLink>
                )}
                <Divider className={hiddenCx} size={dividerSize} />
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

/**
 * components
 */
const AsLink: React.FC<React.ComponentProps<typeof Link>> = ({ className, children, ...props }) => (
  <Link
    {...props}
    className={cx(
      buttonVariants({ variant: "link", size: "link", className: cx("font-normal text-inherit", className) })
    )}
  >
    <span className="line-clamp-1">{children}</span>
  </Link>
)

const AsButton: React.FC<React.ComponentPropsWithoutRef<"button">> = ({ children, ...props }) => (
  <Button {...props} variant="link" size="link">
    <span className="line-clamp-1">{children}</span>
  </Button>
)
const AsSpan: React.FC<React.ComponentPropsWithoutRef<"span">> = ({ className, children, ...props }) => (
  <span className={cx("inline-flex items-center justify-center h-[1rem] px-1 py-1 rounded-md", className)} {...props}>
    <span className="line-clamp-1">{children}</span>
  </span>
)
const Divider: React.FC<LucideProps> = ({ className, ...props }) => (
  <ChevronRight {...props} className={cx("text-foreground/50 mx-1", className)} aria-hidden />
)

/**
 * types
 */
export type Breadcrumb = [React.ReactNode, string] | [React.ReactNode, () => void]
