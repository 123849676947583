import { Form, FormAssertive, FormInput, FormSubmit, FormTranslationTabs, useForm } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import Dialog from "@/components/radix/ui/dialog"
import { contextMapper, createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { useCmsContext } from "../../Context"
import { FormPayload, ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _form } = useDictionary(contextMapper("components", "cms", "content", "form"))

  const {
    actions: { updateContentItem },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: React.useMemo(
      () => ({
        titleLevel: `${item.props.titleLevel}`,
        translations: D.map(languagesById, (language) => ({
          languageId: language.id,
          title: translate(item, language)?.props.title ?? "",
          secondary: translate(item, language)?.props.secondary ?? "",
        })),
      }),
      []
    ),
    onSubmit: async ({ values }) => {
      const payload: FormPayload<ItemType> = {
        props: {
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: [],
      }
      const { error, code } = await updateContentItem(item.id, payload)
      if (!error) {
        toast.success(_("success"))
        return close()
      } else if (code === "VALIDATION_FAILURE") {
        return _form(code)
      } else if (code === "RESOURCE_NOT_FOUND" || code === "FETCH_ERROR") {
        toast.error(_form(code))
        return close()
      }
      resetAllStoresAndReload()
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs>
        {(language) => (
          <div className="grid gap-6" key={language.id}>
            <FormInput label={_("title-label")} name="title" placeholder={_("title-placeholder")} />
            <FormInput label={_("secondary-label")} name="secondary" placeholder={_("secondary-placeholder")} />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
