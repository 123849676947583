import { Form, FormAssertive, FormInput, FormSubmit, FormSwitch, useForm, validator } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useIsSuperadmin } from "@/store/auth/hooks"
import { updateMediasFolder } from "@/store/medias/actions"
import { MediasFolder } from "@/store/medias/localizers"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * EditFolderDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: MediasFolder | false
}
export const EditFolderDialog: React.FC<Props> = ({ item, open, onOpenChange }) => {
  const { _ } = useDictionary(dictionary("edit-folder-dialog"))
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      {item !== false && <DialogForm close={() => onOpenChange(false)} folder={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<{ folder: MediasFolder; close: () => void }> = ({ folder, close }) => {
  const { _ } = useDictionary(dictionary("edit-folder-dialog"))
  const { min } = validator
  const isSuperadmin = useIsSuperadmin()
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      name: folder.name,
      lock: folder.lock,
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      name: [min(1, "name-required")],
    }),
    onSubmit: async ({ values: { name, lock } }, event) => {
      event?.stopPropagation()
      if (!form.isValid) return "VALIDATION_FAILURE"
      const data = { name, lock: isSuperadmin ? lock : undefined }
      const response = await updateMediasFolder(folder.id, data)
      console.log(response)

      if (!response.error) {
        toast.success(_("success"))
        close()
      } else if (response.code === "RESOURCE_NOT_FOUND") {
        toast.error(_(response.code))
        close()
      } else return response.code
    },
  })

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormInput label={_("name-label")} name='name' placeholder={_("name-placeholder")} />
      {isSuperadmin && <FormSwitch label={_("lock-label")} name='lock' />}
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
