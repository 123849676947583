import { apiContent } from "@/components/cms/items/schemas"
import { z } from "zod"
import { apiSeo } from "../seos/schemas"

/**
 * apiPage
 */
export const apiPageState = z.union([z.literal("draft"), z.literal("published")])
export type ApiPageeState = z.infer<typeof apiPageState>
export const apiPage = z.object({
  id: z.string().uuid(),
  state: apiPageState,
  trackingId: z.string().uuid(),
  seoId: z.string(),
  seo: apiSeo,
  contentId: z.string().uuid(),
  content: apiContent,
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiPage = z.infer<typeof apiPage>
