import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import globalConfig from "@/config/global"
import { useDictionary } from "@/dictionaries/hooks"
import { updateMe } from "@/store/auth/actions"
import { useMe } from "@/store/auth/hooks"

/**
 * AccountDialog
 * dictionary src/dictionaries/en/components/dialogs/account-dialog.json
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
}
export const AccountDialog: React.FC<Props> = (props) => {
  const { open, onOpenChange } = props
  const { _ } = useDictionary("components.dialogs.account-dialog")
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='max-w-sm'
    >
      <AccountForm {...props} />
    </Dialog>
  )
}

/**
 * AccountForm
 * dictionary src/dictionaries/en/components/dialogs/account-dialog.json
 */
export const AccountForm: React.FC<Props> = ({ onOpenChange }) => {
  const { _ } = useDictionary("components.dialogs.account-dialog")

  const me = useMe()
  const { min, isEmail } = validator

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      email: me.email,
      password: "",
      confirm: "",
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      email: [min(1, "email-required"), isEmail("email-format")],
      password: [(field) => S.isEmpty(S.trim(field)) || N.gte(S.length(S.trim(field)), 8) || "password-length"],
      confirm: [(field, { password }) => field === password || "password-confirm"],
    }),

    onSubmit: async ({ values: { email, password: maybePassword } }) => {
      if (!form.isValid) return "VALIDATION_FAILURE"
      const data = {
        email,
        redirect: `${globalConfig.host}/validate-email`,
        password: S.isEmpty(maybePassword) ? undefined : S.trim(maybePassword),
      }
      const response = await updateMe(data)
      if (!response.error) {
        toast.success(_("success"))
        onOpenChange(false)
      } else return response.code
    },
  })

  const showEmailInfo =
    S.trim(S.toLowerCase(me.email)) !== S.trim(S.toLowerCase(form.values.email)) && G.isNullable(form.errors.email)

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormInput
        label={_("email-label")}
        name='email'
        type='email'
        info={showEmailInfo && _("email-dirty")}
        placeholder={_("email-placeholder")}
      />
      <FormInput label={_("password-label")} name='password' type='password' placeholder={_("password-placeholder")} />
      <FormInput label={_("confirm-label")} name='confirm' type='password' placeholder={_("confirm-placeholder")} />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
