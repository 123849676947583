import useUuid from "@/app/hooks/useUuid"
import { Button } from "@/components/radix/ui/button"
import { Hn } from "@/components/ui/Hn"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core"
import { SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { ChevronDown, Plus } from "lucide-react"
import { SrOnly } from "../radix/ui/sr-only"

/**
 * dictionary src/dictionaries/en/components/form/form-reorderable.json
 */
const dictionary = createContextMapper("components", "form", "form-reorderable")

/**
 * FormReorderableList
 */
type FormReorderableListProps = {
  items: string[]
  limit?: number
  createButton: string
  create: () => void
  onDragEnd: (event: DragEndEvent) => void
  isReorderable?: boolean
  children: React.ReactNode
}
export const FormReorderableList: React.FC<FormReorderableListProps> = ({
  items,
  limit = Infinity,
  createButton,
  create,
  onDragEnd,
  isReorderable = true,
  children,
}) => {
  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const sensors = useSensors(mouseSensor, touchSensor)

  return (
    <div className="flex flex-col w-full items-start gap-4">
      {A.isNotEmpty(items) &&
        (isReorderable ? (
          <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} sensors={sensors}>
            <SortableContext items={items} strategy={rectSortingStrategy}>
              <ul className="flex flex-col w-full gap-4">{children}</ul>
            </SortableContext>
          </DndContext>
        ) : (
          <ul className="flex flex-col w-full gap-4">{children}</ul>
        ))}
      {items.length < limit && (
        <Button onClick={() => create()} variant="outline">
          <Plus size={16} aria-hidden />
          {createButton}
        </Button>
      )}
    </div>
  )
}

/**
 * FormReorderableItem
 */
type FormReorderableItemProps = {
  id: string
  onKeyDown: (key: "ArrowUp" | "ArrowDown") => void
  title: React.ReactNode
  titleLevel: number
  actions: React.ReactNode
  isReorderable?: boolean
  children: React.ReactNode
}
export const FormReorderableItem: React.FC<FormReorderableItemProps> = ({
  id,
  onKeyDown,
  title,
  titleLevel = 3,
  actions,
  isReorderable = true,
  children,
}) => {
  const { _ } = useDictionary(dictionary())
  const accordionId = useUuid()
  const [state, setState] = React.useState(accordionId)
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id })
  const style = { transform: CSS.Transform.toString(transform), transition }
  return (
    <AccordionPrimitive.Root
      type="single"
      collapsible
      className="group/card @container/card grid w-full max-w-screen-2xl mx-auto"
      value={state}
      onValueChange={setState}
    >
      <AccordionPrimitive.Item asChild value={accordionId}>
        <li
          ref={setNodeRef}
          style={style}
          className={cx(
            "relative flex flex-col border rounded-md",
            isReorderable && "border-dashed",
            isDragging ? "opacity-75 z-20" : "opacity-100 z-10"
          )}
        >
          {isReorderable && (
            <button
              className="absolute inset-0 w-full h-full rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
              {...listeners}
              {...attributes}
              onKeyDown={(e) => {
                const keyCode = e.key
                if (!(keyCode === "ArrowUp" || keyCode === "ArrowDown")) return
                e.preventDefault()
                onKeyDown(keyCode)
              }}
              type="button"
            >
              <SrOnly>{_("reorder")}</SrOnly>
            </button>
          )}
          <div className="flex justify-between items-center p-4">
            <Hn
              className="flex items-center gap-2 text-base font-normal leading-none tracking-tight"
              level={titleLevel}
            >
              {title}
            </Hn>
            <div className="relative flex items-center gap-2">
              {actions}
              <AccordionPrimitive.Trigger asChild>
                <Button variant="ghost" size="xxs" icon>
                  <ChevronDown aria-hidden className="h-4 w-4 shrink-0 transition-transform duration-200" />
                  <SrOnly>{_("accordion-toggle")}</SrOnly>
                </Button>
              </AccordionPrimitive.Trigger>
            </div>
          </div>
          <AccordionPrimitive.Content
            className={cx(
              "overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
            )}
          >
            <div className="relative flex flex-col p-4 gap-4">{children}</div>
          </AccordionPrimitive.Content>
        </li>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  )
}
