import usePersistedState from "@/app/hooks/usePersistedState"
import useUuid from "@/app/hooks/useUuid"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { deleteMediasFile, deleteMediasFolder } from "@/store/medias/actions"
import { useMediasFilesById, useMediasFoldersById } from "@/store/medias/hooks"
import { MediasFile, MediasFolder } from "@/store/medias/localizers"
import { Confirm, confirmSafeDictionary, useConfirm } from "../ui/Confirm"
import { useDialog } from "../ui/Dialog"
import { CreateFolderDialog } from "./dialogs/CreateFolderDialog"
import { CropImageDialog } from "./dialogs/CropImageDialog"
import { EditFileDialog } from "./dialogs/EditFileDialog"
import { EditFolderDialog } from "./dialogs/EditFolderDialog"
import { MoveMediaDialog } from "./dialogs/MoveMediaDialog"
import { MoveSelectionDialog } from "./dialogs/MoveSelectionDialog"
import { UploadFilesDialog } from "./dialogs/UploadFilesDialog"
import { FileInfoDialog } from "./dialogs/FileInfoDialog"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const useMediaContext = () => React.useContext(Context)
export const ContextProvider: React.FC<ContextProviderProps> = ({
  contextKey,
  current,
  selectFolder = false,
  selectFile = false,
  accepted,
  defaultView = "grid",
  children,
}) => {
  const translate = useTranslation()

  const contextId = useUuid()
  const filesById = useMediasFilesById()
  const foldersById = useMediasFoldersById()

  // local helpers
  const displayFileName = (id: string) => {
    const file = D.get(filesById, id)
    return G.isNotNullable(file) ? translate(file).name : id
  }
  const displayFolderName = (id: string) => {
    return foldersById[id]?.name ?? id
  }

  // curent folder
  const [currentFolder, setCurrentFolder] = usePersistedState<string | null>(
    G.isUndefined(current) ? null : current,
    `medias-${contextKey}-current`,
    sessionStorage
  )

  React.useEffect(() => {
    !G.isUndefined(current) && setCurrentFolder(current)
  }, [current])

  // selection
  const [selected, setSelected] = React.useState<ContextType["selected"]>({
    folders: [],
    files: [],
  })
  const resetSelection = () => setSelected({ folders: [], files: [] })
  React.useEffect(() => resetSelection(), [currentFolder])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    defaultView,
    `medias-${contextKey}-view`,
    sessionStorage
  )

  // confirms
  const { confirm: confirmDeleteFolder, ...deleteFolderProps } = useConfirm<string>({
    onAsyncConfirm: deleteMediasFolder,
    dictionary: "components.medias.delete-folder-confirm",
  })
  const { confirm: confirmDeleteFile, ...deleteFileProps } = useConfirm<string>({
    onAsyncConfirm: deleteMediasFile,
    dictionary: "components.medias.delete-file-confirm",
  })
  const { _: _deleteSelection } = useDictionary(dictionary("delete-selection-confirm"))
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void>({
    onConfirm: confirmSelection(
      selected,
      deleteMediasFolder,
      displayFolderName,
      deleteMediasFile,
      displayFileName,
      `delete-selection-confirm-${contextId}`,
      _deleteSelection,
      resetSelection
    ),
    dictionary: confirmSafeDictionary(dictionary("delete-selection-confirm")),
  })

  // dialogs
  const { setItem: createFolder, ...createFolderProps } = useDialog<string | null>()
  const { setItem: editFolder, ...editFolderProps } = useDialog<MediasFolder>()
  const { setItem: moveFolder, ...moveFolderProps } = useDialog<string>()
  const { setItem: uploadFiles, ...uploadFilesProps } = useDialog<string | null>()
  const { setItem: editFile, ...editFileProps } = useDialog<MediasFile>()
  const { setItem: moveFile, ...moveFileProps } = useDialog<string>()
  const { setItem: moveSelection, ...moveSelectionProps } = useDialog<void>()
  const { setItem: cropImage, ...cropImageProps } = useDialog<MediasFile>()
  const { setItem: info, ...infoProps } = useDialog<MediasFile>()

  return (
    <Context.Provider
      value={{
        contextId,
        currentFolder,
        setCurrentFolder,

        selected,
        setSelected,
        resetSelection,
        accepted,
        selectFolder,
        selectFile,

        view,
        setView,

        createFolder,
        editFolder,
        moveFolder,
        confirmDeleteFolder,

        confirmDeleteSelection,
        moveSelection,

        uploadFiles,
        confirmDeleteFile,
        editFile,
        cropImage,
        moveFile,
        info,
      }}
    >
      {children}
      <Confirm {...deleteFolderProps} />
      <Confirm {...deleteFileProps} />
      <Confirm {...deleteSelectionProps} />
      <CreateFolderDialog {...createFolderProps} />
      <EditFolderDialog {...editFolderProps} />
      <MoveMediaDialog {...moveFolderProps} type='folder' />
      <UploadFilesDialog {...uploadFilesProps} />
      <EditFileDialog {...editFileProps} />
      <CropImageDialog {...cropImageProps} />
      <MoveMediaDialog {...moveFileProps} type='file' />
      <MoveSelectionDialog {...moveSelectionProps} />
      <FileInfoDialog openInfo={infoProps.open} setOpenInfo={infoProps.onOpenChange} file={infoProps.item} />
    </Context.Provider>
  )
}
/**
 * types
 */
type ContextProviderProps = {
  contextKey: string
  accepted?: string[]
  current?: string | null
  children: React.ReactNode
  selectFolder?: boolean | "multiple"
  selectFile?: boolean | "multiple"
  defaultView?: ContextType["view"]
}
type ContextType = {
  contextId: string
  currentFolder: string | null
  setCurrentFolder: (id: string | null) => void

  selected: { folders: string[]; files: string[] }
  setSelected: (selected: ContextType["selected"]) => void
  resetSelection: () => void
  accepted: string[] | undefined
  selectFolder: boolean | "multiple"
  selectFile: boolean | "multiple"

  view: "grid" | "list"
  setView: (view: ContextType["view"]) => void

  createFolder: (id: string | null) => void
  editFolder: (folder: MediasFolder) => void
  moveFolder: (id: string) => void
  confirmDeleteFolder: (id: string) => void

  uploadFiles: (id: string | null) => void
  confirmDeleteFile: (id: Uuid) => void
  editFile: (file: MediasFile) => void
  cropImage: (file: MediasFile) => void
  moveFile: (id: string) => void
  info: (file: MediasFile) => void

  confirmDeleteSelection: () => void
  moveSelection: () => void
}

/**
 * confimSelection
 */
export const confirmSelection =
  (
    selected: ContextType["selected"],
    foldersAction: (id: string) => Promise<{ error: boolean }>,
    displayFolderName: (id: string) => string,
    filesAction: (id: string) => Promise<{ error: boolean }>,
    displayFileName: (id: string) => string,
    key: string,
    _: ReturnType<typeof useDictionary>["_"],
    resetSelection: () => void
  ) =>
  async () => {
    if (A.isEmpty(selected.folders) && A.isEmpty(selected.files)) return
    let counter = 1
    const total = selected.folders.length + selected.files.length
    const folders = [...selected.folders]
    const files = [...selected.files]
    resetSelection()
    const toastId = toast.promise(
      Promise.all([
        ...A.mapWithIndex(folders, async (index, item) => {
          const { error } = await foldersAction(item)
          toast.loading(_("progress", { counter: counter++, total }), {
            id: toastId,
          })
          if (error) toast.error(_("error"), { description: displayFolderName(item) })
        }),
        ...A.mapWithIndex(files, async (index, item) => {
          const { error } = await filesAction(item)
          toast.loading(_("progress", { counter: counter++, total }), {
            id: toastId,
          })
          if (error) toast.error(_("error"), { description: displayFileName(item) })
        }),
      ]),
      {
        loading: _("progress", { counter, total }),
        success: _("success"),
      }
    )
  }
