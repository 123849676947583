import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useMediasStore } from "@/store/medias"
import { updateMediasFile, updateMediasFolder } from "@/store/medias/actions"
import { getChildrenFolderIds } from "@/store/medias/helpers"
import { useMediaContext } from "../Context"
import { SelectFolder } from "../components/selectFolder"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias", "move-media-dialog")

/**
 * MoveDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: string | false
  type: "file" | "folder"
}
export const MoveMediaDialog: React.FC<Props> = ({ item, type, open, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const { currentFolder } = useMediaContext()
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_(`title-${type}`)}
      description={_(`secondary-${type}`)}
      className='sm:max-w-xl'
    >
      {item !== false && (
        <DialogForm close={() => onOpenChange(false)} mediaId={item} type={type} currentFolder={currentFolder} />
      )}
    </Dialog>
  )
}

/**
 * DialogForm
 */
type DialogFormProps = {
  mediaId: string
  type: Props["type"]
  currentFolder: string | null
  close: () => void
}
const DialogForm: React.FC<DialogFormProps> = ({ mediaId, type, currentFolder, close }) => {
  const { _ } = useDictionary(dictionary())
  const parentId =
    useMediasStore((state) => (type === "folder" ? D.get(state.folders, mediaId) : D.get(state.files, mediaId)))
      ?.parentId ?? null
  const [selected, setSelected] = React.useState<string | null>(currentFolder)
  const onSubmit = async (nextParentId?: string) => {
    const folderId = nextParentId ?? selected
    close()
    const toadId = toast.loading(_("progress"))
    const { error } = await (type === "folder"
      ? updateMediasFolder(mediaId, { parentId: folderId })
      : updateMediasFile(mediaId, { folderId }))
    if (error) return toast.error(_("error"), { id: toadId })
    toast.success(_("success"), { id: toadId })
  }

  const folders = useMediasStore((state) => D.values(state.folders))
  const disabledIds = React.useMemo(
    () => (type === "folder" ? [...getChildrenFolderIds(mediaId, folders), mediaId] : []),
    [mediaId, folders]
  )
  const submitDisabled = selected === parentId || disabledIds.includes(selected ?? "")

  return (
    <>
      <SelectFolder current={currentFolder} {...{ selected, setSelected, onSubmit, disabledIds }} />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <Button
          onClick={(e) => {
            e.stopPropagation()
            onSubmit()
          }}
          disabled={submitDisabled}
        >
          {_("submit")}
        </Button>
      </DialogFooter>
    </>
  )
}
