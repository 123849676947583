import { Dialog } from "@/components/ui/Dialog"
import { useDictionary } from "@/dictionaries/hooks"
import { ContentItem } from "@/store/contents/localizers"
import { ItemFormDispatcher } from "../components/content"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * UpdateSeoDialogs
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: ContentItem | false
}
export const UpdateItemDialog: React.FC<Props> = ({ open, onOpenChange, item }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("update-item-dialog.title", { name: item ? _(`content.items.${item.type}.title`) : "" })}
      description={item ? _(`content.items.${item.type}.description`) : undefined}
      className="sm:max-w-xl"
    >
      {item !== false && <ItemFormDispatcher item={item} close={() => onOpenChange(false)} />}
    </Dialog>
  )
}
