import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSelect,
  FormSubmit,
  FormSwitch,
  FormTiptap,
  FormToggle,
  FormTranslationContext,
  FormTranslationTabs,
} from "@/components/form"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/radix/ui/button"
import Dialog from "@/components/radix/ui/dialog"
import { contextMapper, createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { useForm } from "use-a11y-form"
import { proseStyle } from "../../frontend/proseStyle"
import { useCornerOptions } from "../../hooks/useCornerOptions"
import { useSchemeOptions } from "../../hooks/useSchemeOptions"
import { useSideOptions } from "../../hooks/useSideOptions"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"
import { resetAllStoresAndReload } from "@/store"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)
const formDictionary = createContextMapper("components", "cms", "content", "form")

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _form } = useDictionary(formDictionary())

  const {
    id: contextKey,
    actions: { updateContentItem },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: React.useMemo(
      () => ({
        image: item.props.image,
        imageSide: item.props.imageSide,
        scheme: item.props.scheme,
        fixeHeight: item.props.fixeHeight,
        titleLevel: `${item.props.titleLevel}`,
        cornerDecoration: item.props.cornerDecoration,
        cornerClip: item.props.cornerClip,
        displayNext: item.props.displayNext,
        translations: D.map(languagesById, (language) => ({
          languageId: language.id,
          title: translate(item, language)?.props.title ?? "",
          secondary: translate(item, language)?.props.secondary ?? "",
          description: translate(item, language)?.props.description ?? "",
          linkText: translate(item, language)?.props.linkText ?? "",
          linkUrl: translate(item, language)?.props.linkUrl ?? "",
        })),
      }),
      []
    ),
    onSubmit: async ({ values }) => {
      const payload: FormPayload<ItemType> = {
        props: {
          ...D.selectKeys(values, [
            "image",
            "scheme",
            "fixeHeight",
            "imageSide",
            "cornerDecoration",
            "cornerClip",
            "displayNext",
          ]),
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: values.image ? [values.image] : [],
      }
      const { error, code } = await updateContentItem(item.id, payload)
      if (!error) {
        toast.success(_("success"))
        return close()
      } else if (code === "VALIDATION_FAILURE") {
        return _form(code)
      } else if (code === "RESOURCE_NOT_FOUND" || code === "FETCH_ERROR") {
        toast.error(_form(code))
        return close()
      }
      resetAllStoresAndReload()
    },
  })

  const titleLevelOptions = useTitleLevelOptions()
  const sideOptions = useSideOptions()
  const schemeOptions = useSchemeOptions()
  const cornerOptions = useCornerOptions()

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />

      <FormTranslationTabs context={false}>
        {(language) => (
          <div className='grid gap-6' key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput label={_form("title-label")} name='title' placeholder={_form("title-placeholder")} />
              <FormInput
                label={_form("secondary-label")}
                name='secondary'
                placeholder={_form("secondary-placeholder")}
              />
              <FormTiptap label={_form("description-label")} name='description' prose={cx(proseStyle, "bg-white")} />
              <FormInput label={_form("cta-text-label")} name='linkText' placeholder={_form("cta-text-placeholder")} />
              <FormInput
                label={_form("cta-url-label")}
                name='linkUrl'
                type='url'
                placeholder={_form("cta-url-placeholder")}
              />
            </FormTranslationContext>
            <FormMediasImage label={_form("image-label")} name='image' contextKey={contextKey} />
            <FormHeader>
              <FormHeader.Title>{_form("more-options-title")}</FormHeader.Title>
              <FormHeader.Description>{_form("more-options-description")}</FormHeader.Description>
            </FormHeader>
            <FormSelect label={_form("title-level-label")} name='titleLevel' options={titleLevelOptions} />
            <FormSelect label={_form("side-label")} name='imageSide' options={sideOptions} />
            <div className='grid grid-cols-2 gap-6'>
              <FormToggle
                label={_form("corner-decoration-label")}
                name='cornerDecoration'
                options={cornerOptions}
                className='grid grid-cols-2 w-max'
                variant='outline'
                icon
              />
              <FormToggle
                label={_form("corner-clip-label")}
                name='cornerClip'
                options={cornerOptions}
                className='grid grid-cols-2 w-max'
                variant='outline'
                icon
              />
            </div>
            <FormSelect label={_form("scheme-label")} name='scheme' options={schemeOptions} />
            <FormSwitch label={_form("fixe-height-label")} name='fixeHeight' />
            <FormSwitch label={_form("display-next-label")} name='displayNext' />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className='sm:justify-start'>
        <Dialog.Close asChild>
          <Button variant='secondary'>{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
