import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/radix/ui/card"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { resetPassword } from "@/store/auth/actions"

/**
 * dictionary src/dictionaries/en/pages/reset-password.json
 */
const dictionary = createContextMapper("pages", "reset-password")

/**
 * Page: ResetPassword
 */
const ResetPassword: React.FC<{ token: string }> = ({ token }) => {
  const { _ } = useDictionary(dictionary())
  const [invalidToken, setInvalidToken] = React.useState(false)
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      password: "",
      confirm: "",
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      password: [(field) => N.gte(S.length(S.trim(field)), 8) || "password-length"],
      confirm: [(field, { password }) => field === password || "password-confirm"],
    }),

    onSubmit: async ({ values: { password } }) => {
      if (!form.isValid) return "VALIDATION_FAILURE"
      const data = {
        password,
        token,
      }
      const response = await resetPassword(data)
      if (!response.error) {
        toast.success(_("toast-success"))
        navigate("/dashboard")
      } else if (response.code === "INVALID_TOKEN" || response.code === "TOKEN_EXPIRED") setInvalidToken(true)
      else return response.code
    },
  })

  return (
    <div className='flex justify-center items-center w-full min-h-screen p-8 bg-secondary'>
      <Card className='w-full max-w-xs'>
        {invalidToken ? (
          <>
            <CardHeader>
              <CardTitle>{_("title")}</CardTitle>
            </CardHeader>
            <CardContent className='grid gap-4'>
              <p className='text-sm font-light'>{_("INVALID_TOKEN")}</p>
              <Button onClick={() => navigate("/forgot-password")}>{_("forgot-password")}</Button>
              <Button variant='ghost' onClick={() => navigate("/")}>
                {_("back")}
              </Button>
            </CardContent>
          </>
        ) : (
          <>
            <CardHeader>
              <CardTitle>{_("title")}</CardTitle>
              <CardDescription>{_("secondary")}</CardDescription>
            </CardHeader>
            <CardContent>
              <Form form={form} className='grid gap-4'>
                <FormAssertive />
                <FormInput
                  label={_("password-label")}
                  name='password'
                  type='password'
                  placeholder={_("password-placeholder")}
                />
                <FormInput
                  label={_("confirm-label")}
                  name='confirm'
                  type='password'
                  placeholder={_("confirm-placeholder")}
                />
                <FormSubmit>{_("submit")}</FormSubmit>
                <Button variant='ghost' onClick={() => navigate("/")}>
                  {_("back")}
                </Button>
              </Form>
            </CardContent>
          </>
        )}
      </Card>
    </div>
  )
}
export default ResetPassword
