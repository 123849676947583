import { Form, FormAssertive, FormInput, FormSelect, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { useDictionary } from "@/dictionaries/hooks"
import { createLanguage } from "@/store/languages/actions"
import localeCodes from "locale-codes"

/**
 * LanguageCreateDialog
 * dictionary src/dictionaries/en/components/dialogs/language-create-dialog.json
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
}
export const LanguageCreateDialog: React.FC<Props> = ({ open, onOpenChange }) => {
  const { _ } = useDictionary("components.dialogs.language-create-dialog")
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      <DialogForm close={() => onOpenChange(false)} />
    </Dialog>
  )
}

/**
 * DialogForm
 * dictionary src/dictionaries/en/components/dialogs/language-create-dialog.json
 */
const DialogForm: React.FC<{ close: () => void }> = ({ close }) => {
  const { _ } = useDictionary("components.dialogs.language-create-dialog")
  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      name: "",
      code: "",
      locale: "",
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      name: [min(1, "name-required")],
      code: [min(1, "code-required"), (field) => !!localeCodes.getByTag(field) || "code-exists"],
      locale: [min(1, "locale-required"), (field) => !!localeCodes.getByTag(field) || "locale-exists"],
    }),
    onSubmit: async ({ values: { name, code, locale } }) => {
      if (!form.isValid) return "VALIDATION_FAILURE"
      const data = {
        name,
        code: S.toLowerCase(code),
        locale,
      }
      const response = await createLanguage(data)
      if (!response.error) {
        toast.success(_("success"))
        close()
      }
      if (response.code === "INVALID_API_TOKEN" || response.code === "INVALID_AUTH_SESSION")
        return window.location.reload()
      return response.code
    },
  })
  const localeOptions = React.useMemo(
    () =>
      pipe(
        localeCodes.all,
        A.filter((locale) => compareCodeToLocale(form.values.code, locale)),
        A.map(({ tag, name, location }) => ({
          label: (
            <>
              <b className='inline-flex w-12'>{tag}</b> {name} {location}
            </>
          ),
          value: tag,
        }))
      ),
    [form.values.code]
  )

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormInput label={_("name-label")} name='name' placeholder={_("name-placeholder")} />
      <FormInput label={_("code-label")} name='code' placeholder={_("code-placeholder")} />
      <FormSelect
        label={_("locale-label")}
        name='locale'
        placeholder={_("locale-placeholder")}
        options={localeOptions}
        disabled={A.isEmpty(localeOptions)}
      />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}

/**
 * helpers
 */
const compareCodeToLocale = (code: string, locale: localeCodes.ILocale) => {
  const splited = S.split(locale.tag, "-")
  const localeCode = N.gte(splited.length, 2) ? A.getUnsafe(splited, 0).toUpperCase() : undefined
  if (G.isUndefined(localeCode)) return false
  return localeCode === code.toUpperCase()
}
