import { byId } from "@/app/fns/byId"
import * as Medias from "@/services/medias/service"
import { mediasStore } from "."
import { getChildrenFolderIds, isMediaInFolder, isMediaInRoot } from "./helpers"
import { MediasFile, MediasFolder, localizeMediaFile, localizeMediaFolder } from "./localizers"

/**
 * initMedias
 */
export const initMedias = async () => {
  const { data, error, code } = await Medias.service.folders.index()
  if (!error) mediasStore.evolve({ folders: byId(data.folders, localizeMediaFolder), initDone: true })
  return { error, code } as const
}

/**
 * getMediasRoot
 */
export const getMediasRoot = async () => {
  const { data, error, code } = await Medias.service.index()
  if (!error) {
    mediasStore.evolve({
      folders: flow(D.reject(isMediaInRoot), D.merge(byId(data.folders, localizeMediaFolder))),
      files: flow(D.reject(isMediaInRoot), D.merge(byId(data.files, localizeMediaFile))),
    })
  }
  return { error, code } as const
}

/**
 * getMediasFolders
 */
export const getMediasFolders = async () => {
  const { data, error, code } = await Medias.service.folders.index()
  if (!error) mediasStore.evolve({ folders: byId(data.folders, localizeMediaFolder) })
  return { error, code } as const
}

/**
 * getMediasFolder
 */
export const getMediasFolder = async (folderId: string) => {
  const { data, error, code } = await Medias.service.folders.read(folderId)
  if (!error)
    mediasStore.evolve({
      folders: flow(
        D.reject(isMediaInFolder(folderId)),
        D.merge(byId([data.folder, ...data.folder.folders], localizeMediaFolder))
      ),
      files: flow(D.reject(isMediaInFolder(folderId)), D.merge(byId(data.folder.files, localizeMediaFile))),
    })
  return { error, code } as const
}

/**
 * createMediasFolder
 */
export const createMediasFolder = async (payload: Medias.Payload["folders"]["create"]) => {
  const { data, error, code } = await Medias.service.folders.create(payload)
  if (!error) mediasStore.evolve({ folders: D.set(data.folder.id, localizeMediaFolder(data.folder)) })
  return { error, code } as const
}

/**
 * updateMediasFolder
 */
export const updateMediasFolder = async (folderId: string, payload: Medias.Payload["folders"]["update"]) => {
  const { data, error, code } = await Medias.service.folders.update(folderId, payload)
  if (!error) mediasStore.evolve({ folders: D.set(data.folder.id, localizeMediaFolder(data.folder)) })
  return { error, code } as const
}

/**
 * deleteMediasFolder
 */
export const deleteMediasFolder = async (folderId: string) => {
  const { error, code } = await Medias.service.folders.delete(folderId)
  if (!error) {
    const ids = [...getChildrenFolderIds(folderId, D.values(mediasStore.current.folders)), folderId]
    mediasStore.set({
      folders: D.reject(mediasStore.current.folders, ({ id }) => A.includes(ids, id)) as ById<MediasFolder>,
      files: D.reject(mediasStore.current.files, ({ folderId }) => A.includes(ids, folderId)) as ById<MediasFile>,
    })
  }
  return { error, code } as const
}

/**
 * createMediasFile
 */
export const createMediasFile = async (payload: Medias.Payload["files"]["create"]) => {
  const { data, error, code } = await Medias.service.files.create(payload)
  if (!error) mediasStore.evolve({ files: D.set(data.file.id, localizeMediaFile(data.file)) })
  return { error, code } as const
}

/**
 * getMediasFile
 */
export const getMediasFile = async (fileId: string) => {
  const { data, error, code } = await Medias.service.files.read(fileId)
  if (!error) mediasStore.evolve({ files: D.set(data.file.id, localizeMediaFile(data.file)) })
  return { error, code } as const
}

/**
 * updateMediasFile
 */
export const updateMediasFile = async (fileId: string, payload: Medias.Payload["files"]["update"]) => {
  const { data, error, code } = await Medias.service.files.update(fileId, payload)
  if (!error) mediasStore.evolve({ files: D.set(fileId, localizeMediaFile(data.file)) })
  return { error, code } as const
}

/**
 * cropMediasImage
 */
export const cropMediasImage = async (fileId: string, payload: Medias.Payload["files"]["crop"]) => {
  const { data, error, code } = await Medias.service.files.crop(fileId, payload)
  if (!error) mediasStore.evolve({ files: D.set(fileId, localizeMediaFile(data.file)) })
  return { error, code } as const
}

/**
 * cropMediasImage
 */
export const copyAndCropMediasImage = async (fileId: string, payload: Medias.Payload["files"]["crop"]) => {
  const { data, error, code } = await Medias.service.files.copyAndCrop(fileId, payload)
  if (!error) mediasStore.evolve({ files: D.set(data.file.id, localizeMediaFile(data.file)) })
  return { error, code } as const
}

/**
 * uncropMediasImage
 */
export const uncropMediasImage = async (fileId: string) => {
  const { data, error, code } = await Medias.service.files.uncrop(fileId)
  if (!error) mediasStore.evolve({ files: D.set(fileId, localizeMediaFile(data.file)) })
  return { error, code } as const
}

/**
 * deleteMediasFile
 */
export const deleteMediasFile = async (fileId: string) => {
  const { error, code } = await Medias.service.files.delete(fileId)
  if (!error) mediasStore.evolve({ files: D.deleteKey(fileId) })
  return { error, code } as const
}

/**
 * copyMediasFile
 */
export const copyMediasFile = async (fileId: string) => {
  const { data, error, code } = await Medias.service.files.copy(fileId)
  if (!error) mediasStore.evolve({ files: D.set(data.file.id, localizeMediaFile(data.file)) })
  return { error, code } as const
}
