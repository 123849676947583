import {
  Form,
  FormAssertive,
  FormInput,
  FormSelect,
  FormSubmit,
  toSelectOptions,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { updateLanguage } from "@/store/languages/actions"
import { Language } from "@/store/languages/localizers"
import localeCodes from "locale-codes"

/**
 * dictionary src/dictionaries/en/components/dialogs/language-edit-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "language-edit-dialog")

/**
 * LanguageEditDialog
 *
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: Language | false
}
export const LanguageEditDialog: React.FC<Props> = ({ item, open, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      {item !== false && <DialogForm close={() => onOpenChange(false)} item={item} />}
    </Dialog>
  )
}

/**
 * LanguageForm
 */
const DialogForm: React.FC<{ item: Language; close: () => void }> = ({ item: language, close }) => {
  const { _ } = useDictionary(dictionary())

  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      name: language.name,
      code: language.code,
      locale: language.locale,
      status: language.status,
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      name: [min(1, "name-required")],
      code: [min(1, "code-required"), (field) => !!localeCodes.getByTag(field) || "code-exists"],
      locale: [min(1, "locale-required"), (field) => !!localeCodes.getByTag(field) || "locale-exists"],
    }),
    onSubmit: async ({ values: { name, code, locale, status } }) => {
      if (!form.isValid) return "VALIDATION_FAILURE"
      const data = {
        name,
        code: S.toLowerCase(code),
        locale,
        status,
      }
      const response = await updateLanguage(language.id, data)
      if (!response.error) {
        toast.success(_("success"))
        return close()
      } else if (response.code === "RESOURCE_NOT_FOUND") {
        toast.error(_(response.code))
        return close()
      } else if (response.code === "VALIDATION_FAILURE" || response.code === "FETCH_ERROR") return response.code
      window.location.reload()
    },
  })

  const localeOptions = React.useMemo(
    () =>
      pipe(
        localeCodes.all,
        A.filter((locale) => compareCodeToLocale(form.values.code, locale)),
        A.map(({ tag, name, location }) => ({
          label: (
            <>
              <b className='inline-flex w-12'>{tag}</b> {name} {location}
            </>
          ),
          value: tag,
        }))
      ),
    [form.values.code]
  )

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormInput label={_("name-label")} name='name' placeholder={_("name-placeholder")} />
      <FormInput label={_("code-label")} name='code' placeholder={_("code-placeholder")} />
      <FormSelect
        label={_("locale-label")}
        name='locale'
        placeholder={_("locale-placeholder")}
        options={localeOptions}
        disabled={A.isEmpty(localeOptions)}
      />
      <FormSelect
        label={_("status-label")}
        name='status'
        placeholder={_("status-placeholder")}
        options={toSelectOptions(["active", "deleted"], _ as (ctx: string) => string, "status")}
      />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}

/**
 * helpers
 */
const compareCodeToLocale = (code: string, locale: localeCodes.ILocale) => {
  const splited = S.split(locale.tag, "-")
  const localeCode = N.gte(splited.length, 2) ? A.getUnsafe(splited, 0).toUpperCase() : undefined
  if (G.isUndefined(localeCode)) return false
  return localeCode === code.toUpperCase()
}
