import * as DialogPrimitive from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import { SrOnly } from "./sr-only"
import { useDictionary } from "@/dictionaries/hooks"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/layout.json
 */
const dictionary = createContextMapper("components", "layout")

/**
 * Dialog
 */
const Dialog = DialogPrimitive.Root

/**
 * DialogTrigger
 */
const DialogTrigger = DialogPrimitive.Trigger

/**
 * DialogPortal
 */
const DialogPortal = DialogPrimitive.Portal

/**
 * DialogClose
 */
const DialogClose = DialogPrimitive.Close

/**
 * DialogOverlay
 */
const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cx(
      "fixed inset-0 z-50 p-2 md:p-8",
      "bg-background/80 backdrop-blur-sm",
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      "overflow-y-auto max-h-screen grid place-items-center",
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

/**
 * DialogContent
 */
type DialogContentProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
  hideClose?: true
  animate?: boolean
  size?: string
}
const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, DialogContentProps>(
  ({ className, hideClose = false, size = "max-w-lg", animate = true, children, ...props }, ref) => {
    const { _ } = useDictionary(dictionary())
    return (
      <DialogPortal>
        <DialogOverlay>
          <DialogPrimitive.Content
            ref={ref}
            className={cx(
              "@container/dialog relative z-50 grid w-full p-4 lg:p-6 pt-6 gap-4 sm:rounded-lg",
              "border bg-background shadow-lg",
              animate &&
                cx(
                  "duration-200",
                  "data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95",
                  "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
                ),
              size,
              className
            )}
            {...props}
          >
            {children}
            {!hideClose && (
              <DialogPrimitive.Close className='absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'>
                <X size={16} aria-hidden />
                <SrOnly>{_("close")}</SrOnly>
              </DialogPrimitive.Close>
            )}
          </DialogPrimitive.Content>
        </DialogOverlay>
      </DialogPortal>
    )
  }
)
DialogContent.displayName = DialogPrimitive.Content.displayName

/**
 * DialogHeader
 */
const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cx("flex flex-col space-y-1.5 text-center sm:text-left", className)} {...props} />
)
DialogHeader.displayName = "DialogHeader"

/**
 * DialogTitle
 */
const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cx("text-lg font-semibold leading-none tracking-tight", className)}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

/**
 * DialogDescription
 */
const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description ref={ref} className={cx("text-sm text-muted-foreground", className)} {...props} />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

/**
 * DialogFooter
 */
const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cx(
      "flex flex-col-reverse @sm/dialog:flex-row @sm/dialog:justify-end @sm/dialog:space-x-2 gap-2 @sm/gap-0",
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

/**
 * default export Dialog
 */
export default Object.assign(Dialog, {
  Portal: DialogPortal,
  Overlay: DialogOverlay,
  Close: DialogClose,
  Trigger: DialogTrigger,
  Content: DialogContent,
  Header: DialogHeader,
  Footer: DialogFooter,
  Title: DialogTitle,
  Description: DialogDescription,
})
