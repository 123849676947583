import { useDocumentVisibility } from "@/app/hooks/useDocumentVisibility"
import useInterval from "@/app/hooks/useInterval"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Grid } from "@/components/layout/dashboard/Collection"
import Card from "@/components/radix/ui/card"
import { StatsVisitByDays } from "@/components/trackings/components/StatsVisitByDays"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { service } from "@/services/trackings/service"
import millify from "millify"

/**
 * dictionary src/dictionaries/en/pages/dashboard/index.json
 */
const dictionary = createContextMapper("pages", "dashboard", "index")

/**
 * Page: dashboard/index
 */
const DashboardIndex: React.FC = () => {
  return (
    <PageWrapper>
      <PageHeader breadcrumbs={[]} />
      <PageContent>
        <Grid view='grid'>
          <LastVisits />
          <Card className='min-h-52'></Card>
          <Card className='min-h-52'></Card>
        </Grid>
      </PageContent>
    </PageWrapper>
  )
}
export default DashboardIndex

/**
 * LastVisits
 */
export const LastVisits: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const refreshDelay = 30000
  const isVisible = useDocumentVisibility()
  const [data, setData] = React.useState<LastVisitsStats>({
    today: 0,
    last7Days: 0,
    lastMonth: 0,
  })
  const updateData = async () => {
    const { error, data } = await service.stats()
    if (error) return
    setData({
      today: D.get(data.stats, "today") ?? 0,
      last7Days: D.get(data.stats, "last7Days") ?? 0,
      lastMonth: D.get(data.stats, "lastMonth") ?? 0,
    })
  }
  React.useEffect(() => {
    updateData()
  }, [])
  useInterval(updateData, isVisible ? refreshDelay : null)
  const interval = React.useMemo(() => {
    const now = new Date()
    return { from: T.sub(now, { days: 7 }), to: now }
  }, [])

  return (
    <Card>
      <Card.Header>
        <Card.Title>{_("last-visits.title")}</Card.Title>
      </Card.Header>
      <Card.Content className='gap-6'>
        <div className='w-full aspect-video'>
          <StatsVisitByDays
            hideAxis
            hideGrid
            strokeWidth={3}
            color='hsl(var(--primary))'
            live={isVisible}
            interval={interval}
          />
        </div>
        <div className='grid grid-cols-3 gap-6'>
          <LastVisitsHighlight content={millify(data.today)} description={_("last-visits.today")} />
          <LastVisitsHighlight content={millify(data.last7Days)} description={_("last-visits.last-7-days")} />
          <LastVisitsHighlight content={millify(data.lastMonth)} description={_("last-visits.last-month")} />
        </div>
      </Card.Content>
    </Card>
  )
}
export const LastVisitsHighlight: React.FC<{ content: string; description: string }> = ({ content, description }) => {
  return (
    <div className='flex flex-col justify-center items-center aspect-square border rounded-md shadow'>
      <span className='text-2xl @xs/card:text-3xl @sm/card:text-4xl @md/card:text-5xl @lg/card:text-6xl font-medium text-primary'>
        {content}
      </span>
      <span className='text-[10px] @xs/card:text-xs @lg/card:text-sm text-foreground/50'>{description}</span>
    </div>
  )
}
type LastVisitsStats = { today: number; last7Days: number; lastMonth: number }
