import { Button } from "@/components/radix/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/radix/ui/dropdown-menu"
import { useDictionary } from "@/dictionaries/hooks"
import { Filter } from "lucide-react"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { SrOnly } from "@/components/radix/ui/sr-only"

/**
 * ToolbarFilters
 */
type ToolbarFiltersProps = {
  // dictionary: string
  // filters: Partial<Record<string, boolean>>
  // setFilters: (filters: Partial<Record<string, boolean>>) => void
  // toggle: (field: string) => void
  // setFalse: (field: string) => void
  // setTrue: (field: string) => void
  // clearOne: (field: string) => void
  // clear: () => void
  // list: string[]
}
export const ToolbarFilters: React.FC<React.PropsWithChildren<ToolbarFiltersProps>> = ({ children }) => {
  const { _ } = useDictionary("components.layout.toolbar")
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" icon>
          <Filter aria-hidden />
          <SrOnly>{_("filters-button")}</SrOnly>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" side="bottom" className="w-56">
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

/**
 * ToolbarFiltersToggle
 */
type ToolbarFiltersButtonProps = React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
  active?: boolean
  inset?: boolean
}
export const ToolbarFiltersButton: React.FC<ToolbarFiltersButtonProps> = ({ ...props }) => {
  return <DropdownMenuItem {...props} />
}
