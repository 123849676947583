import { Switch } from "@/components/radix/ui/switch"
import { FormGroup, FormGroupError, FormGroupInfo, FormLabel, useFieldContext } from "."

/**
 * FormSwitch
 */
type Props = SelectInputProps & {
  label?: string
  name?: string
  info?: string | false
  className?: ClassName
}
export const FormSwitch: React.FC<Props> = ({ label, name, info, disabled, className, ...props }) => {
  return (
    <FormGroup name={name} className={className}>
      <div className="flex justify-between items-center">
        <FormLabel>{label}</FormLabel>
        <SwitchInput {...props} disabled={disabled} />
      </div>
      <FormGroupError />
      {info !== false && <FormGroupInfo>{info}</FormGroupInfo>}
    </FormGroup>
  )
}

type SelectInputProps = {
  placeholder?: string
  className?: ClassName
  disabled?: boolean
}
const SwitchInput: React.FC<SelectInputProps> = ({ disabled }) => {
  const { id, name, value, setFieldValue, disabled: ctxDisabled } = useFieldContext<boolean>()
  return (
    <Switch id={id} name={name} checked={value} onCheckedChange={setFieldValue} disabled={disabled || ctxDisabled} />
  )
}
