import { byId } from "@/app/fns/byId"
import * as Languages from "@/services/languages/service"
import { languagesStore } from "."
import { localizeLanguage } from "./localizers"

/**
 * initLanguagesStore
 */
export const initLanguagesStore = async () => {
  const { data, error, code } = await Languages.service.index()
  if (!error) languagesStore.evolve({ languages: byId(data.languages, localizeLanguage), initDone: true })
  return { error, code } as const
}

/**
 * getLanguages
 */
export const getLanguages = async () => {
  const { data, error, code } = await Languages.service.index()
  if (!error) languagesStore.evolve({ languages: byId(data.languages, localizeLanguage) })
  return { error, code } as const
}

/**
 * createLanguage
 */
export const createLanguage = async (formData: Languages.Payload["create"]) => {
  const { data, error, code } = await Languages.service.create(formData)
  if (!error) languagesStore.evolve({ languages: D.set(data.language.id, localizeLanguage(data.language)) })
  return { error, code } as const
}

/**
 * getLanguage
 */
export const getLanguage = async (id: Uuid) => {
  const { data, error, code } = await Languages.service.read(id)
  if (!error) languagesStore.evolve({ languages: D.set(data.language.id, localizeLanguage(data.language)) })
  return { error, code } as const
}

/**
 * updateLanguage
 */
export const updateLanguage = async (id: Uuid, formData: Languages.Payload["update"]) => {
  const { data, error, code } = await Languages.service.update(id, formData)
  if (!error) languagesStore.evolve({ languages: D.set(data.language.id, localizeLanguage(data.language)) })
  return { error, code } as const
}

/**
 * deleteLanguage
 */
export const deleteLanguage = async (id: Uuid) => {
  const { error, code } = await Languages.service.delete(id)
  if (!error) languagesStore.evolve({ languages: D.deleteKey(id) })
  return { error, code } as const
}
