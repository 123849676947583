import useInterval from "@/app/hooks/useInterval"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { service } from "@/services/trackings/service"
import { eachMonthOfInterval, endOfMonth, formatISO, startOfMonth } from "date-fns"
import millify from "millify"
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts"
import { parseIsoMonth } from "./helpers"
import { useStatsTheme } from "./hooks"
import { StatRecord, StatsProps } from "./types"

const reloadTime = 30000

/**
 * StatsVisitByMonths
 */
export const StatsVisitByMonths: React.FC<StatsProps> = ({
  trackingId,
  interval,
  setIsLoading = () => true,
  live = false,
}) => {
  const { colors } = useStatsTheme()
  const format = useDateFnsLocaleFormat()
  const [data, setData] = React.useState<StatRecord[]>([])
  const updateData = async () => {
    setIsLoading(true)
    const params = interval.from
      ? { from: formatISO(startOfMonth(interval.from)), to: formatISO(endOfMonth(interval.to)) }
      : { to: formatISO(endOfMonth(interval.to)) }
    const { error, data } = G.isNullable(trackingId)
      ? await service.byMonth(params)
      : await service.trackings.byMonth(trackingId, params)
    setIsLoading(false)
    if (error) return
    const start =
      interval.from ??
      pipe(
        D.keys(data.stats),
        A.map(parseIsoMonth),
        A.sortBy((d) => d),
        A.head
      ) ??
      new Date()
    const month = eachMonthOfInterval({ start, end: interval.to })
    const populated = A.map<Date, StatRecord>(month, (date) => {
      const key = format(date, "yyyy-MM")
      return { name: key, value: data.stats[key] ?? 0 }
    })
    setData(populated)
  }

  React.useEffect(() => {
    updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval.from, interval.to])
  useInterval(updateData, live ? reloadTime : null)

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray='3 3' stroke='hsl(var(--muted))' />
        <XAxis
          dataKey='name'
          stroke='hsl(var(--muted-foreground))'
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: string) => format(parseIsoMonth(value), "LLL")}
        />
        <YAxis
          dataKey='value'
          stroke='hsl(var(--muted-foreground))'
          width={40}
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: number) => millify(value)}
        />
        <Tooltip content={<StatsVisitByMonthsTooltip />} cursor={{ fill: "hsl(var(--muted))" }} />
        <Bar dataKey='value' fill={colors[4]} />
      </BarChart>
    </ResponsiveContainer>
  )
}

/**
 * StatsVisitByMonthsTooltip
 */
const StatsVisitByMonthsTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  const format = useDateFnsLocaleFormat()
  const { colors } = useStatsTheme()
  if (!(active && payload && payload.length)) return null
  const {
    value,
    payload: { name },
  } = A.getUnsafe(payload, 0)
  const date = parseIsoMonth(name)
  return (
    <div className='rounded-md border bg-popover px-3 py-1.5 text-[0.75em] text-popover-foreground shadow-md'>
      <b style={{ color: colors[4] }}>{format(date, "LLLL yyyy")}: </b> <span>{`${value}`}</span>
    </div>
  )
}
