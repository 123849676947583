import { AccountDialog } from "@/components/dialogs/AccountDialog"
import { ProfileDialog } from "@/components/dialogs/ProfileDialog"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/radix/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/radix/ui/dropdown-menu"
import { i18nConfig } from "@/config/i18n"
import { useDictionary } from "@/dictionaries/hooks"
import { signOut } from "@/store/auth/actions"
import { useMe } from "@/store/auth/hooks"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { Globe, LogOut, User, UserCog, UserSquare } from "lucide-react"

/**
 * UserDropdown
 */
type Props = {
  expanded?: boolean
  beforeClick?: () => true
  align?: DropdownMenuPrimitive.MenuContentProps["align"]
  side?: DropdownMenuPrimitive.MenuContentProps["side"]
}
export const UserDropdown: React.FC<Props> = ({
  expanded,
  beforeClick = () => true,
  align = "end",
  side = "right",
}) => {
  const me = useMe()
  const { _, language: current, changeLanguage } = useDictionary("components.user-dropdown")
  const languages = [...i18nConfig.locales]
  const [accountDialog, setAccountDialog] = React.useState(false)
  const [profileDialog, setProfileDialog] = React.useState(false)
  const fullName = S.trim(`${me?.profile.firstname ?? ""}`)
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="group/button flex items-center gap-4 text-sm outline-none">
            <span className="inline-flex items-center justify-center rounded-full [&_svg]:w-4 [&_svg]:h-4 ring-offset-background transition-colors group-focus-visible/button:outline-none group-focus-visible/button:ring-2 group-focus-visible/button:ring-ring group-focus-visible/button:ring-offset-2">
              <Avatar>
                <AvatarImage src={me?.profile.image ?? undefined} alt={me?.profile.firstname} />
                <AvatarFallback>
                  <User aria-hidden />
                </AvatarFallback>
              </Avatar>
              <span className="sr-only">{_("button")}</span>
            </span>
            {false && expanded && S.isNotEmpty(fullName) && (
              <span className="text-sm font-light whitespace-nowrap">{fullName}</span>
            )}
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align={align} side={side} className="w-56">
          <DropdownMenuItem onClick={() => beforeClick() && setAccountDialog(true)}>
            <UserCog aria-hidden />
            {_("account")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => beforeClick() && setProfileDialog(true)}>
            <UserSquare aria-hidden />
            {_("profile")}
          </DropdownMenuItem>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Globe aria-hidden />
              {_("languages")}
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {A.map(languages, (language) => (
                  <DropdownMenuCheckboxItem
                    key={language}
                    checked={language === current}
                    onCheckedChange={() => beforeClick() && changeLanguage(language)}
                  >
                    {_(`languages-${language}`)}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuItem onClick={signOut}>
            <LogOut aria-hidden />
            {_("sign-out")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AccountDialog open={accountDialog} onOpenChange={setAccountDialog} />
      <ProfileDialog open={profileDialog} onOpenChange={setProfileDialog} />
    </>
  )
}
