import { D } from "@mobily/ts-belt"
import usePersistedState from "./usePersistedState"

export const useFilterable = <T extends Record<string, unknown>, F = Record<string, (item: T) => boolean>>(
  storageKey: string,
  fields: F,
  initial: Partial<Record<keyof F, boolean>>
) => {
  const [filters, setFilters] = usePersistedState(initial, storageKey, sessionStorage)

  const isActive = React.useCallback((field: keyof F) => D.get(filters, field) === true, [filters])
  const isInactive = React.useCallback((field: keyof F) => D.get(filters, field) === false, [filters])

  const toggle = (field: keyof F) => {
    const current = D.get(filters, field)
    const key = field as string
    if (G.isNullable(current) || current === false) setFilters(D.set(key, true))
    else setFilters(D.set(filters, key, false))
  }
  const setFalse = (field: keyof F) => {
    const current = D.get(filters, field)
    const key = field as string
    if (G.isNullable(current) || current === true) setFilters(D.set(key, false))
  }
  const setTrue = (field: keyof F) => {
    const current = D.get(filters, field)
    const key = field as string
    if (G.isNullable(current) || current === false) setFilters(D.set(key, true))
  }
  const clearOne = (field: keyof F) => {
    const current = D.get(filters, field)
    const key = field as string
    if (G.isNotNullable(current)) setFilters((filters) => D.filterWithKey(filters, (k) => k === key))
  }
  const clear = () => {
    setFilters({})
  }
  const list = React.useMemo(() => D.keys(fields as Record<string, unknown>), [fields])
  const filterBy = React.useCallback(
    (items: T[]) =>
      A.filter(items, (item) =>
        A.some(D.keys(filters), (key) => {
          const fn = D.get(fields, key) as Option<(item: T) => boolean>
          const compare = D.get(filters, key)
          return G.isNotNullable(fn) ? fn(item) !== compare : true
        })
      ),
    [filters, fields]
  )

  return [
    { filters, setFilters, list, toggle, setFalse, setTrue, clearOne, clear, isActive, isInactive },
    filterBy,
  ] as const
}

/**
 * types
 */
export type Filterable<T extends Record<string, unknown>, K extends keyof T> = ReturnType<typeof useFilterable<T, K>>[0]
