import usePersistedState from "@/app/hooks/usePersistedState"
import { useSelectItem } from "@/app/hooks/useSelect"
import { ArticleCategoryCreateDialog } from "@/components/dialogs/ArticleCategoryCreateDialog"
import { ArticleCategoryUpdateDialog } from "@/components/dialogs/ArticleCategoryUpdateDialog"
import { Menu } from "@/components/layout/dashboard/Collection"
import { useMenuContext } from "@/components/layout/dashboard/Collection/Menu"
import { Confirm, confirmSafeDictionary, useConfirm } from "@/components/ui/Confirm"
import { useDialog } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { deleteArticleCategory } from "@/store/articles/actions"
import { useArticlesById } from "@/store/articles/hooks"
import { ArticleCategory } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { Edit, PlusSquare, Scan, Trash } from "lucide-react"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles", "categories")

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const articlesById = useArticlesById()
  const displayName = (id: string) => {
    const seo = D.get(articlesById, id)?.seo
    const title = seo ? t(seo).title : ""
    return S.isEmpty(S.trim(title)) ? _("no-title") : title
  }

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>("grid", "article-categories-view", sessionStorage)

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteArticleCategory,
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: deleteArticleCategory,
    dictionary: confirmSafeDictionary(dictionary("delete-selection-confirm")),
  })

  // dialogs
  const { setItem: create, ...createProps } = useDialog<void>()
  const { setItem: update, ...updateProps } = useDialog<ArticleCategory>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        create,
        update,
        confirmDelete,
        confirmDeleteSelection,
      }}
    >
      {children}
      <ArticleCategoryCreateDialog {...createProps} />
      <ArticleCategoryUpdateDialog {...updateProps} />
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
type ContextMenuProps = { category: ArticleCategory }
export const ContextMenu: React.FC<ContextMenuProps> = ({ category }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { confirmDelete, confirmDeleteSelection, create, update } = usePageContext()
  const { type } = useMenuContext()
  const { isSelected, toggleSelection } = useSelectItem(Context, category.id)
  const { id } = category
  const isContextMenu = type === "context-menu"

  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        <Scan aria-hidden />
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => update(category)}>
        <Edit aria-hidden />
        {_("update")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={create}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={confirmDeleteSelection}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  create: () => void
  update: (article: ArticleCategory) => void
  confirmDelete: (value: string) => void
  confirmDeleteSelection: () => void
}
