import { FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "@/components/form"
import { Menu } from "@/components/layout/dashboard/Collection"
import { SelectFiles } from "@/components/medias/components/selectFiles"
import { FileInfoDialog } from "@/components/medias/dialogs/FileInfoDialog"
import { Button, buttonFormField } from "@/components/radix/ui/button"
import { SrOnly } from "@/components/radix/ui/sr-only"
import AdaptHeight from "@/components/ui/AdaptHeight"
import { useDictionary } from "@/dictionaries/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { MediasFile } from "@/store/medias/localizers"
import delay from "delay"
import { ChevronLeft, ChevronRight, FileDown, FileMinus, FilePlus, Info, MoreVertical } from "lucide-react"
import { Document, DocumentProps, Page, pdfjs } from "react-pdf"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * workers
 */
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString()

/**
 * FormMediasPdf
 */
type FormMediasPdfProps = { contextKey?: string }
export const FormMediasPdf: React.FC<FormMediasPdfProps & FormFieldWrapperProps> = ({
  contextKey,
  ...wrapperProps
}) => (
  <FormFieldWrapper {...wrapperProps}>
    <FormMediasPdfInput {...{ contextKey }} />
  </FormFieldWrapper>
)

/**
 * FormMediasPdfInput
 * dictionary src/dictionaries/en/components/medias.json
 */
const FormMediasPdfInput: React.FC<FormMediasPdfProps> = ({ contextKey }) => {
  const { _ } = useDictionary(dictionary())
  const { setFieldValue, value, id } = useFieldContext<string | null>()
  const file = useMediasFile(value)
  const onRemove = () => {
    setFieldValue(null)
    delay(10).then(() => focusRef.current?.focus())
  }
  const [open, onOpenChange] = React.useState(false)

  const onSelect = (files: string[]) => {
    const file = A.head(files)
    if (G.isNullable(file)) return
    setFieldValue(file)
  }

  const [openInfo, setOpenInfo] = React.useState(false)
  const focusRef = React.useRef<HTMLButtonElement>(null)
  return (
    <div className={cx("relative")}>
      {G.isNotNullable(file) ? (
        <div>
          <PdfPreview file={file} />
          <FileInfoDialog {...{ file, openInfo, setOpenInfo }} onCloseAutoFocus={() => focusRef.current?.focus()} />
          <Menu
            menu={
              <>
                <Menu.Item onClick={() => setOpenInfo(true)}>
                  <Info aria-hidden />
                  {_("file-info")}
                </Menu.Item>
                <Menu.Item onClick={onRemove}>
                  <FileMinus aria-hidden />
                  {_("remove-file")}
                </Menu.Item>
                <Menu.Item onClick={() => onOpenChange(true)}>
                  <FileDown aria-hidden />
                  {_("change-file")}
                </Menu.Item>
              </>
            }
            type="dropdown-menu"
            align="start"
            side="left"
          >
            <Button ref={focusRef} variant="transparent" size="xxs" icon className="absolute top-2 right-2">
              <MoreVertical aria-hidden />
              <SrOnly>{_("more")}</SrOnly>
            </Button>
          </Menu>
        </div>
      ) : (
        <button
          ref={focusRef}
          onClick={() => onOpenChange(true)}
          id={id}
          type="button"
          className={cx(buttonFormField, "h-16")}
        >
          <FilePlus size={32} strokeWidth={1} aria-hidden />
          <SrOnly>{_("add-file")}</SrOnly>
        </button>
      )}
      <SelectFiles
        open={open}
        type="pdf"
        onOpenChange={onOpenChange}
        onCloseAutoFocus={() => focusRef.current?.focus()}
        current={file?.parentId ?? undefined}
        onSelect={onSelect}
        contextKey={contextKey}
      />
    </div>
  )
}

/**
 * PdfPreview
 * dictionary src/dictionaries/en/components/medias.json
 */
const PdfPreview: React.FC<{ file: MediasFile }> = ({ file }) => {
  const { _ } = useDictionary(dictionary("lightbox"))
  const [numberOfPages, setNumberOfPages] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(1)

  const onLoadSuccess: DocumentProps["onLoadSuccess"] = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages)
    setCurrentPage(1)
  }
  const changePage = (offset: number) => {
    setCurrentPage((prevPageNumber) => {
      const nextPageNumber = prevPageNumber + offset
      if (nextPageNumber < 1 || nextPageNumber > numberOfPages) return prevPageNumber
      return nextPageNumber
    })
  }
  const previous = () => changePage(-1)
  const next = () => changePage(1)

  const ref = React.useRef<HTMLDivElement>(null)
  const wrapperWidth = ref.current?.clientWidth ?? 0
  return (
    <AdaptHeight>
      <div className="relative w-full overflow-hidden rounded-md border border-input" ref={ref}>
        <Document file={file.url} onLoadSuccess={onLoadSuccess} className={"grid justify-center items-center w-full"}>
          <Page
            pageNumber={currentPage}
            renderMode="canvas"
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={wrapperWidth}
          />
        </Document>
        <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex justify-center items-center gap-2 rounded-md bg-foreground/75 text-background text-xs">
          <Button
            variant="ghost"
            size="xs"
            icon
            className="ring-offset-foreground/30 hover:bg-foreground/30 text-background hover:text-background"
            onClick={previous}
          >
            <ChevronLeft aria-hidden />
            <SrOnly>{_("previous-page")}</SrOnly>
          </Button>
          <p>{_("pages", { current: currentPage, total: numberOfPages })}</p>
          <Button
            variant="ghost"
            size="xs"
            icon
            className="ring-offset-foreground/30 hover:bg-foreground/30 text-background hover:text-background"
            onClick={next}
          >
            <ChevronRight aria-hidden />
            <SrOnly>{_("next-page")}</SrOnly>
          </Button>
        </div>
      </div>
    </AdaptHeight>
  )
}
