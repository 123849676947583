import { create } from "zustand"
import { decorateStore } from "@/app/fns/decorateStore"
import { MediasFile, MediasFolder } from "./localizers"

/**
 * types
 */
export type MediasStoreState = {
  initDone: boolean
  folders: ById<MediasFolder>
  files: Record<string, MediasFile>
}

/**
 * initialState
 */
const initialState: MediasStoreState = {
  initDone: false,
  folders: {},
  files: {},
}

/**
 * store
 */
export const mediasStore = decorateStore(initialState, create, {})
export const useMediasStore = mediasStore.use
