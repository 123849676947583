import { textPlaceholder } from "@/app/fns/String"
import { Skeleton } from "@/components/radix/ui/skeleton"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { FormPayload, ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"
import { useMediasFile } from "@/store/medias/hooks"
import { Image } from "@/components/radix/ui/image"
import { relativeProseStyle } from "../../frontend/proseStyle"
import { Hn } from "@/components/ui/Hn"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary, description, cards } = t(item).props

  return (
    <article className="p-4 @xl/card:p-8 bg-be-cultured font-museo">
      <div className="w-full max-w-[1000px] mx-auto">
        {S.isNotEmpty(S.trim(secondary)) && (
          <p className="pb-2.5 @xl/card:pb-5 text-xs @xl/card:text-base leading-normal font-semibold text-be-manatee uppercase">
            {secondary}
          </p>
        )}
        <Hn
          className="w-full max-w-sm uppercase text-[25px] @xl/card:text-[40px] leading-tight font-bold text-be-midnight"
          level={titleLevel}
        >
          {title}
          <Skeleton value={title} />
        </Hn>
        {S.isNotEmpty(S.trim(description)) && (
          <div
            className={cx(relativeProseStyle, "w-full max-w-lg text-sm @xl/card:text-base text-be-midgray")}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        <div className="flex flex-col @xl/card:grid grid-cols-3 w-full pt-[30px] gap-[25px]">
          {A.map(item.props.cards, (card) => (
            <CardItem card={D.get(cards, card) ?? emptyCard} titleLevel={titleLevel} key={card} />
          ))}
          {A.isEmpty(item.props.cards) &&
            A.map(A.range(0, 2), (index) => <CardItem card={emptyCard} titleLevel={titleLevel} key={index} />)}
        </div>
      </div>
    </article>
  )
}

const CardItem: React.FC<{ card: Card; titleLevel: number }> = ({ card, titleLevel }) => {
  const { _ } = useDictionary(dictionary())
  const { title, description, type, links } = card

  return (
    <article className="flex flex-col px-[30px] py-[40px] shadow-be-card bg-be-midnight rounded-[5px] text-white">
      <Hn className="pb-5 text-[25px] @xl/card:text-[30px] leading-tight font-bold uppercase" level={titleLevel + 1}>
        {title}
        <Skeleton value={title} />
        <Skeleton value={title} />
        <Skeleton className="w-3/5" value={title} />
      </Hn>
      {type === "button" ? (
        <>
          <p className="grow pb-[20px] text-[14px] @xl/card:text-[20px] font-semibold leading-normal">
            {description}
            <Skeleton value={description} />
            <Skeleton className="w-3/5" value={description} />
          </p>
          {A.map(D.toPairs(links), ([index, link]) => (
            <span
              key={index}
              className="inline-flex items-center justify-center w-full text-center px-[20px] py-[10px] rounded-[25px] bg-be-orient text-be-button font-bold uppercase"
            >
              {textPlaceholder(link.text, _("call-to-action"))}
            </span>
          ))}
        </>
      ) : (
        <ul className="flex flex-col gap-3">
          {A.map(D.toPairs(links), ([index, link]) => (
            <CardItemLink link={link} key={index} />
          ))}
        </ul>
      )}
    </article>
  )
}

const CardItemLink: React.FC<{ link: Card["links"][string] }> = ({ link }) => {
  const icon = useMediasFile(link.icon)
  const t = useTranslation()
  return (
    <li className="text-[14px] leading-tight">
      <span className={cx("flex items-center w-full gap-4")}>
        {G.isNotNullable(icon) && (
          <Image
            src={icon.url}
            alt={t(icon).alt}
            wrapperCx="flex justify-center items-center size-[50px] rounded-full border-2 border-be-orient"
          />
        )}
        <span>{link.text}</span>
      </span>
    </li>
  )
}

const emptyCard: Card = {
  type: "button",
  title: "",
  description: "",
  links: {
    id: {
      order: 0,
      icon: "",
      text: "",
      url: "",
      blank: false,
    },
  },
}

type Card = FormPayload<ItemType>["translations"][number]["props"]["cards"][string]
