import { Form, FormAssertive, FormInput, FormSubmit, FormTranslationTabs, useForm, validator } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { updateArticleCategory } from "@/store/articles/actions"
import { ArticleCategory } from "@/store/articles/localizers"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { FormTextarea } from "../form/FormTextarea"

/**
 * dictionary src/dictionaries/en/components/dialogs/article-category-update-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "article-category-update-dialog")

/**
 * ArticleCategoryUpdateDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: ArticleCategory | false
}
export const ArticleCategoryUpdateDialog: React.FC<Props> = ({ open, onOpenChange, item }) => {
  const { _ } = useDictionary(dictionary())

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      {item !== false && <DialogForm onOpenChange={onOpenChange} category={item} />}
    </Dialog>
  )
}
const DialogForm: React.FC<{ onOpenChange: (open: boolean) => void; category: ArticleCategory }> = ({
  onOpenChange,
  category,
}) => {
  const { _ } = useDictionary(dictionary())

  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: React.useMemo(
      () => ({
        translations: D.map(languagesById, (language) => ({
          languageId: language.id,
          name: translate(category, language)?.name ?? "",
          description: translate(category, language)?.description ?? "",
        })),
      }),
      []
    ),
    validate: validator({}),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _("VALIDATION_FAILURE")
      const { error, code } = await updateArticleCategory(category.id, { translations: D.values(values.translations) })
      if (!error) {
        toast.success(_("success"))
        return onOpenChange(false)
      }
      if (code === "VALIDATION_FAILURE" || code === "FETCH_ERROR") return _(code)
      window.location.reload()
    },
  })

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormTranslationTabs>
        {(language) => (
          <div className='grid gap-6' key={language.id}>
            <FormInput label={_("name-label")} name='name' placeholder={_("name-placeholder")} />
            <FormTextarea
              label={_("description-label")}
              name='description'
              placeholder={_("description-placeholder")}
            />
          </div>
        )}
      </FormTranslationTabs>
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
