/**
 * CollectionGrid
 */
type Props = {
  view: "grid" | "list"
} & React.ComponentProps<"div">
export const Grid = React.forwardRef<HTMLDivElement, Props>(({ className, view, ...props }, ref) => {
  return (
    <div className="@container/collection w-full max-w-screen-2xl mx-auto">
      <div
        ref={ref}
        className={cx(
          view === "grid"
            ? "grid grid-cols-1 @xl/collection:grid-cols-2 @5xl/collection:grid-cols-3 @7xl/collection:grid-cols-4 gap-4"
            : "flex flex-col divide-y-4 divide-background",
          className
        )}
        {...props}
      />
    </div>
  )
})
