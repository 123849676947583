import usePersistedState from "@/app/hooks/usePersistedState"
import { useSelectItem } from "@/app/hooks/useSelect"
import { LanguageCreateDialog } from "@/components/dialogs/LanguageCreateDialog"
import { LanguageEditDialog } from "@/components/dialogs/LanguageEditDialog"
import Menu, { useMenuContext } from "@/components/layout/dashboard/Collection/Menu"
import { Confirm, confirmSafeDictionary, useConfirm } from "@/components/ui/Confirm"
import { useDialog } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useIsAdmin } from "@/store/auth/hooks"
import { deleteLanguage } from "@/store/languages/actions"
import { useLanguagesById } from "@/store/languages/hooks"
import { Language } from "@/store/languages/localizers"
import { Pen, PlusSquare, Scan, Trash } from "lucide-react"

/**
 * dictionary src/dictionaries/en/pages/dashboard/languages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "languages")

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const languagesById = useLanguagesById()

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>("grid", "languages-view", sessionStorage)

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteLanguage,
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName: (id) => D.get(languagesById, id)?.name ?? "",
    onAsyncConfirm: deleteLanguage,
    dictionary: confirmSafeDictionary(dictionary("delete-selection-confirm")),
  })

  // dialogs
  const { setItem: create, ...createProps } = useDialog<void>()
  const { setItem: edit, ...editProps } = useDialog<Language>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        confirmDelete,
        edit,
        create,
        confirmDeleteSelection,
      }}
    >
      {children}
      <Confirm {...deleteSelectionProps} />
      <Confirm {...deleteProps} />
      <LanguageCreateDialog {...createProps} />
      <LanguageEditDialog {...editProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<{
  language: Language
}> = ({ language }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { confirmDelete, edit, create, confirmDeleteSelection } = usePageContext()
  const { id } = language
  const isAdmin = useIsAdmin()
  const { isSelected, toggleSelection } = useSelectItem(Context, language.id)
  const { type } = useMenuContext()
  const isContextMenu = type === "context-menu"
  return isAdmin ? (
    <>
      <Menu.Item onClick={toggleSelection}>
        <Scan aria-hidden />
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => edit(language)}>
        <Pen aria-hidden />
        {_("edit")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={create}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={confirmDeleteSelection}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  ) : null
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  confirmDelete: (value: string) => void
  edit: (value: Language) => void
  create: () => void
  confirmDeleteSelection: () => void
}
