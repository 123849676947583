import DialogCmp from "../radix/ui/dialog"
import { Content } from "@radix-ui/react-dialog"

/**
 * Dialog
 */
interface DialogProps extends React.ComponentProps<typeof DialogCmp> {
  open?: boolean
  animate?: boolean
  onCloseAutoFocus?: () => void
  className?: ClassName
  title?: React.ReactNode
  description?: React.ReactNode
}
export const Dialog = React.forwardRef<React.ElementRef<typeof Content>, DialogProps>(
  ({ open = true, onCloseAutoFocus, className, title, description, animate, children, ...props }, ref) => {
    const displayHeader = G.isNotNullable(title) || G.isNotNullable(description)
    return (
      <DialogCmp open={open} {...props} modal>
        <DialogCmp.Content className={className} ref={ref} onCloseAutoFocus={onCloseAutoFocus} animate={animate}>
          {displayHeader && (
            <DialogCmp.Header>
              <DialogCmp.Title>{title}</DialogCmp.Title>
              <DialogCmp.Description>{description}</DialogCmp.Description>
            </DialogCmp.Header>
          )}
          <div>{children}</div>
        </DialogCmp.Content>
      </DialogCmp>
    )
  }
)
export const DialogFooter = DialogCmp.Footer
export const DialogClose = DialogCmp.Close

/**
 * default export
 */
export const useDialog = <T = true,>() => {
  const [open, setOpen] = React.useState<T | false>(false)
  return { open: open !== false, onOpenChange: () => setOpen(false), item: open, setItem: (value: T) => setOpen(value) }
}
