import { normString } from "@/app/fns/search"
import { useFilterable } from "@/app/hooks/useFilterable"
import { useLimitable } from "@/app/hooks/useLimitable"
import { usePromise } from "@/app/hooks/usePromise"
import { useMatchable } from "@/app/hooks/useSearchable"
import { useSortable } from "@/app/hooks/useSortable"
import { useDateFnsLocale } from "@/dictionaries/hooks"
import { format } from "date-fns"
import { useUsersStore } from "."
import { getUsers } from "./actions"
import { User } from "./localizers"

export const useRefreshUsers = () => {
  return usePromise(getUsers)
}

export const useUsersById = () => {
  const byIds = useUsersStore(D.prop("users"))
  return byIds
}

export const useUser = (id: Option<string>) => {
  return useUsersStore(({ users }) => D.get(users, id ?? ""))
}

export const useUsers = () => {
  const byIds = useUsersById()
  const users = React.useMemo(() => D.values(byIds), [byIds])
  return users
}

export const useFilteredUsers = (initialLimit = 24, initialByMore = 12) => {
  const users = useUsers()
  const locale = useDateFnsLocale()

  const [matchable, matchIn] = useMatchable<User>([
    "profile.firstname",
    "profile.lastname",
    ({ profile: { firstname, lastname } }) => A.join([firstname, lastname], " "),
    // ({ createdAt }) => normString(format(createdAt, "Pp", { locale })),
    // ({ createdAt }) => normString(format(createdAt, "PPPP", { locale })),
  ])

  const [sortable, sortBy] = useSortable<User>(
    "users-sort",
    {
      firstname: ({ profile }) => profile.firstname,
      lastname: ({ profile }) => profile.lastname,
      createdAt: ({ createdAt }) => createdAt,
      updatedAt: ({ updatedAt }) => updatedAt,
    },
    "firstname"
  )

  const [filterable, filterBy] = useFilterable<User>(
    "users-filters",
    { "soft-deleted": ({ status }) => status === "deleted" },
    { "soft-deleted": true }
  )

  const filtered = React.useMemo(() => pipe(users, filterBy, matchIn, sortBy), [users, filterBy, matchIn, sortBy])
  const [limitable, limit] = useLimitable<User>(filtered.length, initialLimit, initialByMore)

  return { users, filtered, sortable, matchable, filterable, limitable, limit }
}
