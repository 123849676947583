import { useSelectItem } from "@/app/hooks/useSelect"
import { Card, Row } from "@/components/layout/dashboard/Collection"
import { Field, Fields } from "@/components/layout/dashboard/Collection/Fields"
import Menu from "@/components/layout/dashboard/Collection/Menu"
import { Link } from "@/components/ui/Link"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { useMe } from "@/store/auth/hooks"
import { User } from "@/store/users/localizers"
import { AlertTriangle, BrainCog, History, LogIn, Settings, UserCheck, UserCog, UserIcon, UserX } from "lucide-react"
import { Context, ContextMenu } from "./Context"
import { LongPressEventType, useLongPress } from "use-long-press"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Item
 */
export const Item: React.FC<{ user: User }> = ({ user }) => {
  const { view } = React.useContext(Context)
  const { selectItemProps, select } = useSelectItem(Context, user.id, () => navigate(`/dashboard/users/${user.id}`))
  // const bind = useLongPress(() => {
  //   alert("Long pressed!")
  // })
  return (
    <Menu type="context-menu" menu={<ContextMenu user={user} />} asChild>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...{ user }} /> : <ItemRow {...{ user }} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<{ user: User }> = ({ user }) => {
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()

  const { isSelected } = useSelectItem(Context, user.id)

  const me = useMe()
  const isMe = me.id === user.id

  const { role, status, lastLoginAt } = user
  const image = user.profile.thumbnail ?? user.profile.preview ?? user.profile.image

  return (
    <Card className={cx("overflow-hidden", status === "deleted" && "opacity-75")} selected={isSelected}>
      <Card.Image src={image} className="aspect-square">
        <UserIcon size={64} strokeWidth={1} />
      </Card.Image>
      <Card.Header>
        <Card.Title>
          {getFullname(user, _("fullname-placeholder"))}
          {isMe && <span className="text-sm pl-1 text-foreground/75">({_("is-me")})</span>}
          <ItemRole role={role} />
        </Card.Title>
        <Card.Description>{S.trim(`${user.profile.position} ${user.profile.company}`)}</Card.Description>
        <Card.Menu menu={<ContextMenu user={user} />} />
      </Card.Header>
      <Card.Content>
        <Fields divider>
          <Field name={_("role")} icon={<Settings aria-hidden />} value={_(`role-${role}`)} stretch />
          <Field name={_("status")} icon={<UserCog aria-hidden />} value={_(`status-${status}`)} stretch />
          <Field
            name={_("last-login-at")}
            icon={<LogIn aria-hidden />}
            value={G.isNotNullable(lastLoginAt) ? format(lastLoginAt, "PPpp") : "-"}
            stretch
          />
        </Fields>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<{ user: User }> = ({ user }) => {
  const { isSelected } = useSelectItem(Context, user.id)
  const { _ } = useDictionary(dictionary())
  const me = useMe()
  const isMe = me.id === user.id
  const format = useDateFnsLocaleFormat()
  const fullname = getFullname(user, _("fullname-placeholder"))
  const { status, role, createdAt } = user
  const image = user.profile.thumbnail ?? user.profile.preview ?? user.profile.image
  return (
    <Row selected={isSelected} className={cx(status === "deleted" && "opacity-75")}>
      <Row.Image src={image}>
        <UserIcon size={16} />
      </Row.Image>
      <Row.Header>
        <Row.Title>
          {fullname}
          {isMe && <span className="text-sm pl-1 text-foreground/75">({_("is-me")})</span>}
          <ItemRole role={role} />
        </Row.Title>
        <Row.Description>
          {_("created-at")} {format(createdAt, "PP")}
        </Row.Description>
      </Row.Header>
      <Row.Content>
        <Row.Icon tooltip={_(`status-${status}`)}>
          <ItemStatus status={status} />
        </Row.Icon>
      </Row.Content>
      <Row.Menu menu={<ContextMenu user={user} />} />
    </Row>
  )
}

/**
 * components
 */
const ItemStatus: React.FC<{ status: User["status"] }> = ({ status }) => (
  <>
    {status === "pending" && <History aria-hidden />}
    {status === "active" && <UserCheck aria-hidden />}
    {status === "deleted" && <UserX aria-hidden />}
    {status === "suspended" && <AlertTriangle aria-hidden />}
  </>
)
const ItemRole: React.FC<{ role: User["role"] }> = ({ role }) =>
  role !== "member" ? (
    <span className="inline-flex items-center ml-2 text-[10px] text-muted-foreground/50">
      {role === "superadmin" && "DEV"}
      {role === "admin" && "ADMIN"}
    </span>
  ) : undefined

/**
 * helpers
 */
export const getFullname = (user: Option<User>, placeholder: string) =>
  G.isNotNullable(user)
    ? S.isEmpty(S.trim(`${user.profile.firstname} ${user.profile.lastname}`))
      ? placeholder
      : `${user.profile.firstname} ${user.profile.lastname}`
    : placeholder
