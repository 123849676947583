import { scrollbarThinCx } from "@/components/radix/ui/scrollbar"
import Tooltip from "@/components/radix/ui/tooltip"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import { CardSpotlight, useCardSpotlight } from "../CardSpotlight"
import { Accordion } from "./accordion"
import { SidebarContext } from "./context"

/**
 * SidebarMenu
 */
type SidebarMenuProps = {
  className?: ClassName
  children: React.ReactNode
}
export const SidebarMenu: React.FC<SidebarMenuProps> = ({ className, children }) => {
  const { isDesktop, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  return (
    <div className={cx("w-full overflow-x-hidden overflow-y-auto px-2 py-4", scrollbarThinCx, className)}>
      {expanded ? <Accordion.Provider>{children}</Accordion.Provider> : children}
    </div>
  )
}

/**
 * SidebarButton
 */
type SidebarButtonProps = React.ComponentProps<"button"> & {
  active?: boolean
  icon: React.ReactNode
}
export const SidebarButton: React.FC<SidebarButtonProps> = ({
  className,
  active = false,
  icon,
  onClick,
  children,
  ...props
}) => {
  const { isDesktop, isMobile, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  const click: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isMobile) sidebar.close()
    onClick && onClick(e)
  }

  return expanded ? (
    <div className="flex">
      <button
        className={cx(
          "flex flex-1 items-center justify-between h-12 rounded-md hover:ring-1 hover:ring-foreground/10 hover:ring-offset-0",
          active && "text-primary",
          "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0",
          "transition-all ease-in-out",
          className
        )}
        onClick={click}
        {...props}
      >
        <span
          className="flex justify-center items-center w-12 h-12 [&>svg]:w-4 [&>svg]:h-4 [&>svg]:shrink-0 transition-all duration-300 ease-in-out"
          aria-hidden
        >
          {icon}
        </span>
        <span className={cx("flex grow text-base whitespace-nowrap font-light transition-all animate-in fade-in-0")}>
          {children}
        </span>
      </button>
    </div>
  ) : (
    <Tooltip content={children} side="left" align="center">
      <button
        className={cx(
          "flex justify-center items-center w-12 h-12 rounded-md",
          "hover:ring-1 hover:ring-foreground/10 hover:ring-offset-0",
          active && "text-primary",
          className
        )}
        onClick={click}
        {...props}
      >
        <span className="[&>svg]:w-4 [&>svg]:h-4 [&>svg]:shrink-0" aria-hidden>
          {icon}
        </span>
        <span className="sr-only">{children}</span>
      </button>
    </Tooltip>
  )
}

/**
 * SidebarDivider
 */
export const SidebarDivider: React.FC<React.ComponentProps<"div">> = ({ className, ...props }) => (
  <div
    className={cx(
      "flex w-10/12 h-[1px] mx-auto bg-gradient-to-r from-muted/10 via-foreground/10 to-muted/10",
      className
    )}
    {...props}
    aria-hidden
  />
)

/**
 * SidebarWrapper
 */
type SidebarWrapperProps = {
  children: React.ReactNode
}
export const SidebarWrapper: React.FC<SidebarWrapperProps> = ({ children }) => {
  const { isMobile } = React.useContext(SidebarContext)
  if (isMobile) return <SidebarMobile>{children}</SidebarMobile>
  return <SidebarDesktop>{children}</SidebarDesktop>
}

/**
 * SidebarMobile
 */
type SidebarMobileProps = {
  children: React.ReactNode
}
const SidebarMobile: React.FC<SidebarMobileProps> = ({ children }) => {
  const { state, close } = React.useContext(SidebarContext)
  return (
    <DialogPrimitive.Root open={state} onOpenChange={close}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay
          className={cx(
            "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
          )}
        />
        <DialogPrimitive.Content asChild>
          <header
            className={cx(
              "fixed z-50 gap-4 bg-background p-6 pb-0 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
              "inset-y-0 left-0 h-full w-11/12 max-w-sm border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left"
            )}
            style={{ display: "grid", gridTemplateRows: "auto 1fr auto" }}
          >
            {children}
            <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
              <X className="h-4 w-4" />
            </DialogPrimitive.Close>
          </header>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

/**
 * SidebarDesktop
 */
type SidebarDesktopProps = {
  children: React.ReactNode
}
const SidebarDesktop: React.FC<SidebarDesktopProps> = ({ children }) => {
  const sidebar = React.useContext(SidebarContext)
  const { cardProps, cardSpotlightProps } = useCardSpotlight()

  return (
    <header
      className={cx(
        "fixed z-10 top-4 left-4 bottom-4",
        "grid h-[calc(100%-2rem)]",
        "bg-primary/25 md:bg-transparent backdrop-blur-sm md:backdrop-blur-none",
        "transition-all duration-300",
        sidebar.state ? "w-72" : "w-16"
      )}
    >
      <div
        className="relative grid grid-rows-[auto_1fr_auto] h-full rounded-xl bg-card shadow-lg border overflow-hidden"
        {...cardProps}
      >
        {children}
        <CardSpotlight {...cardSpotlightProps} />
      </div>
    </header>
  )
}
