/**
 * FormHeader
 */
const FormHeader = React.forwardRef<React.ElementRef<"div">, React.ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cx("flex flex-col space-y-1.5 pt-4 text-center sm:text-left", className)} {...props} />
  )
)

/**
 * FormHeaderTitle
 */
const FormHeaderTitle = React.forwardRef<React.ElementRef<"h3">, React.ComponentPropsWithoutRef<"h3">>(
  ({ className, ...props }, ref) => (
    <h3 ref={ref} className={cx("text-base font-normal leading-none tracking-tight", className)} {...props} />
  )
)

/**
 * FormHeaderDescription
 */
const FormHeaderDescription = React.forwardRef<React.ElementRef<"p">, React.ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => (
    <p ref={ref} className={cx("text-sm text-muted-foreground font-light", className)} {...props} />
  )
)

export default Object.assign(FormHeader, {
  Title: FormHeaderTitle,
  Description: FormHeaderDescription,
})
