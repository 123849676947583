import Routes from "@/routes"
import { AdobeFonts } from "react-adobe-fonts"
import { ErrorBoundary } from "react-error-boundary"
import { Toaster } from "sonner"
import { PageBoundary } from "./components/PageBoundary"
import { PageError } from "./components/PageError"
import { PageLoader } from "./components/PageLoader"
import { useDictionary, useInitDictionary } from "./dictionaries/hooks"
import { useInitAuthStore } from "./store/auth/hooks"
import { useInitLanguagesStore } from "./store/languages/hooks"

/**
 * App
 */
const App: React.FC = () => {
  const { _ } = useDictionary("components.layout.initialize-error")

  // initialize application
  useInitDictionary()
  const [languagesLoading, languagesDone] = useInitLanguagesStore()
  const [authLoading, authDone] = useInitAuthStore()

  // on loading
  if (authLoading || languagesLoading) return <PageLoader />

  // on errors
  if (!languagesDone || !authDone)
    return <PageError title={_("title")} secondary={_("secondary")} message={_("message")} />

  return (
    <ErrorBoundary FallbackComponent={PageBoundary}>
      <AdobeFonts kitId='mnz7pfw' />
      <Routes />
      <Toaster
        toastOptions={{
          unstyled: true,
          classNames: {
            toast: "flex items-center p-4 gap-1.5 bg-card border shadow-lg rounded-md w-[var(--width)]",
            title: "text-card-foreground text-sm",
            description: "text-xs text-card-foreground/75",
            loader: "[&_svg]:text-blue-600",
            closeButton: "text-orange-600",
            cancelButton: "",
            actionButton: "",
            success: "[&_svg]:text-green-600",
            error: "[&_svg]:text-red-600",
            info: "[&_svg]:text-blue-600",
            warning: "[&_svg]:text-amber-600",
            default: "",
          },
        }}
      />
    </ErrorBoundary>
  )
}
export default App
