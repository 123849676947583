import { textPlaceholder } from "@/app/fns/String"
import { usePromise } from "@/app/hooks/usePromise"
import { Image } from "@/components/radix/ui/image"
import { Skeleton } from "@/components/radix/ui/skeleton"
import { SrOnly } from "@/components/radix/ui/sr-only"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useArticle, useArticleCategory } from "@/store/articles/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { getUser } from "@/store/users/actions"
import { useUser } from "@/store/users/hooks"
import { User } from "@/store/users/localizers"
import { useCmsContext } from "../../Context"
import { ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"
import { Hn } from "@/components/ui/Hn"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()

  const { seo, id } = useCmsContext()
  const article = useArticle(id)
  const category = useArticleCategory(article?.category)
  const { titleLevel } = item.props

  usePromise(() => G.isNotNullable(item.props.author) && getUser(item.props.author), [item.props.author])
  const author = useUser(item.props.author ?? article?.author)

  const title = textPlaceholder(t(item).props.title, t(seo).title)
  const linkText = textPlaceholder(t(item).props.linkText, _("back-to-articles"))
  const secondary = textPlaceholder(t(item).props.secondary, G.isNotNullable(category) ? t(category).name : undefined)
  const image = useMediasFile(G.isNotNullable(t(item).props.image) ? t(item).props.image : t(seo).image)

  return (
    <article className='rounded-b-md font-museo text-white overflow-hidden'>
      <div className='relative flex max-w-[1200px] aspect-[4/3] mx-auto p-4 @2xl/card:p-16 gap-4 @2xl/card:gap-[57px]'>
        <div className='absolute inset-0 w-full h-full bg-be-midnight'>
          {G.isNotNullable(image) && (
            <Image
              className='object-cover w-full h-full'
              wrapperCx='w-full h-full'
              src={image.url}
              alt={t(image).alt}
            />
          )}
          <div className='absolute inset-0 bg-[#00265780]' aria-hidden />
        </div>
        <span className='inline-flex justify-center items-center shrink-0 size-6 @2xl/card:size-10 rounded-full bg-white/20 backdrop-blur-md'>
          <ArrowBottom aria-hidden className='size-3 @2xl/card:size-4 rotate-90' />
          <SrOnly>{linkText}</SrOnly>
        </span>
        <div className='relative flex flex-col justify-start grow'>
          <div className='flex flex-col justify-center items-start grow max-w-sm'>
            {G.isNotNullable(secondary) && (
              <p className='inline-flex mb-4 @2xl/card:mb-[30px] px-[30px] py-3 rounded-full bg-white/20 backdrop-blur-md text-sm @2xl/card:text-base leading-none uppercase'>
                {secondary}
              </p>
            )}
            <Hn
              className='w-full max-w-sm uppercase text-[25px] @2xl/card:text-[40px] leading-tight font-bold'
              level={titleLevel}
            >
              {title}
              <Skeleton value={title} />
              <Skeleton value={title} />
              <Skeleton className='w-3/4' value={title} />
            </Hn>
            {G.isNotNullable(author) && <Author author={author} />}
          </div>
          <span
            className='hidden @4xl/card:inline-flex justify-center items-center w-10 h-[64px] shrink-0 rounded-full bg-white/20 backdrop-blur-md'
            aria-hidden
          >
            <ArrowBottom />
          </span>
        </div>
      </div>
    </article>
  )
}

/**
 * Author
 */
const Author: React.FC<{ author: Option<User> }> = ({ author }) => {
  if (G.isNullable(author)) return null
  const fullname = textPlaceholder(`${author.profile.firstname} ${author.profile.lastname}`)
  const email = A.head(author.profile.emails)?.value ?? null
  const image = author.profile.thumbnail ?? author.profile.preview ?? author.profile.image
  return G.isNotNullable(image) || G.isNotNullable(email) || G.isNotNullable(image) ? (
    <div className='flex items-center gap-[18px] pt-4 @2xl/card:pt-[27px] text-xs'>
      {G.isNotNullable(image) && (
        <Image className='size-8 @2xl/card:size-10 rounded-full object-cover' src={image} alt={fullname ?? undefined} />
      )}
      <ul className='flex flex-col gap-0.25 @2xl/card:gap-[5px]'>
        {G.isNotNullable(fullname) && <li className='font-bold uppercase'>{fullname}</li>}
        {G.isNotNullable(email) && (
          <li>
            <a href={`mailto:${email}`}>{email}</a>
          </li>
        )}
      </ul>
    </div>
  ) : null
}

/**
 * icons
 */
const ArrowBottom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none' {...props}>
    <path
      stroke='CurrentColor'
      strokeLinecap='square'
      strokeWidth={1.5}
      d='M10 3.125v13.75m0 0L4.375 11.25M10 16.875l5.625-5.625'
    />
  </svg>
)
