import { Form, FormAssertive, FormInput, FormSelect, FormSubmit, FormTranslationTabs, useForm } from "@/components/form"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/radix/ui/button"
import Dialog from "@/components/radix/ui/dialog"
import { contextMapper, createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { useCmsContext } from "../../Context"
import { useAuthorOptions } from "../../hooks/useAuthorOptions"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _form } = useDictionary(contextMapper("components", "cms", "content", "form"))
  const { id: contextKey } = useCmsContext()

  const {
    actions: { updateContentItem },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: React.useMemo(
      () => ({
        titleLevel: item.props.titleLevel,
        author: item.props.author,
        translations: D.map(languagesById, (language) => ({
          languageId: language.id,
          title: translate(item, language)?.props.title ?? "1",
          secondary: translate(item, language)?.props.secondary ?? "",
          linkText: translate(item, language)?.props.linkText ?? "",
          linkUrl: translate(item, language)?.props.linkUrl ?? "",
          image: translate(item, language)?.props.image ?? null,
        })),
      }),
      []
    ),
    onSubmit: async ({ values }) => {
      const payload: FormPayload<ItemType> = {
        props: {
          titleLevel: +values.titleLevel,
          author: values.author,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: A.reduce(D.values(values.translations), [] as string[], (files, translation) =>
          G.isNotNullable(translation.image) ? [...files, translation.image] : files
        ),
      }
      const { error } = await updateContentItem(item.id, payload)
      if (!error) {
        toast.success(_("success"))
        return close()
      }
      window.location.reload()
    },
  })

  const authorOptions = useAuthorOptions()
  const titleLevelOption = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs>
        {(language) => (
          <div className="grid gap-6" key={language.id}>
            <FormInput label={_("title-label")} name="title" placeholder={_("title-placeholder")} />
            <FormInput label={_("secondary-label")} name="secondary" placeholder={_("secondary-placeholder")} />
            <FormInput label={_("link-text-label")} name="linkText" placeholder={_("link-text-placeholder")} />
            <FormInput label={_("link-url-label")} name="linkUrl" type="url" placeholder={_("link-url-placeholder")} />
            <FormMediasImage label={_("image-label")} name="image" ratio="aspect-[4/3]" contextKey={contextKey} />
          </div>
        )}
      </FormTranslationTabs>
      <FormSelect label={_form("author-label")} name="author" options={authorOptions} />
      <FormSelect label={_form("title-level-label")} name="titleLevel" options={titleLevelOption} />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
