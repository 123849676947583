import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
  useForm,
} from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import Dialog from "@/components/radix/ui/dialog"
import { contextMapper, createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { useCmsContext } from "../../Context"
import { proseStyle } from "../../frontend/proseStyle"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _form } = useDictionary(contextMapper("components", "cms", "content", "form"))

  const {
    actions: { updateContentItem },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: React.useMemo(
      () => ({
        titleLevel: `${item.props.titleLevel}`,
        translations: D.map(languagesById, (language) => ({
          languageId: language.id,
          title: translate(item, language)?.props.title ?? "",
          secondary: translate(item, language)?.props.secondary ?? "",
          description: translate(item, language)?.props.description ?? "",
          linkText: translate(item, language)?.props.linkText ?? "",
          linkUrl: translate(item, language)?.props.linkUrl ?? "",
        })),
      }),
      []
    ),
    onSubmit: async ({ values }) => {
      const payload: FormPayload<ItemType> = {
        props: {
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: [],
      }
      const { error, code } = await updateContentItem(item.id, payload)
      if (!error) {
        toast.success(_("success"))
        return close()
      } else if (code === "VALIDATION_FAILURE") {
        return _form(code)
      } else if (code === "RESOURCE_NOT_FOUND" || code === "FETCH_ERROR") {
        toast.error(_form(code))
        return close()
      }
      resetAllStoresAndReload()
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs context={false}>
        {(language) => (
          <div className="grid gap-6" key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput label={_form("title-label")} name="title" placeholder={_form("title-placeholder")} />
              <FormInput
                label={_form("secondary-label")}
                name="secondary"
                placeholder={_form("secondary-placeholder")}
              />
              <FormTiptap label={_form("description-label")} name="description" prose={cx(proseStyle, "bg-white")} />
              <FormInput label={_("link-text-label")} name="linkText" placeholder={_("link-text-placeholder")} />
              <FormInput
                label={_("link-url-label")}
                name="linkUrl"
                type="url"
                placeholder={_("link-url-placeholder")}
              />
            </FormTranslationContext>
            <FormHeader>
              <FormHeader.Title>{_form("more-options-title")}</FormHeader.Title>
              <FormHeader.Description>{_form("more-options-description")}</FormHeader.Description>
            </FormHeader>
            <FormSelect label={_form("title-level-label")} name="titleLevel" options={titleLevelOptions} />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
