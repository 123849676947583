import Layout from "@/components/layout/dashboard"
import DashboardIndex from "@/pages/dashboard"
import DashboardLanguagesIndex from "@/pages/dashboard/languages"
import DashboardUsersIndex from "@/pages/dashboard/users"
import DashboardMediasIndex from "@/pages/dashboard/medias"
import DashboardPagesIndex from "@/pages/dashboard/pages"
import DashboardPagesPage from "@/pages/dashboard/pages/page"
import DashboardArticlesIndex from "@/pages/dashboard/articles"
import DashboardArticlesArticle from "@/pages/dashboard/articles/article"
import DashboardArticlesCategories from "@/pages/dashboard/articles/categories"
import DashboardUsersUser from "@/pages/dashboard/users/user"
import { Redirect, Route, Switch } from "wouter"

/**
 * DashboardRoutes
 */
const DashboardRoutes: React.FC = () => {
  const isAuth = true
  if (!isAuth) return <Redirect to='/' />
  return (
    <Layout>
      <Switch>
        {/* medias */}
        <Route path='/dashboard/medias/:folderId*'>{(params) => <DashboardMediasIndex id={params.folderId} />}</Route>
        {/* users */}
        <Route path='/dashboard/users'>
          <DashboardUsersIndex />
        </Route>
        <Route path='/dashboard/users/:id'>{(params) => <DashboardUsersUser {...params} />}</Route>
        {/* languages */}
        <Route path='/dashboard/languages'>
          <DashboardLanguagesIndex />
        </Route>
        {/* pages */}
        <Route path='/dashboard/pages'>
          <DashboardPagesIndex />
        </Route>
        <Route path='/dashboard/pages/:id'>{(params) => <DashboardPagesPage {...params} />}</Route>
        {/* articles */}
        <Route path='/dashboard/articles'>
          <DashboardArticlesIndex />
        </Route>
        <Route path='/dashboard/articles/categories'>
          <DashboardArticlesCategories />
        </Route>
        <Route path='/dashboard/articles/:id'>{(params) => <DashboardArticlesArticle {...params} />}</Route>
        {/* home */}
        <Route path='/dashboard'>
          <DashboardIndex />
        </Route>
        <Redirect to='/dashboard' />
      </Switch>
    </Layout>
  )
}
export default DashboardRoutes
