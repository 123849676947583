import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  toSelectOptions,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { useDictionary } from "@/dictionaries/hooks"
import { useMe } from "@/store/auth/hooks"
import { createUser } from "@/store/users/actions"
import { User } from "@/store/users/localizers"

/**
 * UserCreateDialog
 * dictionary src/dictionaries/en/components/dialogs/user-create-dialog.json
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
}
export const UserCreateDialog: React.FC<Props> = ({ open, onOpenChange }) => {
  const { _ } = useDictionary("components.dialogs.user-create-dialog")
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      <DialogForm close={() => onOpenChange(false)} />
    </Dialog>
  )
}

/**
 * DialogForm
 * dictionary src/dictionaries/en/components/dialogs/user-create-dialog.json
 */
const DialogForm: React.FC<{ close: () => void }> = ({ close }) => {
  const { _ } = useDictionary("components.dialogs.user-create-dialog")
  const me = useMe()
  const isSuperadmin = me.role === "superadmin"
  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      email: "",
      password: "",
      role: "member" as User["role"],
      status: "active" as User["status"],
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      email: [min(1, "email-required"), isEmail("email-format")],
      password: [min(8, "password-required")],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return "VALIDATION_FAILURE"
      const response = await createUser(values)
      if (!response.error) {
        close()
        toast.success(_("success"))
        navigate(`/dashboard/users/${response.id}`)
      } else return response.code
    },
  })

  const roleOption: { label: string; value: User["role"]; disabled?: boolean }[] = [
    {
      label: _("role-member"),
      value: "member",
    },
    {
      label: _("role-admin"),
      value: "admin",
    },
    {
      label: _("role-superadmin"),
      value: "superadmin",
      disabled: !isSuperadmin,
    },
  ]

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-connection-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-connection-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormInput label={_("email-label")} name='email' type='email' placeholder={_("email-placeholder")} />
        <FormInput
          label={_("password-label")}
          labelAside={
            <Button variant='link' size='link' onClick={() => form.setValues({ password: generatePassword() })}>
              {_("password-generate")}
            </Button>
          }
          name='password'
          type='password'
          placeholder={_("password-placeholder")}
        />
      </FormSection>
      <FormHeader>
        <FormHeader.Title>{_("section-settings-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-settings-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormSelect label={_("role-label")} name='role' placeholder={_("role-placeholder")} options={roleOption} />
        <FormSelect
          label={_("status-label")}
          name='status'
          placeholder={_("status-placeholder")}
          options={toSelectOptions(
            ["pending", "active", "deleted", "suspended"],
            _ as (ctx: string) => string,
            "status"
          )}
        />
      </FormSection>
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}

/**
 * generatePassword
 */
const generatePassword = (minLength: number = 12) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+"
  let password = ""
  for (let i = 0; i < minLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    password += characters[randomIndex]
  }
  return password
}
