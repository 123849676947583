import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  formatDateToFormInput,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { updateArticle } from "@/store/articles/actions"
import { useArticleCategories, useRefreshArticleCategories } from "@/store/articles/hooks"
import { Article } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { formatDatetimeToFormInput } from "../form/FormInput"
import { useRefreshUsers, useUsers } from "@/store/users/hooks"

/**
 * dictionary src/dictionaries/en/components/dialogs/article-create-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "article-update-dialog")

/**
 * ArticleUpdateDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: Article | false
}
export const ArticleUpdateDialog: React.FC<Props> = ({ open, onOpenChange, item }) => {
  const { _ } = useDictionary(dictionary())

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      {item !== false && <DialogForm onOpenChange={onOpenChange} article={item} />}
    </Dialog>
  )
}
const DialogForm: React.FC<{ onOpenChange: (open: boolean) => void; article: Article }> = ({
  onOpenChange,
  article,
}) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  useRefreshArticleCategories()
  useRefreshUsers()
  const categories = useArticleCategories()
  const authors = useUsers()

  const { min, isSetAndBeforeIso, isSetAndAfterIso } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: React.useMemo(
      () => ({
        type: article.type,
        state: article.state,
        categoryId: G.isNull(article.category) ? "null" : article.category,
        authorId: G.isNull(article.author) ? "null" : article.author,
        publishedAt: formatDateToFormInput(article.publishedAt),
        publishedFrom: formatDatetimeToFormInput(article.publishedFrom),
        publishedTo: formatDatetimeToFormInput(article.publishedTo),
      }),
      []
    ),
    validate: validator({
      publishedAt: [min(1, _("published-at-required"))],
      publishedFrom: [isSetAndBeforeIso("publishedTo", _("published-from-invalid"))],
      publishedTo: [isSetAndAfterIso("publishedFrom", _("published-to-invalid"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _("VALIDATION_FAILURE")
      const payload = {
        type: values.type,
        state: values.state,
        categoryId: values.categoryId === "null" ? null : values.categoryId,
        authorId: values.authorId === "null" ? null : values.authorId,
        publishedAt: values.publishedAt,
        publishedFrom: values.publishedFrom || null,
        publishedTo: values.publishedTo || null,
      }
      const { error, code } = await updateArticle(article.id, payload)
      if (!error) {
        toast.success(_("success"))
        return onOpenChange(false)
      }
      if (code === "VALIDATION_FAILURE" || code === "FETCH_ERROR") return _(code)
      window.location.reload()
    },
  })

  const typeOptions = [
    {
      label: _("type-news"),
      value: "news",
    },
    {
      label: _("type-case-studies"),
      value: "case-studies",
    },
  ]
  const stateOptions = [
    {
      label: _("state-draft"),
      value: "draft",
    },
    {
      label: _("state-published"),
      value: "published",
    },
  ]
  const categoryOptions = [
    { label: _("category-placeholder"), value: "null" },
    ...A.map(
      A.sortBy(categories, (category) => t(category).name),
      (category) => ({
        label: t(category).name,
        value: category.id,
      })
    ),
  ]
  const authorOptions = [
    { label: _("author-placeholder"), value: "null" },
    ...A.map(
      A.sortBy(authors, (author) => author.profile.lastname),
      (author) => ({
        label: `${author.profile.lastname} ${author.profile.firstname}`,
        value: author.id,
      })
    ),
  ]

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-informations-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-informations-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormSelect label={_("type-label")} name='type' options={typeOptions} />
        <FormSelect label={_("category-label")} name='categoryId' options={categoryOptions} />
        <FormSelect label={_("author-label")} name='authorId' options={authorOptions} />
        <FormInput label={_("published-at-label")} name='publishedAt' type='date' />
      </FormSection>
      <FormHeader>
        <FormHeader.Title>{_("section-publication-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-publication-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormSelect label={_("state-label")} name='state' options={stateOptions} />
        <div className='grid grid-cols-2 gap-4'>
          <FormInput label={_("published-from-label")} name='publishedFrom' type='datetime-local' />
          <FormInput label={_("published-to-label")} name='publishedTo' type='datetime-local' />
        </div>
      </FormSection>
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
