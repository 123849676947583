import { Form } from "./"

/**
 * FormFieldGroup
 */
type FormFieldGroupProps = {
  name: string
  children: React.ReactNode
}

export const FormFieldGroup: React.FC<FormFieldGroupProps> = ({ name, children }) => {
  return <Form.FieldGroup name={name}>{children}</Form.FieldGroup>
}
