import { createFetcher, request } from "@101studios/request"
import { makePath } from "@/app/fns/File"
import globalConfig from "@/config/global"

export const getSessionToken = () => (globalConfig.sessionKey ? localStorage.getItem(globalConfig.sessionKey) : null)

export const api = createFetcher((url, opts) => {
  return request(makePath(globalConfig.api, "api", url), {
    ...opts,
    throwCanceled: false,
    throwNetwork: false,
    credentials: "include",
    headers: {
      ...opts?.headers,
    },
  })
})

/**
 * types
 */
export type NoContent = {
  //
}
export type NoError = {
  code: "FETCH_ERROR"
}
export type ErrorCode<T extends string> = {
  code: T
}
export type AuthErrorCode<T extends string = "INVALID_API_TOKEN"> = {
  code: T | "INVALID_API_TOKEN" | "INVALID_AUTH_SESSION"
}
export type AdminErrorCode<T extends string = "INVALID_API_TOKEN"> = {
  code: T | "RESOURCE_NOT_ALLOWED" | "INVALID_API_TOKEN" | "INVALID_AUTH_SESSION"
}
