import { useSelectItem } from "@/app/hooks/useSelect"
import { Card, Row } from "@/components/layout/dashboard/Collection"
import { Field, Fields } from "@/components/layout/dashboard/Collection/Fields"
import Menu from "@/components/layout/dashboard/Collection/Menu"
import { Badge } from "@/components/radix/ui/badge"
import { useDictionary } from "@/dictionaries/hooks"
import { Language } from "@/store/languages/localizers"
import localeCodes from "locale-codes"
import { Barcode, Flag, Globe, Languages } from "lucide-react"
import { Context, ContextMenu } from "./Context"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/pages/dashboard/languages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "languages")

/**
 * Item
 */
export const Item: React.FC<{ language: Language }> = ({ language }) => {
  const { view } = React.useContext(Context)
  const { selectItemProps } = useSelectItem(Context, language.id)
  return (
    <Menu type="context-menu" menu={<ContextMenu {...{ language }} />}>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...{ language }} /> : <ItemRow {...{ language }} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<{ language: Language }> = ({ language }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, language.id)
  const code = localeCodes.getByTag(language.locale)
  return (
    <Card className={cx("overflow-hidden", status === "deleted" && "opacity-75")} selected={isSelected}>
      <Card.Header>
        <Card.Title>{language.name}</Card.Title>
        <Card.Menu menu={<ContextMenu language={language} />} />
      </Card.Header>
      <Card.Content>
        <Fields divider>
          <Field name={_("location")} icon={<Flag aria-hidden />} value={code.location} stretch />
          <Field name={_("local")} icon={<Languages aria-hidden />} value={code.name} stretch />
          <Field name={_("code")} icon={<Barcode aria-hidden />} value={language.locale} stretch />
        </Fields>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<{ language: Language }> = ({ language }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, language.id)
  const code = localeCodes.getByTag(language.locale)
  return (
    <Row selected={isSelected} className={cx(status === "deleted" && "opacity-75")}>
      <Row.Image>
        <Globe size={16} />
      </Row.Image>
      <Row.Header>
        <Row.Title>{language.name}</Row.Title>
        <Row.Description className="flex flex-wrap gap-2">
          <span className="inline-flex items-center gap-2">
            <Flag aria-hidden /> {_("location")}: {code.location}
          </span>
          <span className="inline-flex items-center gap-2">
            <Languages aria-hidden /> {_("local")}: {code.name}
          </span>
        </Row.Description>
      </Row.Header>
      <Row.Content className="flex flex-wrap gap-2">
        <Badge variant="secondary" className="w-28">
          {_("code")}: {language.locale}
        </Badge>{" "}
      </Row.Content>
      <Row.Menu menu={<ContextMenu language={language} />} />
    </Row>
  )
}
