import {
  Form,
  FormAssertive,
  FormExtraFields,
  FormHeader,
  FormImage,
  FormInput,
  FormSection,
  FormSubmit,
  formatDateToFormInput,
  useForm,
} from "@/components/form"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { useDictionary } from "@/dictionaries/hooks"
import { updateProfile } from "@/store/auth/actions"
import { useMe } from "@/store/auth/hooks"
import { Me } from "@/store/auth/localizers"
import { Button } from "../radix/ui/button"

/**
 * ProfileDialog
 * dictionary src/dictionaries/en/components/dialogs/profile-dialog.json
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
}
export const ProfileDialog: React.FC<Props> = (props) => {
  const { open, onOpenChange } = props
  const { _ } = useDictionary("components.dialogs.profile-dialog")

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-4xl'
    >
      <ProfileForm {...props} />
    </Dialog>
  )
}

/**
 * ProfileForm
 * dictionary src/dictionaries/en/components/dialogs/profile-dialog.json
 */
export const ProfileForm: React.FC<Props> = ({ onOpenChange }) => {
  const { _ } = useDictionary("components.dialogs.profile-dialog")
  const { _: translate } = useDictionary<string, false>("components.dialogs.profile-dialog")

  const me = useMe() as Me
  const form = useForm({
    values: {
      ...D.selectKeys(me.profile, ["firstname", "lastname", "position", "company", "phones", "emails", "extras"]),
      image: {
        url: me.profile.image,
        file: null,
        delete: false,
      },
      dob: formatDateToFormInput(me.profile.dob),
      ...me.profile.address,
    },
    allowSubmitAttempt: true,
    translate,
    onSubmit: async ({ values }) => {
      const dob = values.dob === "" ? null : values.dob
      const address = D.selectKeys(values, ["address", "city", "country", "state", "zip"])
      const image = D.selectKeys(values.image, ["file", "delete"])
      const data = D.selectKeys(values, ["firstname", "lastname", "position", "company", "phones", "emails", "extras"])
      const response = await updateProfile({ ...data, address, dob, image })
      if (!response.error) {
        toast.success(_("success"))
        onOpenChange(false)
      } else return response.code
    },
  })

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />

      <FormHeader>
        <FormHeader.Title>{_("section-personal-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-personal-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className='grid @xs/dialog:grid-cols-3'>
        <FormSection className='@xs/dialog:col-span-2'>
          <FormInput
            label={_("firstname-label")}
            name='firstname'
            placeholder={_("firstname-placeholder")}
            auto='given-name'
          />
          <FormInput
            label={_("lastname-label")}
            name='lastname'
            placeholder={_("lastname-placeholder")}
            auto='family-name'
          />
          <FormInput label={_("dob-label")} name='dob' type='date' auto='bday-day' />
        </FormSection>
        <FormImage label={_("image-label")} name='image' ratio='aspect-square' />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-employment-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-employment-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className='grid @xs/dialog:grid-cols-2'>
        <FormInput
          label={_("company-label")}
          name='company'
          placeholder={_("company-placeholder")}
          auto='organization'
        />
        <FormInput
          label={_("position-label")}
          name='position'
          placeholder={_("position-placeholder")}
          auto='organization-title'
        />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-contact-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-contact-description")}</FormHeader.Description>
      </FormHeader>
      <FormExtraFields
        label={_("phones-label")}
        name='phones'
        translate={(t) => translate(`phones-${t}`)}
        type='tel'
        auto={{ name: "new-phone", value: "tel" }}
      />
      <FormExtraFields
        label={_("emails-label")}
        name='emails'
        translate={(t) => translate(`emails-${t}`)}
        type='email'
        auto={{ name: "new-email", value: "email" }}
      />

      <FormHeader>
        <FormHeader.Title>{_("section-address-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-address-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className='grid @xs/dialog:grid-cols-3'>
        <FormInput
          className='@xs/dialog:col-span-2'
          label={_("address-label")}
          name='address'
          auto='street-address'
          placeholder={_("address-placeholder")}
        />
        <FormInput label={_("city-label")} name='city' placeholder={_("city-placeholder")} />
        <FormInput label={_("state-label")} name='state' placeholder={_("state-placeholder")} />
        <FormInput label={_("zip-label")} name='zip' placeholder={_("zip-placeholder")} auto='postal-code' />
        <FormInput label={_("country-label")} name='country' placeholder={_("country-placeholder")} auto='country' />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-additional-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-additional-description")}</FormHeader.Description>
      </FormHeader>
      <FormExtraFields
        label={_("extras-label")}
        name='extras'
        translate={(t) => translate(`extras-${t}`)}
        auto={{ value: false }}
      />
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
