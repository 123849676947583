import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/radix/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/radix/ui/card"
import globalConfig from "@/config/global"
import { useDictionary } from "@/dictionaries/hooks"
import { forgotPassword } from "@/store/auth/actions"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/pages/forgot-password.json
 */
const dictionary = createContextMapper("pages", "forgot-password")

/**
 * Page: ForgotPassword
 */
const ForgotPassword: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const [requestDone, setRequestDone] = React.useState(false)
  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      email: "",
    },
    translate: _ as (ctx: string) => string,
    validate: validator({
      email: [min(1, "email-required"), isEmail("email-format")],
    }),

    onSubmit: async ({ values: { email } }) => {
      if (!form.isValid) return "VALIDATION_FAILURE"
      const data = {
        email,
        redirect: `${globalConfig.host}/reset-password`,
      }
      const response = await forgotPassword(data)
      if (!response.error || response.code === "USER_NOT_FOUND") {
        setRequestDone(true)
      } else return response.code
    },
  })
  return (
    <div className="flex justify-center items-center w-full min-h-screen p-8 bg-secondary">
      {requestDone ? (
        <Card className="w-full max-w-xs">
          <CardHeader>
            <CardTitle>{_("success-title")}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <p className="text-sm">{_("success-message")}</p>
              <Button variant="ghost" onClick={() => navigate("/")}>
                {_("back")}
              </Button>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card className="w-full max-w-xs">
          <CardHeader>
            <CardTitle>{_("title")}</CardTitle>
            <CardDescription>{_("secondary")}</CardDescription>
          </CardHeader>
          <CardContent>
            <Form form={form} className="grid gap-4">
              <FormAssertive />
              <FormInput label={_("email-label")} name="email" type="email" placeholder={_("email-placeholder")} />
              <FormSubmit>{_("submit")}</FormSubmit>
              <Button variant="ghost" onClick={() => navigate("/")}>
                {_("back")}
              </Button>
            </Form>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
export default ForgotPassword
