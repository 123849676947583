import useInterval from "@/app/hooks/useInterval"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocale, useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { service } from "@/services/trackings/service"
import millify from "millify"
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts"
import { parseIsoWeek } from "./helpers"
import { useStatsTheme } from "./hooks"
import { StatRecord, StatsProps } from "./types"

/**
 * dictionary src/dictionaries/en/components/trackings.json
 */
const dictionary = createContextMapper("components", "trackings")

const reloadTime = 30000

/**
 * StatsVisitByWeeks
 */
export const StatsVisitByWeeks: React.FC<StatsProps> = ({
  trackingId,
  interval,
  setIsLoading = () => true,
  live = false,
}) => {
  const { colors } = useStatsTheme()
  const format = useDateFnsLocaleFormat()
  const locale = useDateFnsLocale()
  const [data, setData] = React.useState<StatRecord[]>([])

  const updateData = async (interval: StatsProps["interval"]) => {
    setIsLoading(true)
    const params = interval.from
      ? {
          from: T.formatISO(T.startOfWeek(interval.from, { locale })),
          to: T.formatISO(T.endOfWeek(interval.to, { locale })),
        }
      : { to: T.formatISO(T.endOfWeek(interval.to)) }
    const { error, data } = G.isNullable(trackingId)
      ? await service.byWeek(params)
      : await service.trackings.byWeek(trackingId, params)
    setIsLoading(false)
    if (error) return
    const start =
      interval.from ??
      pipe(
        D.keys(data.stats),
        A.map((iso) => parseIsoWeek(iso, locale)),
        A.sortBy((d) => d),
        A.head
      ) ??
      new Date()
    const week = T.eachWeekOfInterval({ start, end: interval.to })
    const populated = A.map<Date, StatRecord>(week, (date) => {
      const key = format(date, "yyyy-ww")
      return { name: key, value: data.stats[key] ?? 0 }
    })
    setData(populated)
  }

  React.useEffect(() => {
    updateData(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval.from, interval.to])
  useInterval(() => updateData(interval), live ? reloadTime : null)

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray='3 3' stroke='hsl(var(--muted))' />
        <XAxis
          dataKey='name'
          stroke='hsl(var(--muted-foreground))'
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: string) => format(parseIsoWeek(value, locale), "ww")}
        />
        <YAxis
          dataKey='value'
          stroke='hsl(var(--muted-foreground))'
          width={40}
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: number) => millify(value)}
        />
        <Tooltip content={<StatsVisitByWeeksTooltip />} cursor={{ fill: "hsl(var(--muted))" }} />
        {/* <Legend /> */}
        <Bar dataKey='value' fill={colors[2]} />
      </BarChart>
    </ResponsiveContainer>
  )
}

/**
 * StatsVisitByWeeksTooltip
 */
const StatsVisitByWeeksTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  const { _ } = useDictionary(dictionary("stats-dialog"))
  const { colors } = useStatsTheme()
  if (!(active && payload && payload.length)) return null
  const {
    value,
    payload: { name },
  } = A.getUnsafe(payload, 0)
  const [year, week] = A.map(S.split(name, "-"), Number)
  if (G.isNullable(year) || G.isNullable(week)) throw new Error("Invalid yearWeek")
  return (
    <div className='rounded-md border bg-popover px-3 py-1.5 text-[0.75em] text-popover-foreground shadow-md'>
      <b style={{ color: colors[2] }}>{_("tooltip-by-weeks", { week, year })}: </b> <span>{`${value}`}</span>
    </div>
  )
}
