import { usePromise } from "@/app/hooks/usePromise"
import { getPages } from "./actions"
import { usePagesStore } from "."
import { useDateFnsLocale } from "@/dictionaries/hooks"
import { useMatchable } from "@/app/hooks/useSearchable"
import { Page } from "./localizers"
import { format } from "date-fns"
import { normString } from "@/app/fns/search"
import { useTranslation } from "../languages/hooks"
import { useSortable } from "@/app/hooks/useSortable"
import { useLimitable } from "@/app/hooks/useLimitable"

export const useRefreshPages = () => {
  return usePromise(getPages)
}
export const usePagesById = () => {
  const byIds = usePagesStore(D.prop("pages"))
  return byIds
}
export const usePages = () => {
  const byIds = usePagesById()
  const pages = React.useMemo(() => D.values(byIds), [byIds])
  return pages
}
export const usePage = (id: Option<string>) => {
  const page = usePagesStore(flow(D.prop("pages"), D.prop(id ?? "")))
  return page
}

export const useFilteredPages = (initialLimit = 24, initialByMore = 12) => {
  const pages = usePages()
  const locale = useDateFnsLocale()
  const t = useTranslation()

  const [matchable, matchIn] = useMatchable<Page>([
    ({ seo }) => t(seo).title,
    ({ seo }) => t(seo).description,
    ({ createdAt }) => normString(format(createdAt, "Pp", { locale })),
    ({ createdAt }) => normString(format(createdAt, "PPPP", { locale })),
  ])

  const [sortable, sortBy] = useSortable<Page>(
    "pages-sort",
    {
      title: ({ seo }) => t(seo).title,
      createdAt: ({ createdAt }) => createdAt,
      updatedAt: ({ updatedAt }) => updatedAt,
    },
    "title"
  )
  const filtered = React.useMemo(() => pipe(pages, matchIn, sortBy), [pages, matchIn, sortBy])
  const [limitable, limit] = useLimitable<Page>(filtered.length, initialLimit, initialByMore)

  return { pages, filtered, matchable, sortable, sortBy, limitable, limit }
}
