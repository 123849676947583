import { textPlaceholder } from "@/app/fns/String"
import { Skeleton } from "@/components/radix/ui/skeleton"
import { Hn } from "@/components/ui/Hn"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { ImageIcon } from "lucide-react"
import { ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary, linkText } = t(item).props

  return (
    <article className="p-4 @lg/card:p-8 bg-be-cultured rounded-b-md font-museo">
      <div className="w-full max-w-[1000px] mx-auto">
        {S.isNotEmpty(S.trim(secondary)) && (
          <p className="pb-2.5 @xl/card:pb-5 text-xs @xl/card:text-base leading-normal font-semibold text-be-manatee uppercase">
            {secondary}
          </p>
        )}
        <div className="flex items-center gap-[50px] mb-[50px]">
          <Hn
            className="uppercase text-[25px] @xl/card:text-[40px] leading-tight font-bold text-be-midnight"
            level={titleLevel}
          >
            {title}
            <Skeleton className="w-48" value={title} />
          </Hn>
          <span className="hidden @2xl/card:inline-flex grow border-b border-b-be-manatee" aria-hidden />
          <span className="hidden @2xl/card:inline-flex items-center px-[20px] py-[10px] gap-3 rounded-[25px] bg-be-orient text-be-button text-white uppercase">
            {textPlaceholder(linkText, _("read-more"))}
            <ArrowRight />
          </span>
        </div>
        <div className="flex flex-col @2xl/card:grid grid-cols-2 gap-[25px]">
          {A.map(A.range(0, 3), (index) => (
            <Card key={index} />
          ))}
        </div>
        <div className="flex @2xl/card:hidden justify-center mt-4">
          <span className="inline-flex items-center px-[20px] py-[10px] gap-3 rounded-[25px] bg-be-orient text-be-button text-white uppercase">
            {textPlaceholder(linkText, _("read-more"))}
            <ArrowRight />
          </span>
        </div>
      </div>
    </article>
  )
}

/**
 * Card
 */
const Card: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  return (
    <article className="relative flex flex-col shadow-be-card rounded-[5px]">
      <div className="relative flex justify-center items-center w-full aspect-[46/16] rounded-t-[5px] overflow-hidden bg-be-manatee/25">
        <ImageIcon size={64} strokeWidth={1} className="text-be-independance/5" />
      </div>
      <div className="px-[20px] py-[30px]">
        <h3 className="text-be-independance text-[25px] leading-normal">
          <Skeleton />
          <Skeleton />
          <Skeleton className="w-3/5" />
        </h3>
        <div className="flex justify-between items-end pt-[30px]">
          <p className="flex items-center gap-2 text-sm text-be-midgray">
            {_("published-on")} <Skeleton className="w-28" />
          </p>
          <span
            className={cx(
              "inline-flex justify-center items-center w-[50px] h-[50px] rounded-full bg-be-orient text-be-button text-white uppercase"
            )}
          >
            <ArrowRight />
          </span>
        </div>
      </div>
    </article>
  )
}

/**
 * icons
 */
const ArrowRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="square"
      strokeWidth={1.5}
      d="M3.125 10h13.75m0 0L11.25 4.375M16.875 10l-5.625 5.625"
    />
  </svg>
)
