import globalConfig from "@/config/global"
import * as Auth from "@/services/auth/service"
import { authStore } from "."
import { resetAllStores } from ".."
import { localizeMe } from "./localizers"

/**
 * initStore
 */
export const initAuthStore = async (sessionId: string) => {
  const sid = localStorage.getItem(`${globalConfig.sessionKey}-sid`) ?? sessionId
  localStorage.setItem(`${globalConfig.sessionKey}-sid`, sid)
  const { data, error, code } = await Auth.service.session()
  if (error) authStore.set({ sid, initDone: true, initError: true })
  else
    authStore.set({
      sid,
      initDone: true,
      initError: false,
      authenticated: data.session === true,
      user: data.session === true ? localizeMe(data.user) : null,
    })
  return { error, code } as const
}

/**
 * signIn
 */
export const signIn = async (payload: Auth.Payload["signIn"]) => {
  const { data, error, code } = await Auth.service.signIn(payload)
  if (!error) authStore.evolve({ authenticated: true, user: localizeMe(data.user) })
  return { error, code } as const
}

/**
 * signOut
 */
export const signOut = async () => {
  const { error, code } = await Auth.service.signOut()
  if (!error) authStore.evolve({ authenticated: false, user: null })
  resetAllStores()
  return { error, code } as const
}

/**
 * updateMe
 */
export const updateMe = async (payload: Auth.Payload["update"]) => {
  const { data, error, code } = await Auth.service.update(payload)
  if (!error) authStore.evolve({ user: localizeMe(data.user) })
  return { error, code } as const
}

/**
 * updateSession
 */
export const updateSession = async () => {
  const { data, error, code } = await Auth.service.session()
  if (!error) {
    if (data.session) authStore.evolve({ authenticated: true, user: localizeMe(data.user) })
    else {
      authStore.evolve({ authenticated: false, user: null })
      resetAllStores()
    }
  }
  return { error, code } as const
}

/**
 * updateProfile
 */
export const updateProfile = async (payload: Auth.Payload["profile"]["update"]) => {
  const { data, error, code } = await Auth.service.profile.update(payload)
  if (!error) authStore.evolve({ user: localizeMe(data.user) })
  return { error, code } as const
}

/**
 * validateEmail
 */
export const validateEmail = async (payload: Auth.Payload["validateEmail"]) => {
  const { data, error, code } = await Auth.service.validateEmail(payload)
  if (!error) authStore.evolve({ authenticated: true, user: localizeMe(data.user) })
  return { error, code } as const
}

/**
 * forgotPassword
 */
export const forgotPassword = async (payload: Auth.Payload["forgotPassword"]) => {
  const { error, code } = await Auth.service.forgotPassword(payload)
  return { error, code } as const
}

/**
 * resetPassword
 */
export const resetPassword = async (payload: Auth.Payload["resetPassword"]) => {
  const { data, error, code } = await Auth.service.resetPassword(payload)
  if (!error) authStore.evolve({ authenticated: true, user: localizeMe(data.user) })
  return { error, code } as const
}
