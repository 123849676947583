import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { useDictionary } from "@/dictionaries/hooks"
import { createPage } from "@/store/pages/actions"

/**
 * PageCreateDialog
 * dictionary src/dictionaries/en/components/dialogs/page-create-dialog.json
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
}
export const PageCreateDialog: React.FC<Props> = ({ open, onOpenChange }) => {
  const { _ } = useDictionary("components.dialogs.page-create-dialog")
  const submit = async () => {
    onOpenChange(false)
    const { error, code, id } = await createPage({})
    if (error && code) {
      if (code === "INVALID_API_TOKEN" || code === "INVALID_AUTH_SESSION") return window.location.reload()
      toast.error(_(code))
    }
    toast.success(_("success"))
    navigate(`/dashboard/pages/${id}`)
  }
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className='sm:max-w-xl'
    >
      <DialogFooter className='sm:justify-start'>
        <DialogClose asChild>
          <Button variant='secondary'>{_("cancel")}</Button>
        </DialogClose>
        <Button onClick={submit}>{_("submit")}</Button>
      </DialogFooter>
    </Dialog>
  )
}
