import { themeColors, useTheme } from "@/components/layout/dashboard/ThemeProvider"
import { Button } from "@/components/radix/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "@/components/radix/ui/dropdown-menu"
import { useDictionary } from "@/dictionaries/hooks"
import { Moon, Sun, SunMoon, Palette } from "lucide-react"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { SrOnly } from "@/components/radix/ui/sr-only"

/**
 * ThemeToggle
 */
type ThemeToggleProps = {
  beforeClick?: () => true
  align?: DropdownMenuPrimitive.MenuContentProps["align"]
  side?: DropdownMenuPrimitive.MenuContentProps["side"]
}
export const ThemeToggle: React.FC<ThemeToggleProps> = ({
  beforeClick = () => true,
  align = "end",
  side = "right",
}) => {
  const { _ } = useDictionary("components.theme-toggle")
  const { setTheme, color: currentColor, setColor } = useTheme()
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" icon className="rounded-full">
          <Sun size={20} className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" aria-hidden />
          <Moon
            size={20}
            className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
            aria-hidden
          />
          <SrOnly>{_("button")}</SrOnly>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align={align} side={side}>
        <DropdownMenuItem onClick={() => beforeClick() && setTheme("light")}>
          <Sun aria-hidden />
          {_("light")}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => beforeClick() && setTheme("dark")}>
          <Moon aria-hidden />
          {_("dark")}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => beforeClick() && setTheme("system")}>
          <SunMoon aria-hidden />
          {_("system")}
        </DropdownMenuItem>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <Palette aria-hidden />
            {_("colors")}
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              {A.map([...themeColors], (color) => (
                <DropdownMenuCheckboxItem
                  key={color}
                  checked={color === currentColor}
                  onCheckedChange={() => beforeClick() && setColor(color)}
                >
                  <span className="inline-flex w-4 h-4 mr-2" style={{ background: `hsl(var(--primary-${color}))` }} />
                  {_(`colors-${color}`)}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
