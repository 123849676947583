import {
  Form,
  FormAssertive,
  FormFieldGroup,
  FormHeader,
  FormInput,
  FormReorderableItem,
  FormReorderableList,
  FormSelect,
  FormSubmit,
  FormSwitch,
  FormTextarea,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
  useFieldGroupContext,
  useForm,
  useFormContext,
} from "@/components/form"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/radix/ui/button"
import Dialog from "@/components/radix/ui/dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { LinkIcon, RectangleHorizontal, Trash } from "lucide-react"
import { v4 as uuid } from "uuid"
import { useCmsContext } from "../../Context"
import { proseStyle } from "../../frontend/proseStyle"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"
import { textPlaceholder } from "@/app/fns/String"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)
const formDictionary = createContextMapper("components", "cms", "content", "form")

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _form } = useDictionary(formDictionary())

  const {
    actions: { updateContentItem },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: React.useMemo(
      () => ({
        titleLevel: `${item.props.titleLevel}`,
        cards: item.props.cards,
        translations: D.map(languagesById, (language) => ({
          languageId: language.id,
          title: translate(item, language)?.props.title ?? "",
          secondary: translate(item, language)?.props.secondary ?? "",
          description: translate(item, language)?.props.description ?? "",
          cards:
            translate(item, language)?.props.cards ??
            A.reduce(item.props.cards, {} as Cards, (cards, card) => ({
              ...cards,
              [card]: { ...emptyCard },
            })),
        })),
      }),
      []
    ),
    onSubmit: async ({ values }) => {
      const payload: FormPayload<ItemType> = {
        props: {
          titleLevel: +values.titleLevel,
          cards: values.cards,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props: {
              ...props,
              cards: D.map(props.cards, (card) => ({
                ...card,
                description: card.type === "links" ? "" : card.description,
                links: card.type === "button" ? (D.reject(card.links, ({ order }) => order > 0) as Links) : card.links,
              })),
            },
          }))
        ),
        files: A.reduce(D.values(values.translations), [] as string[], (files, translation) => [
          ...files,
          ...A.reduce(D.values(translation.cards), [] as string[], (files, card) => [
            ...files,
            ...A.filterMap(D.values(card.links), ({ icon }) => icon || O.None),
          ]),
        ]),
      }
      const { error, code } = await updateContentItem(item.id, payload)
      if (!error) {
        toast.success(_("success"))
        return close()
      } else if (code === "VALIDATION_FAILURE") {
        return _form(code)
      } else if (code === "RESOURCE_NOT_FOUND" || code === "FETCH_ERROR") {
        toast.error(_form(code))
        return close()
      }
      resetAllStoresAndReload()
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs context={false}>
        {(language) => (
          <div className="grid gap-6" key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput label={_form("title-label")} name="title" placeholder={_form("title-placeholder")} />
              <FormInput
                label={_form("secondary-label")}
                name="secondary"
                placeholder={_form("secondary-placeholder")}
              />
              <FormTiptap label={_form("description-label")} name="description" prose={cx(proseStyle, "bg-white")} />
              <FormCards languageId={language.id} />
            </FormTranslationContext>
            <FormHeader>
              <FormHeader.Title>{_form("more-options-title")}</FormHeader.Title>
              <FormHeader.Description>{_form("more-options-description")}</FormHeader.Description>
            </FormHeader>
            <FormSelect label={_form("title-level-label")} name="titleLevel" options={titleLevelOptions} />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * FormCards
 */
type FormCardsProps = {
  languageId: string
}
const FormCards: React.FC<FormCardsProps> = ({ languageId }) => {
  const { _ } = useDictionary(dictionary(`cards`))
  const { _: _form } = useDictionary(formDictionary())

  const { values, setValues } = useFormContext<FormCardsContext>()

  // create a new card
  const createCard = () => {
    const cardId = uuid()
    setValues({
      cards: [...values.cards, cardId],
      translations: D.map(values.translations, (translation) => ({
        ...translation,
        cards: {
          ...translation.cards,
          [cardId]: { ...emptyCard },
        },
      })),
    })
  }

  // drag and drop reordering
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      const oldIndex = values.cards.indexOf(active.id as string)
      const newIndex = values.cards.indexOf(over!.id as string)
      setValues({
        cards: arrayMove(values.cards, oldIndex, newIndex),
      })
    }
  }

  return (
    <FormFieldGroup name="cards">
      <div className="flex flex-col items-start gap-4">
        <FormHeader>
          <FormHeader.Title>{_("title")}</FormHeader.Title>
          <FormHeader.Description>{_("description")}</FormHeader.Description>
        </FormHeader>
        <FormReorderableList
          onDragEnd={onDragEnd}
          items={values.cards}
          createButton={_form("create-card")}
          create={createCard}
        >
          {A.map(values.cards, (id) => (
            <FormCard key={id} {...{ id, languageId: languageId }} />
          ))}
        </FormReorderableList>
      </div>
    </FormFieldGroup>
  )
}

/**
 * FormCard
 */
type FormCardProps = {
  id: string
  languageId: string
}
const FormCard: React.FC<FormCardProps> = ({ id, languageId }) => {
  const { _ } = useDictionary(dictionary(`cards`))
  const { _: _form } = useDictionary(formDictionary())

  const { values, setValues } = useFormContext<FormCardsContext>()
  const { title } = D.getUnsafe(useFieldGroupContext<FormCardsContext["translations"][string]["cards"]>().values, id)

  // delete a card
  const deleteCard = () => {
    setValues({
      cards: A.reject(values.cards, (card) => card === id),
      translations: D.map(values.translations, (translation) => ({
        ...translation,
        cards: D.deleteKey(translation.cards, id),
      })),
    })
  }

  // keyboard accessibility reordering
  const onKeyDown = (keyCode: "ArrowUp" | "ArrowDown") => {
    const oldIndex = values.cards.indexOf(id)
    switch (keyCode) {
      case "ArrowUp": {
        const newIndex = oldIndex - 1
        if (newIndex < 0) return
        setValues({
          cards: arrayMove(values.cards, oldIndex, newIndex),
        })
        break
      }
      case "ArrowDown": {
        const newIndex = oldIndex + 1
        if (newIndex >= values.cards.length) return
        setValues({
          cards: arrayMove(values.cards, oldIndex, newIndex),
        })
        break
      }
    }
  }

  const typeOptions = [
    { value: "button", label: _("card.type-button") },
    { value: "links", label: _("card.type-links") },
  ]
  const type = getCard(values, languageId, id)?.type ?? "button"

  return (
    <FormReorderableItem
      id={id}
      title={
        <>
          <RectangleHorizontal size={16} aria-hidden />
          {textPlaceholder(title, _form("card-title"))}
        </>
      }
      titleLevel={4}
      actions={
        <Button variant="secondary" size="xxs" icon onClick={deleteCard} aria-label={_form("delete-card")}>
          <Trash aria-hidden />
        </Button>
      }
      onKeyDown={onKeyDown}
    >
      <FormFieldGroup name={id}>
        <FormSelect label={_("card.type-label")} name="type" options={typeOptions} />
        <FormInput label={_("card.title-label")} name="title" placeholder={_("card.title-placeholder")} />
        {type === "button" && (
          <FormTextarea
            label={_("card.description-label")}
            name="description"
            placeholder={_("card.description-placeholder")}
          />
        )}
        <FormCardLinks cardId={id} languageId={languageId} />
      </FormFieldGroup>
    </FormReorderableItem>
  )
}

/**
 * FormCardLinks
 */
type FormCardLinksProps = {
  cardId: string
  languageId: string
}
const FormCardLinks: React.FC<FormCardLinksProps> = ({ cardId, languageId }) => {
  const { _ } = useDictionary(dictionary(`links`))

  const { values, setValues } = useFormContext<FormCardsContext>()

  // create link
  const createLink = () => {
    const linkId = uuid()
    setValues({
      ...values,
      translations: D.map(values.translations, (translation) => {
        const card = D.get(translation.cards, cardId)
        if (G.isNullable(card)) return translation
        return {
          ...translation,
          cards: {
            ...translation.cards,
            [cardId]: {
              ...card,
              links: {
                ...card.links,
                [linkId]: { order: A.length(D.keys(card.links)), icon: "", text: "", url: "", blank: false },
              },
            },
          },
        }
      }),
    })
  }

  // drag and drop reordering
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      let links = getSortedLinks(values, languageId, cardId)
      const oldIndex = links.indexOf(active.id as string)
      const newIndex = links.indexOf(over!.id as string)
      links = arrayMove(links, oldIndex, newIndex)
      setValues({
        ...values,
        translations: D.map(values.translations, (translation) => {
          const card = D.get(translation.cards, cardId)
          if (G.isNullable(card)) return translation
          return {
            ...translation,
            cards: {
              ...translation.cards,
              [cardId]: {
                ...card,
                links: D.mapWithKey(card.links, (id, link) => ({ ...link, order: links.indexOf(id) })),
              },
            },
          }
        }),
      })
    }
  }

  const type = getCard(values, languageId, cardId)?.type ?? "button"
  const links = React.useMemo(() => {
    const links = getSortedLinks(values, languageId, cardId)
    if (type === "links") return links
    return A.take(links, 1)
  }, [values, languageId, cardId])

  return (
    <FormFieldGroup name="links">
      <div className="flex flex-col items-start gap-4">
        {type === "links" && (
          <FormHeader>
            <FormHeader.Title>{_("title")}</FormHeader.Title>
            <FormHeader.Description>{_("description")}</FormHeader.Description>
          </FormHeader>
        )}
        <FormReorderableList
          onDragEnd={onDragEnd}
          items={links}
          limit={type === "links" ? 3 : 1}
          createButton={_("create")}
          create={createLink}
          isReorderable={type === "links"}
        >
          {A.map(links, (id) => (
            <FormCardLink key={id} {...{ id, languageId, cardId }} />
          ))}
        </FormReorderableList>
      </div>
    </FormFieldGroup>
  )
}

/**
 * FormCardLink
 */
const FormCardLink: React.FC<{
  cardId: string
  id: string
  languageId: string
}> = ({ id, cardId, languageId }) => {
  const { _ } = useDictionary(dictionary("links"))
  const { id: contextKey } = useCmsContext()

  const { values, setValues } = useFormContext<FormCardsContext>()
  const { text } = D.getUnsafe(
    useFieldGroupContext<FormCardsContext["translations"][string]["cards"][string]["links"]>().values,
    id
  )

  // delete link
  const deleteLink = () => {
    const links = A.reject(getSortedLinks(values, languageId, cardId), (linkId) => linkId === id)
    setValues({
      ...values,
      translations: D.map(values.translations, (translation) => {
        const card = D.get(translation.cards, cardId)
        if (G.isNullable(card)) return translation
        return {
          ...translation,
          cards: {
            ...translation.cards,
            [cardId]: {
              ...card,
              links: pipe<Links, Links, Links>(
                card.links,
                D.deleteKey(id),
                D.mapWithKey((id, link) => ({ ...link, order: links.indexOf(id) }))
              ),
            },
          },
        }
      }),
    })
  }

  // keyboard accessibility reordering
  const onKeyDown = (keyCode: "ArrowUp" | "ArrowDown") => {
    let links = getSortedLinks(values, languageId, cardId)
    const oldIndex = links.indexOf(id)
    if (keyCode === "ArrowUp" && oldIndex - 1 < 0) return
    if (keyCode === "ArrowDown" && oldIndex + 1 >= links.length) return
    const newIndex = keyCode === "ArrowUp" ? oldIndex - 1 : oldIndex + 1
    links = arrayMove(links, oldIndex, newIndex)
    setValues({
      ...values,
      translations: D.map(values.translations, (translation) => {
        const card = D.get(translation.cards, cardId)
        if (G.isNullable(card)) return translation
        return {
          ...translation,
          cards: {
            ...translation.cards,
            [cardId]: {
              ...card,
              links: D.mapWithKey(card.links, (id, link) => ({ ...link, order: links.indexOf(id) })),
            },
          },
        }
      }),
    })
  }

  const type = getCard(values, languageId, cardId)?.type ?? "button"

  return (
    <FormReorderableItem
      id={id}
      title={
        <>
          <LinkIcon size={16} aria-hidden />
          {textPlaceholder(text, _("link.title"))}
        </>
      }
      titleLevel={5}
      actions={
        <Button variant="secondary" size="xxs" icon onClick={deleteLink} aria-label={_("link.delete")}>
          <Trash aria-hidden />
        </Button>
      }
      onKeyDown={onKeyDown}
      isReorderable={type === "links"}
    >
      <FormFieldGroup name={id}>
        <div className="relative flex flex-col gap-4">
          {type === "links" ? (
            <div className="grid grid-cols-4 gap-4">
              <div className="flex flex-col col-span-3 gap-6">
                <FormInput label={_("link.text-label")} name="text" placeholder={_("link.text-placeholder")} />
                <FormInput label={_("link.url-label")} name="url" placeholder={_("link.url-placeholder")} />
              </div>
              <div>
                <FormMediasImage
                  label={_("link.icon-label")}
                  name="icon"
                  ratio="aspect-square"
                  contextKey={contextKey}
                />
              </div>
            </div>
          ) : (
            <>
              <FormInput label={_("link.text-label")} name="text" placeholder={_("link.text-placeholder")} />
              <FormInput label={_("link.url-label")} name="url" placeholder={_("link.url-placeholder")} />
            </>
          )}
          <FormSwitch label={_("link.blank-label")} name="blank" />
        </div>
      </FormFieldGroup>
    </FormReorderableItem>
  )
}

/**
 * helpers
 */
const getCard = (values: FormCardsContext, languageId: string, cardId: string) => {
  const translation = D.get(values.translations, languageId)
  if (G.isNullable(translation)) return undefined
  return D.get(translation.cards, cardId)
}
const getSortedLinks = (values: FormCardsContext, languageId: string, cardId: string) => {
  const card = getCard(values, languageId, cardId)
  if (G.isNullable(card)) return []
  return pipe(
    card.links,
    D.toPairs,
    A.sortBy(([, { order }]) => order),
    A.map(([id]) => id)
  )
}
const emptyCard: Cards[string] = {
  type: "button",
  title: "",
  description: "",
  links: {},
}

/**
 * types
 */
type Cards = FormPayload<ItemType>["translations"][number]["props"]["cards"]
type Links = FormPayload<ItemType>["translations"][number]["props"]["cards"][string]["links"]
type FormCardsContext = {
  cards: string[]
  translations: Record<
    string,
    {
      languageId: string
      cards: Record<string, Cards[string]>
    }
  >
}
