export { SelectProvider } from "@/app/hooks/useSelect"
import { useMediaContext } from "./Context"
import { SelectoProps } from "react-selecto"

/**
 * useSelect
 */
export const useSelect = <T extends HTMLElement = HTMLDivElement>(options: Partial<SelectoProps> = {}) => {
  const { setSelected, selected, selectFile, selectFolder, accepted } = useMediaContext()
  const ref = React.useRef<T | null>(null)

  const onSelect: SelectoProps["onSelect"] = React.useCallback(
    ({ added, removed }) => {
      let selection = A.reduce(added, selected, (list, { dataset: { selected, selectedId, selectedExtension } }) => {
        const type = selected === "folders" ? "folders" : "files"
        const isFile = selected === "files"
        if (G.isNotNullable(accepted) && isFile && !accepted.includes(selectedExtension ?? "")) return list
        if (G.isNullable(selectedId) || (isFile && !selectFile) || (type === "folders" && !selectFolder)) return list
        return D.set(list, type, A.append(list[type], selectedId))
      })
      selection = A.reduce(removed, selection, (list, { dataset: { selected, selectedId } }) => {
        const type = selected === "folders" ? "folders" : "files"
        if (G.isNullable(selectedId)) return list
        return D.set(
          list,
          type,
          A.reject(list[type], (id) => id === selectedId)
        )
      })
      setSelected(selection)
    },
    [selected]
  )

  /**
   * defaultOptions
   */
  const defaultOptions: Partial<SelectoProps> = {
    rootContainer: ref.current,
    dragContainer: ref.current,
    selectableTargets: ["[data-selected]"],
    selectByClick: true,
    selectFromInside: true,
    continueSelect: false,
    toggleContinueSelect: "shift",
    keyContainer: window,
    hitRate: 1,
    onSelect,
  }
  const props = D.merge(defaultOptions, options)
  return { ref, props }
}

/**
 * useSelectItem
 */
export const useSelectItem = (
  type: "folders" | "files",
  id: string,
  extension: string | undefined = undefined,
  onNavigate?: () => void
) => {
  const { selected, setSelected, selectFolder, selectFile } = useMediaContext()

  // selection
  const multiple = React.useMemo(
    () => (type === "folders" ? selectFolder === "multiple" : selectFile === "multiple"),
    [type, selectFile, selectFolder]
  )
  const canSelect = React.useMemo(
    () => !((type === "folders" && !selectFolder) || (type === "files" && !selectFile)),
    [type, selectFolder, selectFile]
  )
  const isSelected = React.useMemo(() => A.includes(selected[type], id), [type, id, selected])
  const hasSelection = React.useMemo(() => A.isNotEmpty(selected.files) || A.isNotEmpty(selected.folders), [selected])
  const select = React.useCallback(() => {
    if (!canSelect) return
    if (multiple) setSelected({ ...selected, [type]: A.append(selected[type], id) })
    else setSelected({ folders: [], files: [], [type]: [id] })
  }, [type, id, selected, multiple, canSelect])
  const deselect = React.useCallback(
    () => setSelected({ ...selected, [type]: A.reject(selected[type], (sid) => sid === id) }),
    [type, id, selected]
  )
  const toggleSelection = React.useCallback(
    () => (A.includes(selected[type], id) ? deselect() : select()),
    [type, id, selected, select, deselect]
  )

  // bind
  const isTouchDevice = React.useMemo(() => "ontouchstart" in window || navigator.maxTouchPoints > 0, [])
  // const touchBind = useLongPress(
  //   (e) => {
  //     e.preventDefault()
  //     select()
  //   },
  //   { cancelOnMovement: true, detect: LongPressEventType.Touch }
  // )
  const onClick = () => {
    if (isTouchDevice) {
      hasSelection ? toggleSelection() : onNavigate && onNavigate()
    }
  }
  const onDoubleClick = () => !isTouchDevice && onNavigate && onNavigate()
  const onContextMenu = (e: React.MouseEvent) => isTouchDevice && e.preventDefault()
  const selectItemBind = { onClick, onDoubleClick, onContextMenu }

  // props
  const selectItemProps = React.useMemo(
    () => ({
      "data-selected": type,
      "data-selected-id": id,
      ...(G.isNotNullable(extension)
        ? {
            "data-selected-extension": extension,
          }
        : {}),
    }),
    [type, id]
  )

  return {
    multiple,
    canSelect,
    isSelected,
    selectItemProps,
    selectItemBind,
    hasSelection,
    select,
    deselect,
    toggleSelection,
  }
}
