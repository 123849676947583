import { Form, FormAssertive, FormInput, FormSubmit, FormTranslationTabs, useForm } from "@/components/form"
import { FormKeywords } from "@/components/form/FormKeywords"
import { FormTextarea } from "@/components/form/FormTextarea"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/radix/ui/button"
import { Dialog, DialogClose, DialogFooter } from "@/components/ui/Dialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { useCmsContext } from "../Context"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * UpdateSeoDialog
 * dictionary src/dictionaries/en/components/cms.json
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
}
export const UpdateSeoDialog: React.FC<Props> = (props) => {
  const { _ } = useDictionary(dictionary("update-seo-dialog"))
  return (
    <Dialog
      open={props.open}
      onOpenChange={props.onOpenChange}
      title={_("title")}
      description={_("secondary")}
      className="sm:max-w-xl"
    >
      {props.open && <DialogForm {...props} />}
    </Dialog>
  )
}

/**
 * DialogForm
 * dictionary src/dictionaries/en/components/cms.json
 */
const DialogForm: React.FC<Props> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary("update-seo-dialog"))
  const {
    id,
    seo,
    actions: { updateSeo },
  } = useCmsContext()
  const languagesById = useLanguagesById()
  const form = useForm({
    allowSubmitAttempt: true,
    values: React.useMemo(
      () => ({
        translations: D.map(languagesById, (language) => ({
          languageId: language.id,
          title: translate(seo, language)?.title ?? "",
          description: translate(seo, language)?.description ?? "",
          keywords: translate(seo, language)?.keywords ?? [],
          imageId: translate(seo, language)?.image ?? null,
        })),
      }),
      []
    ),
    translate: _ as (ctx: string) => string,
    onSubmit: async ({ values }) => {
      const { error, code } = await updateSeo({ translations: D.values(values.translations) })
      if (!error) {
        toast.success(_("success"))
        onOpenChange(false)
      } else if (code === "RESOURCE_NOT_FOUND") {
        toast.error(_(code))
        onOpenChange(false)
      } else return code
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs>
        {(language) => (
          <div className="grid gap-6" key={language.id}>
            <FormInput label={_("title-label")} name="title" placeholder={_("title-placeholder")} />
            <FormTextarea
              label={_("description-label")}
              name="description"
              placeholder={_("description-placeholder")}
            />
            <FormKeywords label={_("keywords-label")} name="keywords" placeholder={_("keywords-placeholder")} />
            <FormMediasImage label={_("image-label")} name="imageId" contextKey={id} />
          </div>
        )}
      </FormTranslationTabs>
      <DialogFooter className="sm:justify-start">
        <DialogClose asChild>
          <Button variant="secondary">{_("cancel")}</Button>
        </DialogClose>
        <FormSubmit>{_("submit")}</FormSubmit>
      </DialogFooter>
    </Form>
  )
}
