import { PageContent } from "@/components/layout/dashboard"
import { Grid } from "@/components/layout/dashboard/Collection"
import { Selection } from "@/components/layout/dashboard/Collection/Selection"
import Toolbar, { ToolbarSort } from "@/components/layout/dashboard/Toolbar"
import { useMediaContext } from "@/components/medias/Context"
import { CollectionEmpty } from "@/components/medias/components/collection"
import { ItemFile } from "@/components/medias/components/files"
import { ItemFolder } from "@/components/medias/components/folders"
import { useLightboxFiles } from "@/components/medias/components/lightbox"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useFilteredMedias } from "@/store/medias/hooks"
import { FolderPlus, Upload } from "lucide-react"
import { MediaFormTest } from "./MediaFormTest"

/**
 * dictionary src/dictionaries/en/pages/dashboard/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * ComponentName
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const {
    currentFolder,
    view,
    setView,
    createFolder,
    uploadFiles,
    selected,
    setSelected,
    confirmDeleteSelection,
    moveSelection,
  } = useMediaContext()
  const { matchable, sortable, folders, filteredFolders, files, filteredFiles, clearAllFilters } =
    useFilteredMedias(currentFolder)
  const folderIsEmpty = A.isEmpty(folders) && A.isEmpty(files)
  const resultIsEmpty = A.isEmpty(filteredFolders) && A.isEmpty(filteredFiles)

  const { renderLightbox, lightbox } = useLightboxFiles(filteredFiles)

  return (
    <PageContent>
      <Selection
        selected={[...selected.files, ...selected.folders]}
        unselect={() => setSelected({ files: [], folders: [] })}
        moveSelection={moveSelection}
        deleteSelection={confirmDeleteSelection}
      />
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={() => createFolder(currentFolder)}>
          <FolderPlus aria-hidden />
          {_("create-folder")}
        </Toolbar.Button>
        <Toolbar.Button onClick={() => uploadFiles(currentFolder)}>
          <Upload aria-hidden />
          {_("upload-files")}
        </Toolbar.Button>
        <ToolbarSort {...sortable} dictionary="components.medias.sort" />
        <Toolbar.View {...{ view, setView }} />
        <MediaFormTest />
      </Toolbar>
      <CollectionEmpty {...{ resultIsEmpty, folderIsEmpty, clearAllFilters }} />
      <Grid {...{ view }}>
        {A.map(filteredFolders, (folder) => (
          <ItemFolder
            key={folder.id}
            folder={folder}
            open={(id) => navigate(`/dashboard/medias/${id}`)}
            canToggleSelect={true}
          />
        ))}
        {A.map(filteredFiles, (file) => (
          <ItemFile key={file.id} file={file} open={lightbox} canToggleSelect={true} />
        ))}
      </Grid>
      {renderLightbox()}
    </PageContent>
  )
}
