import { Image } from "@/components/radix/ui/image"
import { Skeleton } from "@/components/radix/ui/skeleton"
import { Hn } from "@/components/ui/Hn"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { ImageIcon } from "lucide-react"
import { relativeProseStyle } from "../../frontend/proseStyle"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 * dictionary src/dictionaries/en/components/cms.json
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary, description, linkText, linkUrl, facts } = t(item).props

  return (
    <article className="font-museo bg-be-cultured px-8 py-8">
      <div className="w-full max-w-[1000px] mx-auto">
        {S.isNotEmpty(S.trim(secondary)) && (
          <p className="pb-2.5 @xl/card:pb-5 text-xs @xl/card:text-base leading-normal font-semibold text-be-manatee uppercase">
            {secondary}
          </p>
        )}
        <Hn
          className={cx(
            "w-full max-w-sm uppercase text-[25px] @xl/card:text-[40px] leading-tight font-bold text-be-midnight"
          )}
          level={titleLevel}
        >
          {title}
          <Skeleton value={title} />
          <Skeleton className="w-3/4" value={title} />
        </Hn>
        {S.isNotEmpty(S.trim(description)) && (
          <div
            className={cx(relativeProseStyle, "w-full max-w-lg text-sm @xl/card:text-base text-be-midgray")}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
      <ul className="flex flex-col @xl/card:grid grid-cols-4 w-full max-w-[1280px] mx-auto pt-[30px] gap-[25px]">
        {A.map(item.props.facts, (fact) => (
          <FactCard fact={D.get(facts, fact) ?? emptyFact} titleLevel={titleLevel} key={fact} />
        ))}
        {A.isEmpty(item.props.facts) &&
          A.map(A.range(0, 3), (index) => <FactCard fact={emptyFact} titleLevel={titleLevel} key={index} />)}
      </ul>
      {S.isNotEmpty(S.trim(linkUrl)) && (
        <p className="flex justify-center pt-[50px]">
          <span className="inline-flex items-center px-[20px] py-[10px] rounded-[25px] bg-be-orient text-be-button text-white uppercase">
            {linkText}
          </span>
        </p>
      )}
    </article>
  )
}

type Fact = FormPayload<ItemType>["translations"][number]["props"]["facts"][string]
const FactCard: React.FC<{ fact: Fact; titleLevel: number }> = ({ fact, titleLevel }) => {
  const t = useTranslation()
  const image = useMediasFile(fact.icon)
  const { title, description } = fact
  return (
    <li className="flex flex-col px-[20px] py-[25px] bg-white shadow-be-card rounded-[5px]">
      {G.isNotNullable(image) && (
        <div className="pb-[20px]">
          <Image src={image.url} alt={t(image).alt} className="w-10 h-10 object-cover">
            <ImageIcon size={16} />
          </Image>
        </div>
      )}
      <Hn className="flex items-center h-12 pb-2.5 text-be-midnight text-[20px] font-semibold" level={titleLevel + 1}>
        {title}
        <Skeleton className="max-w-[200px]" value={title} />
      </Hn>
      <p className="text-xs leading-[18px] text-be-independance">
        {description}
        <Skeleton value={description} />
        <Skeleton value={description} />
        <Skeleton value={description} />
        <Skeleton className="w-3/5" value={description} />
      </p>
    </li>
  )
}

const emptyFact = {
  icon: "",
  title: "",
  description: "",
}
