import { FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "@/components/form"
import { Menu } from "@/components/layout/dashboard/Collection"
import { SelectFiles } from "@/components/medias/components/selectFiles"
import { FileInfoDialog } from "@/components/medias/dialogs/FileInfoDialog"
import { Button, buttonFormField } from "@/components/radix/ui/button"
import { Image } from "@/components/radix/ui/image"
import { SrOnly } from "@/components/radix/ui/sr-only"
import { useDictionary } from "@/dictionaries/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import delay from "delay"
import { FileDown, FileMinus, ImagePlus, Info, MoreVertical } from "lucide-react"
import { createContextMapper } from "@/dictionaries/helpers"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * FormMediasImage
 */
type FormMediasImageProps = { ratio?: string; fit?: string; contextKey?: string }
export const FormMediasImage: React.FC<FormMediasImageProps & FormFieldWrapperProps> = ({
  ratio = "aspect-video",
  fit = "object-cover",
  contextKey,
  ...wrapperProps
}) => (
  <FormFieldWrapper {...wrapperProps}>
    <FormMediasImageInput {...{ ratio, fit, contextKey }} />
  </FormFieldWrapper>
)

/**
 * FormMediasImageInput
 */
const FormMediasImageInput: React.FC<FormMediasImageProps> = ({
  ratio = "aspect-video",
  fit = "object-cover",
  contextKey,
}) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const { setFieldValue, value, id } = useFieldContext<string | null>()
  const file = useMediasFile(value)
  const onRemove = () => {
    setFieldValue(null)
    delay(10).then(() => focusRef.current?.focus())
  }
  const [open, onOpenChange] = React.useState(false)

  const onSelect = (files: string[]) => {
    const file = A.head(files)
    if (G.isNullable(file)) return
    setFieldValue(file)
  }

  const [openInfo, setOpenInfo] = React.useState(false)
  const focusRef = React.useRef<HTMLButtonElement>(null)

  return (
    <div className={cx("@container/input relative flex justify-center items-center w-full", ratio)}>
      {G.isNotNullable(file) ? (
        <>
          <Image
            src={file.url}
            alt={t(file).name}
            wrapperCx='w-full h-full rounded-md border border-input'
            className={cx("w-full h-full rounded-md", fit)}
          />
          <Menu
            menu={
              <>
                <Menu.Item onClick={() => setOpenInfo(true)}>
                  <Info aria-hidden />
                  {_("file-info")}
                </Menu.Item>
                <Menu.Item onClick={onRemove}>
                  <FileMinus aria-hidden />
                  {_("remove-image")}
                </Menu.Item>
                <Menu.Item onClick={() => onOpenChange(true)}>
                  <FileDown aria-hidden />
                  {_("change-image")}
                </Menu.Item>
              </>
            }
            type='dropdown-menu'
            align='start'
            side='left'
          >
            <Button ref={focusRef} variant='transparent' size='xxs' icon className='absolute top-2 right-2'>
              <MoreVertical aria-hidden />
              <SrOnly>{_("more")}</SrOnly>
            </Button>
          </Menu>
          <FileInfoDialog {...{ file, openInfo, setOpenInfo }} onCloseAutoFocus={() => focusRef.current?.focus()} />
        </>
      ) : (
        <>
          <button
            ref={focusRef}
            className={cx(buttonFormField, "h-full")}
            id={id}
            onClick={() => onOpenChange(true)}
            type='button'
          >
            <ImagePlus
              strokeWidth={0.5}
              className='w-8 h-8 @sm/input:w-10 @sm/input:h-10 @md/input:w-12 @md/input:h-12 @lg/input:w-16 @lg/input:h-16'
            />
            <SrOnly>{_("add-image")}</SrOnly>
          </button>
        </>
      )}
      <SelectFiles
        open={open}
        type='image'
        current={file?.parentId ?? undefined}
        onOpenChange={onOpenChange}
        onCloseAutoFocus={() => focusRef.current?.focus()}
        onSelect={onSelect}
        contextKey={contextKey}
      />
    </div>
  )
}
