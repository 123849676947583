import { useSelectItem } from "@/app/hooks/useSelect"
import { Card, Row } from "@/components/layout/dashboard/Collection"
import Menu from "@/components/layout/dashboard/Collection/Menu"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { ArticleCategory } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { LayoutGrid } from "lucide-react"
import { Context, ContextMenu, usePageContext } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles", "categories")

/**
 * Item
 */
type Props = { category: ArticleCategory }
export const Item: React.FC<Props> = ({ category }) => {
  const { view } = usePageContext()
  const { selectItemProps } = useSelectItem(Context, category.id)
  return (
    <Menu type='context-menu' menu={<ContextMenu category={category} />} asChild>
      <div className='grid' {...selectItemProps}>
        {view === "grid" ? <ItemCard {...{ category }} /> : <ItemRow {...{ category }} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<Props> = ({ category }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, category.id)
  const t = useTranslation()
  const { name, description } = t(category)
  return (
    <Card className={cx("overflow-hidden")} selected={isSelected}>
      <Card.Header>
        <Card.Title>{textPlaceholder(name, _("no-title"))}</Card.Title>
        <Card.Description>{textPlaceholder(description, _("no-description"))}</Card.Description>
        <Card.Menu menu={<ContextMenu category={category} />} />
      </Card.Header>
      {/* <Card.Content>
        <Fields divider></Fields>
      </Card.Content> */}
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<Props> = ({ category }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, category.id)
  const t = useTranslation()
  const { name } = t(category)
  return (
    <Row selected={isSelected}>
      <Row.Image>
        <LayoutGrid size={16} />
      </Row.Image>
      <Row.Header>
        <Row.Title>{textPlaceholder(name, _("no-title"))}</Row.Title>
        <Row.Description></Row.Description>
      </Row.Header>
      <Row.Menu menu={<ContextMenu category={category} />} />
    </Row>
  )
}

/**
 * helpers
 */
const textPlaceholder = (value: string, placeholder: string) => (S.isEmpty(S.trim(value ?? "")) ? placeholder : value)
