import { usePromise } from "@/app/hooks/usePromise"
import { PageError } from "@/components/PageError"
import { PageLoader } from "@/components/PageLoader"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { validateEmail } from "@/store/auth/actions"
import { Redirect } from "wouter"

/**
 * dictionary src/dictionaries/en/pages/validate-email.json
 */
const dictionary = createContextMapper("pages", "validate-email")

/**
 * Page: ValidateEmail
 */
const ValidateEmail: React.FC<{ token: string }> = ({ token }) => {
  const { _ } = useDictionary(dictionary())

  const [response, loading] = usePromise(async () => {
    const response = await validateEmail({ token })
    if (!response.error) toast.success(_("toast-success"))
    return response
  })

  if (loading) return <PageLoader />
  if (!response.error) return <Redirect to='/dashboard' />
  return (
    <PageError
      title={_("error-title")}
      secondary={_("error-secondary")}
      message={_(response.code === "FETCH_ERROR" ? response.code : "error-message")}
    />
  )
}
export default ValidateEmail
