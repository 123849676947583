import { Button, buttonVariants } from "@/components/radix/ui/button"
import { type VariantProps } from "class-variance-authority"
import { useToolbarContext } from "./Toolbar"

/**
 * ToolbarButton
 */
type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof buttonVariants>
const ToolbarButton: React.FC<Props> = ({ size, className, ...props }) => {
  const ctx = useToolbarContext()
  const buttonSize = size ?? ctx.size
  return (
    <Button
      size={buttonSize}
      {...props}
      className={cx(buttonSize === "xs" ? "[&>svg]:w-3 [&>svg]:h-3" : "[&>svg]:w-4 [&>svg]:h-4", className)}
    />
  )
}
export default ToolbarButton
